<template>
    <div class="marketplace">
        <!-- preloader start here -->
        <!-- <div class="preloader">
            <div class="preloader-inner">
                <div class="preloader-icon">
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div> -->
        <!-- preloader ending here -->

        <!-- type tap start -->
        <div class="header">
            <div>
                <router-link class="selected" to="/marketplace">{{$t('Paper Money')}}</router-link>
                <!-- <router-link class="unselect" to="/marketplace/mystery">{{$t('Mystery Egg')}}</router-link> -->
            </div>

            <div class="search-input-wrapper col-3 d-none d-sm-none d-md-none d-lg-flex me-0 me-sm-0 me-md-0 me-lg-4">
                <b-icon icon="search"></b-icon>
                <input class="form-control" :placeholder="$t('Search')" type="text" v-model="searchInput">
            </div>
        </div>
        <!-- type tap end -->

        <!-- sidebar start -->
        <div class="sidebar col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 d-none d-lg-block">
            <div>
                <div class="filter">
                    <div class="title-area d-flex">
                        <p class="m-0 mt-3 mt-lg-0">{{ $t("Filter") }}</p>
                        <button class="btn reset-btn mt-3 mt-lg-0" @click="clearFilter">
                            <!-- <i class="icofont-refresh"></i> -->
                            {{ $t("Reset") }}
                        </button>
                        <!-- <button class="close-btn btn text-white d-lg-none d-block" @click="closeFilterModal">x</button> -->
                    </div>
                </div>
            </div>

            <!-- category start -->
            <div class="category" variant="info">
                <div v-b-toggle.category class="title-area" @click="sidebarToggleClick(2)">
                    <p class="m-0">{{$t('Category')}}</p>
                    <b-icon :icon="sidebarToggle[2] ? 'chevron-up' : 'chevron-down'"></b-icon>
                </div>
                <b-collapse id="category"  role="tabpanel">
                    <div class="content-area p-3">
                        <!-- filter start -->
                        <div class="form-group">
                            <div class="filter-input">
                                <b-icon icon="search"></b-icon>
                                <input type="text" class="form-control" placeholder="Filter" v-model="filterCategoryInput">
                            </div>
                        </div>
                        <!-- filter end -->
                        <div class="content">
                            <div class="form-check" v-for="category in categories" :key="category.id">
                                <input class="form-check-input" type="checkbox" @change="changeSelectedCatogory(category.name,$event)">
                                <label class="form-check-label">
                                    {{category.name}}
                                </label>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
            <!-- category end -->

            <!-- denomination start -->
            <div class="amount" variant="info">
                <div v-b-toggle.denomination class="title-area" @click="sidebarToggleClick(3)">
                    <p class="m-0">{{$t("Denomination")}}</p>
                    <b-icon :icon="sidebarToggle[3] ? 'chevron-up' : 'chevron-down'"></b-icon>
                </div>
                <b-collapse id="denomination" role="tabpanel">
                    <div class="content-area p-3">
                        <!-- filter start -->
                        <div class="form-group">
                            <div class="filter-input">
                                <b-icon icon="search"></b-icon>
                                <input type="text" class="form-control" placeholder="Filter" v-model="filterDenominationInput">
                            </div>
                        </div>
                        <!-- filter end -->
                        <div class="content">
                            <div class="form-check" v-for="(amount, index) in amounts" :key="amount.amount">
                                <input class="form-check-input" type="checkbox" v-model="selectedAmount" :key="'amount'+index" :id="'amount'+index" :value="amount.amount">
                                <label class="form-check-label" :for="'amount'+index">
                                    {{amount.amount}}
                                </label>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
            <!-- denomination end -->

            <!-- cert number start -->
            <div class="cert-number d-block d-lg-none" variant="info">
                <div v-b-toggle.certNumber class="title-area" @click="sidebarToggleClick(0)">
                    <p class="m-0">{{ $t("Cert Number") }}</p>
                    <b-icon :icon="sidebarToggle[0] ? 'chevron-up' : 'chevron-down'"></b-icon>
                </div>
                <b-collapse id="certNumber" visible>
                    <div class="content-area p-3">
                       <div class="search-input-wrapper d-flex d-sm-flex d-md-none d-lg-none">
                            <b-icon icon="search"></b-icon>
                            <input class="form-control" :placeholder="$t('Search cert number')" type="text" v-model="searchInput">
                        </div>
                    </div>
                </b-collapse>
            </div>
            <!-- cert number end -->

            <!-- status start -->
            <div class="status" variant="info">
                <div v-b-toggle.status class="title-area" @click="sidebarToggleClick(0)">
                    <p class="m-0">{{ $t("Status") }}</p>
                    <b-icon :icon="sidebarToggle[0] ? 'chevron-up' : 'chevron-down'"></b-icon>
                </div>
                <b-collapse id="status" visible>
                    <div class="content-area p-3">
                        <button :class="`btn btn-numisart unselect ${this.selectedStatus == 'auction' ? 'active' : ''}`" @click="filterStatus('auction',$event)">{{$t('Auctions')}}</button>
                        <button :class="`btn btn-numisart unselect ${this.selectedStatus == 'listing' ? 'active' : ''}`" @click="filterStatus('listing',$event)">{{ $t('List') }}</button>
                        <button :class="`btn btn-numisart unselect ${this.selectedStatus == 'passive' ? 'active' : ''}`" @click="filterStatus('passive',$event)">{{ $t('No list') }}</button>
                    </div>
                </b-collapse>
            </div>
            <!-- status end -->

            <!-- sort by start -->
            <div class="sortBy" variant="info">
                <div v-b-toggle.sortBy class="title-area" @click="sidebarToggleClick(1)">
                    <p class="m-0">{{$t('Sort by')}}</p>
                    <b-icon :icon="sidebarToggle[1] ? 'chevron-up' : 'chevron-down'"></b-icon>
                </div>
                <b-collapse id="sortBy" role="tabpanel">
                    <div class="content-area p-3">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" v-model="selectedSortBy" id="exampleRadios1" name="flexRadioDefault"  type="radio" value="default" checked>
                                <label class="form-check-label">
                                    {{$t('All')}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" v-model="selectedSortBy"  name="flexRadioDefault1"  type="radio" value="recently-minted">
                                <label class="form-check-label">
                                    {{$t('Recently Minted')}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" v-model="selectedSortBy"  name="flexRadioDefault2"  type="radio" value="high-to-low">
                                <label class="form-check-label">
                                    {{$t('Price high to low')}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" v-model="selectedSortBy"  name="flexRadioDefault3"  type="radio" value="low-to-high">
                                <label class="form-check-label">
                                    {{$t('Price low to high')}}
                                </label>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
            <!-- sort by end -->

            <!-- year start -->
            <div class="year" variant="info">
                <div v-b-toggle.year class="title-area" @click="sidebarToggleClick(4)">
                    <p class="m-0">{{$t("Year")}}</p>
                    <b-icon :icon="sidebarToggle[4] ? 'chevron-up' : 'chevron-down'"></b-icon>
                </div>
                <b-collapse id="year" >
                    <!-- <div class="content-area p-3">
                        <div class="content">
                            <b-form-group v-for="year in years" :key="year.year">
                                <b-form-radio v-model="selectedYear" :value="year.year">{{year.year}}</b-form-radio>
                            </b-form-group>
                        </div>
                    </div> -->
                    <div class="content-area p-3">
                        <!-- filter start -->
                        <div class="form-group">
                            <div class="filter-input">
                                <b-icon icon="search"></b-icon>
                                <input type="text" class="form-control" placeholder="Filter" v-model="filterYearInput">
                            </div>
                        </div>
                        <!-- filter end -->
                        <div class="content">
                            <div class="form-check" v-for="(year, index) in years" :key="'year'+index">
                                <input class="form-check-input" type="checkbox" @change="changeSelectedYear(year.year,$event)">
                                <label class="form-check-label">
                                    {{year.year}}
                                </label>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
            <!-- year end -->
        </div>
        <!-- sidebar end -->

        <!-- nft start -->
        <div class="col-lg-10 col-12 px-lg-5 px-3 ml-16">
            <div class="grid-button-container">
                <b-icon-grid-fill font-scale="2" :class="onChange_Grid == true ? 'grid-button-active' : 'grid-button'" @click="onChange_Grid = true">

                </b-icon-grid-fill> 
                
                <b-icon-grid3x3-gap-fill font-scale="2" :class="onChange_Grid == false ? 'grid-button-active' : 'grid-button'" @click="onChange_Grid = false">

                </b-icon-grid3x3-gap-fill>
            </div>

            <div class="nft" v-if="nfts.length == 0">
                <p>{{$t('No NFT Found')}}</p>
            </div>
            <div v-if="nfts.length > 0" class="nft" :class="onChange_Grid == true ? 'nft' : 'nft_grid'">
                <div v-for="(nft,index) in nfts" :key="'nftId'+nft.nft_id">
                    <div class="item card">
                        <router-link :to="'/nft/'+nft.nft_id">
                            <div class="img-area">
                                <div class="thumbnail">
                                    <img :src="nft.thumbnail_picture" alt="thumbnail">
                                    <!-- <video v-if="nft.thumbnail" loop muted playsinline :key="nft.thumbnail">
                                        <source :src="nft.thumbnail" type="video/mp4">
                                    </video> -->
                                </div>
                                <button class="switch-btn" @click="changeSrc($event,index)">
                                    <i class="bi bi-play-fill"></i>
                                </button>
                                <!-- <ul v-if="nft.expiry_date" class="nft-countdown">
                                    <li>
                                        <span class="days">00</span><span class="count-txt">D</span>
                                    </li>
                                    <li>
                                        <span class="hours">00</span><span class="count-txt">H</span>
                                    </li>
                                    <li>
                                        <span class="minutes">00</span><span class="count-txt">M</span>
                                    </li>
                                    <li>
                                        <span class="seconds">00</span><span class="count-txt">S</span>
                                    </li>
                                </ul> -->
                            </div>

                            <div class="info-area">
                                <div class="title-area d-flex flex-wrap p-2 pb-0">
                                    <p class="category col-6 m-0">{{nft.category}}</p>
                                    <p v-if="nft.price" class="col-6 text-end m-0">{{$t("Price")}}</p>
                                </div>
                                <div class="content-area d-flex justisy-content-between px-2">
                                    <!-- <p class="cert col-6 m-0">{{nft.nft_id}}</p> -->
                                    <p class="cert col-6 m-0">{{nft.pmg_cert}}</p>
                                    <p v-if="nft.price" class="col-6 text-end m-0"><img role="button" v-b-tooltip.hover :title="!nft.symbol?nft.offer_symbol : nft.symbol" :src="!nft.currency_image ? nft.offer_currency : nft.currency_image" alt="numisart-coin" width="15px" height="15px">{{formatNumber(nft.price,nft.symbol)}}</p>
                                </div>
                            </div>
                        </router-link>
                        <div class="like-area d-flex  mt-2 px-2">
                            <div class="col-9 text-start">
                                <p v-if="nft.expiry_date" class="nft-countdown mb-0 text-start" id="nft-countdown">
                                    <span class="days">00</span>
                                </p>
                            </div>
                            
                            <div class="col-3 text-end d-flex flex-col align-items-center justify-content-end">
                                <b-icon-suit-heart-fill :id="'fave'+nft.nft_id" font-scale="1.25" :class="nft.like_status == 1 ? 'favourites' : 'unfavourites'" 
                                @click="currentUserAddress == null ? $bvModal.show('connectWalletModal') : favourite(nft.nft_id)">

                                </b-icon-suit-heart-fill>
                                <div :id="'faveCount'+nft.nft_id" class="ps-1 ms-1">{{nft.total_likes}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div> 
        <!-- nft end -->

        <!-- filter button start -->
        <button id="filterBtn" class="filter-btn" @click="openFilterModal()">{{$t("Filter")}}</button>
        <button id="filterCloseBtn" class="filter-btn d-none bg-success" @click="closeFilterModal()">{{$t("Done")}}</button>
        <!-- filter button end -->

        <!-- connect wallet modal start -->
        <b-modal
            id="connectWalletModal"
            @ok.prevent="connectWallet"
            :ok-title="$t('Connect')"
            :cancel-title="$t('Cancel')"
            cancel-variant="danger"
            :title= "$t('Connect Wallet')"
        >
            <p>{{ $t("Connect with your wallet to continue the action") }}</p>
        </b-modal>
        <!-- connect wallet modal end -->
     
    </div>
</template>

<style>
    svg.faved path{
        fill: #dc3535;
    }
    .marketplace{
        display: flex; 
        overflow: auto;
        position: relative;
    }

    .lightmode .marketplace .header{
        background: white;
    }

    .marketplace .header{
        position: fixed;
        z-index: 50;
        top:70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .lightmode .marketplace .search-input-wrapper{
        background: white;
        border: 1px solid black;
    }

    .lightmode .marketplace .search-input-wrapper input{
        color: black;
    }

    .marketplace .search-input-wrapper{
        display: flex;
        align-items: center;
        background-color: #151517;
        padding: 0px 10px;
        border-radius: 5px;
    }

    .marketplace .search-input-wrapper input{
        border: none;
        background-color: transparent;
        color: white;
    }

    .marketplace .search-input-wrapper input:focus{
        box-shadow: none;
    }

    .marketplace .header a{
        text-decoration: none;
        margin-left: 30px;
    }

    .marketplace .header .unselect{
        color: lightgray;
    }

    .marketplace .header .selected{
        color: var(--theme-color);
    }

    /* nft */
    .marketplace .nft{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 20px;
        padding: 20px 0;
        margin-top: 80px;
    }

    .marketplace .nft_grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 20px;
        padding: 20px 0;
    }

    .marketplace .nft .item{
        background-color: #2F2F2F;
        /* width: 300px; */
        height: 422px;
        border-radius: 16px;
        transition: 1s;
    }

    .lightmode .marketplace .nft .item{
        background-color: rgb(255, 255, 255);
        border: 1px solid var(--default);
    }


    .marketplace .nft .item:hover{
        opacity: 0.8;
        /* transform: translateY(-10px); */
        /* box-shadow: rgba(99, 99, 99, 0.24) 0px 3px 8px; */
    }

    .marketplace .nft .item .img-area{
        width: 100%;
        height: 320px;
        position: relative;
    }

    .marketplace .nft .item .img-area .thumbnail{
        width: 100%;
        height: 100%;
    }

    .marketplace .nft .item .img-area .thumbnail img,
    .marketplace .nft .item .img-area .thumbnail video{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0;
    }

    .video{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0;
    }

    .marketplace .nft .item .img-area .switch-btn{
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 50%;
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 30;
    }

    .marketplace .nft .item .img-area .switch-btn:hover{
        opacity: 0.2;
    }

    .marketplace .nft .item .nft-countdown{
        /* width: 80%;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 50%; 
        transform: translateX(-50%);
        background-color: var(--theme-color);
        padding: 5px !important;
        border-radius: 20px;
        gap: 10px; */
        color: var(--theme-color);
    }

    .marketplace .nft .item a{ 
        color: lightgray;
        text-decoration: none ;
    }

    .lightmode .marketplace .nft .item .info-area .title-area p{
        color: #000000;
        opacity: 1;
    }

    .marketplace .nft .item .info-area .title-area p{
        color: white;
    }

    .marketplace .nft .item .info-area .title-area .category{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .marketplace .nft .item .content-area .cert{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        /* border-bottom: 1px solid gray;
        box-shadow: 10px 10px 10px black; */
    }

    .marketplace .nft .item .content-area p img{
        margin-bottom: 3px;
        margin-right: 5px;
    }

    .marketplace .nft .item .like-area{
        padding-top: 5px;
        border-top: 1px solid rgb(90, 90, 90);
        display: flex;
        justify-content: space-between;
    } 

    .lightmode .marketplace .nft .item .like-area{
        border-top: 1px solid transparent;
        background: linear-gradient(rgba(233, 233, 233, 0.85) 0%, rgb(255, 255, 255) 20%);
    }

    /* sidebar */
    .marketplace .sidebar{
        background-color: #2F2F2F;
        /* padding: 20px; */
        padding-top: 10px;
        position: -webkit-sticky;
        position: fixed;
        top: 100px;
        bottom:0;
        position:fixed;
        overflow: auto;
        padding-top:20px;
        z-index: 40;
        left: 0;
    }

    .lightmode .marketplace .sidebar {
        background-color: white;
        box-shadow: 0 0 10px rgb(3 0 16 / 20%);
    }

    .marketplace .sidebar .reset-btn{
        color: white;
        font-size: 16px;
    }

    .marketplace .sidebar .reset-btn:focus{
        box-shadow: none;
    }

    .marketplace .close-btn{
        position: absolute;
        right: 10px;
        top: 25px;
        font-size: 30px;
    }

    .marketplace .sidebar::-webkit-scrollbar {
        /* display: none; */
        width: 5px;
        background-color: darkgray;
    }

    .marketplace .sidebar::-webkit-scrollbar-track {
        /* box-shadow: inset 0 0 5px rgb(5, 5, 5);  */
        background-color: #111011;
    }
 
    .marketplace .sidebar .title-area{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        padding: 1rem;
        border-bottom: 1px solid rgb(88, 88, 88);
    }

    .marketplace .sidebar .content-area .content{
        max-height:250px;
        border-color: rgba(0, 0, 0, 0);
        overflow-y: hidden;
        transition: border-color 0.2s ease-in 0s;
    }

    .marketplace .sidebar .content-area .content p,button{
        font-size: 15px;
    }

    .marketplace .sidebar .content-area .content:hover{
        /* background-color: red; */
        border-color: rgba(0, 0, 0, 0.4);
        overflow-y: auto;
    }

    .marketplace .sidebar .content-area .content::-webkit-scrollbar {
        /* display: none; */
        width: 5px;
        background-color: darkgray;
    }
    
    .marketplace .sidebar .content-area .content::hover {
        display: none;
        /* width: 5px; */
        /* background-color: darkgray; */
    }

    .marketplace .sidebar .status .content-area{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        grid-gap: 10px;
        height: 100%;
    }

    .marketplace .sidebar .status .content-area .unselect{
        background-color: transparent;
    }

    .marketplace .sidebar .status .content-area .unselect.active{
        background-color: orange;
    }

    .marketplace .filter-btn{
        position: fixed;
        width: 250px;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%);
        padding: 10px;
        border-radius: 20px;
        border: none;
        background-color: var(--theme-color);
        color: white;
        font-size: 20px;
        font-weight: bold;
        z-index: 200;
    }

    .marketplace .filter .title-area p{
        font-size: 18px;
        color: var(--theme-color);
    }

    .marketplace .sidebar .category .content-area .form-check-label{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .marketplace .sidebar .filter-input{
        display: flex;
        align-items: center;
        background-color: white;
        padding: 0 10px;
        border-radius: 5px;
    }

    .marketplace .sidebar .filter-input input{
        border: none;
    }

    .marketplace .sidebar .filter-input input:focus{
        box-shadow: unset;
    }

    .favourites {
        fill: red;
    }

    .unfavourites {
        fill: var(--default);
    }

    .grid-button-container {
        display: none;
    }

    .grid-button {
        margin: 8px;
    }

    .grid-button:hover {
        fill: white;
    }

    .grid-button-active {
        fill: var(--theme-color);
        margin: 8px;
        font-size: 140%;
    }

@media only screen and (min-width: 1000px) {
    /* .marketplace .nft{
        margin-left: 16.66666667%;
    } */

    .marketplace .header{
        top:80px;
    }

    .marketplace .ml-16{
        margin-left: 16.66666667%;
    }

    .marketplace .filter-btn{
        display: none;
    }
    
    /* sidebar */
    .marketplace .sidebar{
        padding-top: 50px;
    }

    .marketplace .nft{
        margin-top: 0px;
    }

    /* .marketplace .nft .item .img-area{
        height: 200px;
    } */

    /* 
    .marketplace .nft .item{
        height: 300px;
    } */

    .grid-button-container {
        display: flex;
        flex-direction: row;
        margin-top: 80px;
        justify-content: flex-end;
    }
}
</style>

<script>
import axios from "axios";
import moment from "moment";
const numeral = require("numeral");

const config = {
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem("access_token"),
  },
}

export default {
    name:'Marketplace',
    data(){
        return{
            interval:'',
            nfts:[
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:1, symbol:'BNB'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:2, symbol:'BUSD'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:20, symbol:'USDT'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:10.32, symbol:'USDC'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:1.23, symbol:'USDT'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:9, symbol:'USDC'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:9, symbol:'BUSD'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:12, symbol:'BNB'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:3, symbol:'USDT'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:15, symbol:'USDC'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:12, symbol:'BUSD'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:35, symbol:'BNB'},
                // {category:'Straits Settlements', pmg_cert:"# 8033033-001", price:100, symbol:'USDC'}
            ],
            totalNft:0,
            years:[],
            amounts:[],
            categories:[],
            selectedAmount:[],
            selectedYear:'',
            selectedCategory:'',
            selectedSortBy:'default',
            selectedStatus:'',
            searchInput:'',
            pageSize:10,
            currentPage:1,
            auctionNft:[],
            sidebarToggle:[
                true,false,false,false,false
            ],
            filterCategoryInput:'',
            defaultCategories:'',
            filterDenominationInput:'',
            defaultAmounts:'',
            filterYearInput:'',
            defaultYears:'',
            defaultThumbnail:require('../assets/images/bg.jpg'),
            currentUserAddress: localStorage.getItem("address"),
            onChange_Grid: true
        }
    },
    watch:{
        selectedAmount(){
            this.fetchNft();
        },
        selectedYear(){
            this.fetchNft();
        },
        selectedCategory(){
            this.fetchNft();
        },
        selectedSortBy(){
            this.fetchNft();
        },
        selectedStatus(){
            this.fetchNft();
        },
        searchInput(){
            this.fetchNft();
        },
        auctionNft(){
            // this.auctionCountdown();
        },
        filterCategoryInput(value){
            if(!value){
                this.categories = this.defaultCategories;
                return;
            }
            let search = new RegExp(this.filterCategoryInput,'i');
            let filterCategory = this.categories.filter(item=>search.test(item.name));

            this.categories = filterCategory;
        },
        filterDenominationInput(value){
            if(!value){
                this.amounts = this.defaultAmounts;
                return;
            }
            let search = new RegExp(this.filterDenominationInput,'i');
            let filterAmounts = this.amounts.filter(item=>search.test(item.amount));

            this.amounts = filterAmounts;
        },

        filterYearInput(value){
            if(!value){
                this.years = this.defaultYears;
                return;
            }
            let search = new RegExp(this.filterYearInput,'i');
            let filterYear = this.years.filter(item=>search.test(item.year));

            this.years = filterYear;
        },

    },
    beforeRouteLeave(to, from, next) {
        console.log('chaneg page')
        clearInterval(this.interval);
        next();
    },
    mounted(){
        document.body.style.backgroundImage = "none"
        this.fetchNft();
        this.fetchAmount();
        this.fetchYear();
        this.fetchCategory();  
        if(document.documentElement.offsetHeight < 1700){
            this.loadMore();
        }
        
        window.onscroll = () => {
            let bottomOfWindow =  document.documentElement.offsetHeight - (document.documentElement.scrollTop + window.innerHeight) <= 2;
            // console.log(bottomOfWindow,document.documentElement.scrollTop + window.innerHeight,document.documentElement.offsetHeight)
            if (bottomOfWindow) {
                // console.log('is bottom')
                if (this.nfts.length < this.totalNft) {
                    this.loadMore()
                    clearInterval(this.interval);
                    this.auctionCountdown();
                }
                
            }
        }
    },
    methods:{
        changeSrc(element,index){
            
            let switchBtnSelectors = document.querySelectorAll('.img-area .switch-btn')
            let thumbnailSelectors = document.querySelectorAll('.img-area .thumbnail');

            // check current element is video or img
            let currentElementClass = thumbnailSelectors[index].className;
            if(currentElementClass == 'thumbnail is_play'){
                let img = this.nfts[index].thumbnail_picture;

                thumbnailSelectors[index].classList.remove('is_play');
                thumbnailSelectors[index].innerHTML = `<img src="${img}" alt="thumbnail">`;
                switchBtnSelectors[index].innerHTML = `<i class="bi bi-play-fill"></i>`;

                element.preventDefault();
                return;
            }

            // set target to pause
            // set others to play
            switchBtnSelectors = [...switchBtnSelectors];

            switchBtnSelectors[index].innerHTML = `<i class="bi bi-pause-fill"></i>`

            switchBtnSelectors.splice(index,1);

            switchBtnSelectors.forEach((item)=>{
                item.innerHTML = `<i class="bi bi-play-fill"></i>`
            });


            // change target from img to video
            // change others from video to img
            thumbnailSelectors = [...thumbnailSelectors]

            let thumbnailVideo = this.nfts[index].thumbnail;
            thumbnailSelectors[index].innerHTML = `<video autoplay loop muted playsinline>
                                                        <source src="${thumbnailVideo}" type="video/mp4">
                                                    </video>`;

            thumbnailSelectors[index].classList.add('is_play')
                                    
            thumbnailSelectors.forEach((item,i)=>{
                if(i == index){
                    return;
                }

                let img = this.nfts[i].thumbnail_picture;

                item.innerHTML = `<img src="${img}" alt="thumbnail">`
                item.classList.remove('is_play')
            }); 
             
            element.preventDefault();
            
            return false;
        },

        sidebarToggleClick(index){
            this.sidebarToggle[index] = !this.sidebarToggle[index];
            this.$set(this.sidebarToggle, index, this.sidebarToggle[index])
        },

        openFilterModal(){
            document.querySelector('.marketplace .sidebar').style.setProperty('display','block','important');
            document.getElementById('filterBtn').style.setProperty('display','none');
            document.getElementById('filterCloseBtn').style.setProperty('display','block','important');
        },

        closeFilterModal(){
            document.querySelector('.marketplace .sidebar').style.setProperty('display','none','important');
            document.getElementById('filterBtn').style.setProperty('display','block');
            document.getElementById('filterCloseBtn').style.setProperty('display','none');
        },

        clearFilter(){
            this.selectedAmount = [];
            this.selectedYear = '';
            this.selectedCategory = '';
            this.selectedSortBy = 'default';
            this.selectedStatus = '';
            this.currentPage = 1;

            this.fetchNft();
        },

        changeSelectedCatogory(value,event){
            if(value == this.selectedCategory){
                this.selectedCategory = '';
                return;
            }
            
            let categorySidebarSelect = document.querySelectorAll('.marketplace .sidebar .category input[type="checkbox"]');
            categorySidebarSelect.forEach((value)=>{
                value.checked = false
            });

            event.target.checked = true;
            this.selectedCategory = value;
        },

        changeSelectedYear(value,event){
            if(value == this.selectedYear){
                this.selectedYear = '';
                return;
            }
            
            let yearSidebarSelect = document.querySelectorAll('.marketplace .sidebar .year input[type="checkbox"]');
            yearSidebarSelect.forEach((value)=>{
                value.checked = false
            });

            event.target.checked = true;
            this.selectedYear = value;
        },

        async loadMore(){
            let collection = []
            this.currentPage ++;
            const baseUrl = process.env.VUE_APP_API;
            
            try{
                collection = await axios.get(`${baseUrl}/nft/allpmg`,{params:{
                    pageSize:this.pageSize,
                    page:this.currentPage,
                    category_name:this.selectedCategory,
                    type_name:"",
                    cert_number:"",
                    status:this.selectedStatus,
                    amount:this.selectedAmount.length > 0 ? JSON.stringify(this.selectedAmount) : '',
                    year:this.selectedYear,
                    sort:this.selectedSortBy
                }});
            }catch(error){
                console.error(error);
            }
            
            for (let i=0; i < collection.data.results.length; i++) {
                // console.log(collection.data.results[i]);
                this.nfts.push(collection.data.results[i]);
                if(collection.data.results[i].expiry_date != null){
                    console.log('auction nft',collection.data.results[i]);
                    this.auctionNft.push(collection.data.results[i]);
                }
            }
        },

        async fetchNft(){
            const baseUrl = process.env.VUE_APP_API;
            this.currentPage = 1;

            try{
                let nfts = await axios.get(`${baseUrl}/nft/allpmg`,{params:{
                    pageSize:this.pageSize,
                    page:this.currentPage,
                    category_name:this.selectedCategory,
                    type_name:"",
                    status:this.selectedStatus,
                    amount:this.selectedAmount.length > 0 ? JSON.stringify(this.selectedAmount) : '',
                    year:this.selectedYear,
                    sort:this.selectedSortBy,
                    user_address: this.currentUserAddress ?? null,
                    search_input:this.searchInput
                }});

                this.nfts = nfts.data.results;

                this.totalNft = nfts.data.metadata.total;
                this.auctionNft = this.nfts.filter(data=>data.expiry_date != null);
                this.auctionCountdown();
                
                console.log(this.currentUserAddress)
                console.log(this.nfts)
                return nfts;
            }catch(error){
                console.log(error);
            }

            if(window.innerWidth >= 1900){
                this.loadMore();
            }
        },

        async fetchYear(){
            const baseUrl = process.env.VUE_APP_API;
            try{
                let years = await axios.get(`${baseUrl}/pmg/pmg_year`);

                this.years = years.data;
                this.defaultYears = years.data;
                // console.log(this.years);
            }catch(error){
                console.log(error);
            }
        },

        async fetchAmount(){
            const baseUrl = process.env.VUE_APP_API;
            try{
                let amounts = await axios.get(`${baseUrl}/pmg/pmg_amount`);

                this.amounts = amounts.data;
                this.defaultAmounts = amounts.data;
            }catch(error){
                console.log(error);
            }
        },

        async fetchCategory(){
            const baseUrl = process.env.VUE_APP_API;
            try{
                let categories = await axios.get(`${baseUrl}/category`);

                this.categories = categories.data;
                this.defaultCategories = categories.data;
            }catch(error){
                console.log(error);
            }
        },

        filterStatus(status){
            if(status == this.selectedStatus){
                this.selectedStatus = ''
                return false;
            }

            this.selectedStatus = status; 
        },

        formatNumber(num, symbol = 'USDT'){
            let fourDecimalCoin = ['BNB','ETH'];
            let decimal = fourDecimalCoin.includes(symbol) ? 4 : 2;
            
            let formatedDecimal = Math.floor(num * Math.pow(10, 4)) / Math.pow(10, 4);
            return formatedDecimal > 100000 ? numeral(formatedDecimal).format('0,0a').toUpperCase() :numeral(formatedDecimal).format(decimal == 2 ? '0,0.00' : '0,0.0000');	
        },

        auctionCountdown(){
            this.interval = setInterval(async() => {
                try{
                    for(let i=0; i < this.auctionNft.length; i++){
                    // get expire time
                    let expiredTime = moment(this.auctionNft[i].expiry_date).format("x");
                    let now = moment().format("x");
                    let timeLeft = expiredTime - now;

                    // query selector nft countdown
                    let countdownSelector = document.querySelectorAll('.nft-countdown');
                    // console.log('count down selector',countdownSelector[i],i);
                    // check timeleft
                    if(timeLeft <= 0){
                        if(this.auctionNft[i].auction_status == 'Passed'){
                            countdownSelector[i].style.color = '#dc3545';
                        }else{
                            countdownSelector[i].style.color = '#27a17b';
                        }
                        countdownSelector[i].innerHTML = `<i class="bi bi-clock-history me-1"></i> ${this.auctionNft[i].auction_status}`;
                        // this.auctionNft.splice(i,1);                    
                    }else{
                        countdownSelector[i].innerHTML = `
                            <i class="bi bi-clock me-1"></i>
                            <span class="days">${moment.duration(timeLeft).days()}</span><span class="count-txt">D</span>
                            <span class="hours">${moment.duration(timeLeft).hours()}</span><span class="count-txt">H</span>
                            <span class="minutes">${moment.duration(timeLeft).minutes()}</span><span class="count-txt">M</span>
                            <span class="seconds">${moment.duration(timeLeft).seconds()}</span><span class="count-txt">S</span>
                        `
                    }
                }
                }catch(err){
                    clearInterval(this.interval);
                }
                // this.countDown.day = moment.duration(timeLeft).days();
                // this.countDown.hour = moment.duration(timeLeft).hours();
                // this.countDown.minutes = moment.duration(timeLeft).minutes();
                // this.countDown.second = moment.duration(timeLeft).seconds();
            }, 1000);
        },

        connectWallet(){
            this.$emit('connectWallet');
        },

        async favourite(nft_id) {
            console.log('nft_id',nft_id)
            const baseUrl = process.env.VUE_APP_API;
            // const status = await axios.post(`${baseUrl}/favourite/update`, {
            axios.post(`${baseUrl}/favourite/update`, {
                "user_address": this.currentUserAddress,
                "nft_id": nft_id
            }, config).then(async () => {
                let element = document.getElementById(`fave${nft_id}`)
                let count = document.getElementById(`faveCount${nft_id}`)
                if (element.classList.contains("favourites")) {
                    element.classList.remove("favourites")
                    element.classList.add("unfavourites")
                    count.innerHTML = parseInt(count.innerHTML) - 1
                } else {
                    element.classList.remove("unfavourites")
                    element.classList.add("favourites")
                    count.innerHTML = parseInt(count.innerHTML) + 1
                }
            })
            // this.nfts.forEach((element,index)=>{
            //     if(element.nft_id == nft_id){
            //         this.nfts[index].like_status =  this.nfts[index].like_status ==0 ? 1 : 0 ;
            //         this.nfts[index].total_likes= this.nfts[index].like_status == 0 ? this.nfts[index].total_likes - 1 : this.nfts[index].total_likes + 1;
            //         console.log("Update ",this.nfts[index])
            //     }
            // });
            // let nft = this.nfts.find(a => a.nft_id == nft_id)
            // console.log("current like", nft.like_status)
            // nft[nft_id].like_status = nft.like_status == 0 ? 1 : 0;
            // nft.total_likes = nft.like_status == 0 ? nft.total_likes - 1 : nft.total_likes + 1;
            // this.fetchNft();
        }
    },

}
</script>
