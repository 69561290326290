<template>
  <!-- <div :class="lightmode == 'light' ? 'about light' : 'about'"> -->
  <div class="about mt-3">
    <!-- <div class="about-banner">
      <div class="title">{{ $t('About Us') }}</div>
      <div class="sub-title">
        {{ $t("The World First & Largest Numismatic NFT Marketplace") }}
      </div>
    </div> -->
    <div class="about-section">
      <div class="about_title">{{$t("About ")}}<span class="orangify">NumisArt</span></div>
      <div class="about_sub-title">
        <div>{{$t("The World's First And Largest Numismatic NFT Trading Market")}}</div>
        <div>{{$t("Let's Rewrite The History Of Currency Trading Together!")}}</div>
      </div>
      <div class="about-wrapper">
        <div class="left-content">
          <div>{{ $t("Why was NumisArt created? Because we, like you, are passionate about currency collecting and trading!") }}</div>
          <div>{{ $t("NumisArt is the world's first and largest numismatic NFT trading platform created by Trigometric. We sincerely invite currency collectors from all over the world to rewrite history, change the game, and make it more interesting and valuable!") }}</div>
          <div>{{ $t("The birth of NumisArt means currency collectors will no longer feel lonely again! Here, collectors can show their currency collection to the world, find more kindred spirits, and encounter better trading opportunities. More importantly, as a creator, your ownership is forever secured in history. That’s not all – you’ll also get a 5% royalty fee for every transaction. In other words, you can build a source of income that transcends generations through NumisArt!") }}</div>
          <div>{{ $t("NumisArt firmly believes that coins and NFTs are the perfect combination, the perfect marriage between virtual and reality. We hope this platform can create a richer, more diverse, fairer and more transparent currency trading market. Creating a dynamic new economy, letting more people fall in love with currency collecting, and building more personal digital assets through NumisArt, while granting them lifelong ownership guarantee.") }}</div>
        </div>
        <div class="right-content">
          <img src="../assets/images/about-us.png" alt="building_an_open_digital_economy">
        </div>
      </div>

      <div class="trigo-container">
        <div class="about_title">{{$t("About ")}}<span class="orangify">Trigometric</span></div>
        <div class="trigo-wrapper">
          <div class="left-content">
            <div>{{ $t("Established in 2002, Trigometric is the largest auctioneer of collectibles in Malaysia, with collections including currency and stamps.") }}</div>
            <div>{{ $t("Trigometric has participated in many different international exhibition trade shows, including high-profile education trade shows and conventions.") }}</div>
            <div>{{ $t("Trigometric has in-depth research in the field of numismatics, has published a large number of reference materials and guides, and is known as the foremost expert in numismatics within Malaysia.") }}</div>
            <div>{{ $t("At Trigometric, we use the latest technology to provide better services to our clients and become a more influential brand in the world of collecting.") }}</div>
            <div>{{ $t("Learn more about Trigometric:") }} <a href="https://www.trigo.co/about">https://www.trigo.co/about</a></div>
          </div>
          <div class="right-content">
            <img :src="lightMode == 'light' ? trigoLogo.dark : trigoLogo.light" alt="about_trigometric_pmg">
          </div>
        </div>
      </div>

    </div>
    

    <!-- <div class="about_icons-group">
      <div class="about_img-wrapper">
        <img src="../assets/images/icons/founded.png">
        <div class="numbers">2022</div>
        <div class="desc">{{ $t('Founded') }}</div>
      </div>
      <div class="about_img-wrapper">
        <img src="../assets/images/icons/employees.png">
        <div class="numbers">200+</div>
        <div class="desc">{{ $t('Employees') }}</div>
      </div>
      <div class="about_img-wrapper">
        <img src="../assets/images/icons/users.png">
        <div class="numbers">600K+</div>
        <div class="desc">{{ $t('Users') }}</div>
      </div>
      <div class="about_img-wrapper">
        <img src="../assets/images/icons/collections.png">
        <div class="numbers">2M+</div>
        <div class="desc">{{ $t('Collections') }}</div>
      </div>
      <div class="about_img-wrapper">
        <img src="../assets/images/icons/nfts.png">
        <div class="numbers">80M+</div>
        <div class="desc">{{ $t('NFTs') }}</div>
      </div>
      <div class="about_img-wrapper">
        <img src="../assets/images/icons/volume.png">
        <div class="numbers">$20B+</div>
        <div class="desc">{{ $t('Volume') }}</div>
      </div>
    </div> -->

    <!-- <div class="about_meet-numisart">
      <div class="title">{{ $t('Meet NumisArt') }}</div>
      <div class="desc">{{ $t('The NFT marketplace with all numismatic item for everyone') }}</div>
      <img v-if="!isPlaying" @click="isPlaying = true" src="../assets/images/homepage/numisart-video.png" alt="nmc_video">
      <video v-if="isPlaying" autoplay controls src="../assets/images/homepage/meet-numisart.mp4"></video>
    </div> -->

    <div class="container">
      <!-- How it works -->
      <!-- <div class="content-title">
        <hr />
        <div class="title">{{ $t('How it works') }}</div>
        <hr />
      </div>

      <div class="how-row">
        <div class="how-content">
          <img src="../assets/images/icon_nft.png" alt="nft" />
          <div class="title">{{ $t("NFT Digital Assets") }}</div>
          <div class="content">
            {{ $t("NFT certifies digital assets to be unique, tracked on blockchains and provide the owner with a proof of ownership.") }}
          </div>
        </div>

        <div class="how-content">
          <img src="../assets/images/icon_metamask.png" alt="metamask" />
          <div class="title">{{ $t("MetaMask") }}</div>
          <div class="content">
            {{ $t("MetaMask allows users to store, manage, send and receive digital assets in a secure and safe environment.") }}
          </div>
        </div>
        <div class="how-content">
          <img src="../assets/images/icon_numisart.png" alt="numisart" />
          <div class="title">{{ $t("NumisArt") }}</div>
          <div class="content">
            {{ $t("NumisArt is a marketplace, specifically curated for Numismatic NFTs on the BSC blockchain technology. Here you can buy, sell & auction assets.") }}
          </div>
        </div>
      </div> -->

      <!-- The numismatic nft  -->
      <div class="about_content-wrapper">
        <div class="content-title">
          <hr />
          <div class="title">{{ $t('The Numismatic NFT') }}</div>
          <hr />
        </div>

        <div class="numismatic-container">
          <div class="numismatic-left-container">
            <!-- <img src="../assets/images/gif/about-sample.gif" /> -->
            <video autoplay loop muted playsinline>
              <source src="../assets/images/new-pmg-gif/big.mp4" type="video/mp4">
            </video>

            <div class="numismatic-content">
              <div>{{ $t('CERTIFIED By CERTIK') }}</div>
              <div>
                {{ $t('The NFTs are listed on our CERTIK certified platform marketplace, ensuring a save and reliable storage and listing service.') }}
              </div>
            </div>

            <div class="numismatic-content">
              <div>{{ $t('Collectible & Transferable') }}</div>
              <div>
                {{ $t('All NFTs are compiled in your own proivate collection and can be sold or traded with other collectors on the NumisSpace NFT Market.') }}
                <br />
                <br />
                {{ $t('All minted NFTs is stored in your own MetaMask Wallet, easily managed and accessible.') }}
              </div>
            </div>
          </div>

          <div class="numismatic-right-container">
            <div class="numismatic-content numismatic-first-content">
              <div>{{ $t('PMG Graded Label') }}</div>
              <div>
                {{ $t('With an unparalleled commitment to accuracy, consistency and integrity, PMG is the world’s largest third-party paper money grading service with more than 6,000,000 notes certified. Every note that PMG grades is backed by the comprehensive PMG guarantee of grade and authenticity, which gives buyers and sellers greater confidence.') }}
              </div>
            </div>

            <div class="numismatic-content">
              <div>{{ $t('Certification Information') }}</div>
              <div>
                {{ $t('Basic information including the PMG certification number is included on the NFT, along with all the information about the Banknote.') }}
              </div>
            </div>

            <div class="numismatic-content">
              <div>{{ $t('The PMG Grading Scale') }}</div>
              <div>
                {{ $t('PMG uses a 70-point numerical scale derived from the internationally recognized Sheldon grading scale.') }}
                
              </div>
            </div>

            <div class="numismatic-content">
              <div>{{ $t('PMG Certification Verification') }}</div>
              <div>
                {{ $t("With PMG's Partnership, we have also enabled the PMG Certification Verification on our NFTs. Each NFT has its own certification verification code.") }}
                <br />
                <br />
                {{ $t("Enter a note's PMG certification number (circled in the image) and grade to confirm its description and grade in PMG's database and, if available, view the images of the note taken by PMG.") }}
              </div>
            </div>

            <div class="numismatic-content">
              <div>{{ $t('Educational') }}</div>
              <div>
                {{ $t('With accurate numismatic information, one can learn the origins of the banknote.') }}
                
              </div>
            </div>

            <div class="numismatic-content">
              <div>{{ $t('Professionally Designed & Generated') }}</div>
              <div>
                {{ $t('Easily recognisable and distinct on its own.') }}
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Why Nft -->
      <div class="content-title">
        <hr />
        <div class="title">{{ $t('Why NFTs') }}</div>
        <hr />
      </div>

      <div class="d-flex flex-column">
        <div class="whyNFT-top-container">
          <div class="whyNFT-content whyNFT-left">
            <img src="../assets/images/icons/nmc-diff.png" />
            <div class="title">{{ $t('What Makes Your NFTs Different') }}</div>
            <div class="content">
              {{ $t('Our NFTs are one coherent body. The NFTs are directly connected to the real tangible graded banknote, one exist for the other.') }}
            </div>
          </div>

          <div class="whyNFT-content whyNFT-right">
            <img src="../assets/images/icons/why-nmc.png" />
            <div class="title">{{ $t('Why Numismatic NFT') }}</div>
            <div class="content">
              {{ $t('The same reason why you grade your notes with PMG. PMG is the certification you need in reality. NumisNFTs is the certification you need in the Metaverse. Forming one systematized coherent body.') }}
            </div>
          </div>
        </div>

        <div class="whyNFT-content last-whyNFT-content">
          <img src="../assets/images/icons/yes-but-why.png" />
          <div class="title">{{ $t('Yes, But Why Mint The NFT At All?') }}</div>
          <div class="content">
            {{ $t("Leonardo Da Vinci's painting, the 'Salvator Mundi' was recently sold at auction for a record breaking $450 Million Dollars. What happens when that painting deteriorates over time? Its materials go through a drying process which sets up internal stresses in the structure. As the painting continues to age, both the paint film and priming lose flexibility and become brittle.") }}
            <br />
            <br />
            {{ $t("The important question is, will 'Salvator Mundi' still worth $450 Million Dollars in 10 years? 30 years? The answer is simple, No.") }}
            <br />
            <br />
            {{ $t("The current owner of the 'Salvator Mundi', Abu Dhabi's Department of Culture and Tourism, have been looking into creating an NFT for the Painting. The reason is simple, NFT is the only way to protect the heritage of this painting. Only by creating an NFT for the painting, they ensure that the legacy is preserved.") }}
          </div>
        </div>
      </div>

      <!-- Our mission  -->
      <div class="mission-container">
        <div class="mission-left-content">
          <div class="title">{{ $t('Our Mission') }}</div>
          <div class="content">
            <div>
              {{ $t('NUMISMATIC connects the two different worlds of numismatics and meta-universe.') }}
            </div>
            <div>
              {{ $t('We want to reproduce (handmade) the most important coins of every time and every country, to give anyone the opportunity to own a NumisNFT Collection to be proud of it.') }}
            </div>
            <div>
              {{ $t('Become a NumisNFT collector, and start collecting art, history and culture, mint your NFT Coins and begins a collection that will acquire more and more value over time!') }}
            </div>
          </div>
        </div>

        <div v-if="lightMode == 'dark'" class="mission-right-content">
          <img src="../assets/images/mission-coin.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default({
  props: [
      "lightMode"
  ],
  mounted() {
    document.body.style.backgroundImage = "none"
  },
  data() {
    return {
      trigoLogo:{
        dark: require('../assets/images/icons/trigoLogo.png'),
        light: require('../assets/images/icons/trigoLogo-Light.png'),
      },
      isPlaying: false
    }
  }
})
</script>

<style scoped>
.about_title,
.about_sub-title {
  text-align: center;
  font-size: auto;
}
.about_title {
  font-size: 70px;
  color: white;
}
.lightmode .about_title {
  color: var(--dark);
}
.about_sub-title {
  font-size: 25px;
  color: var(--default);
  margin-bottom: 30px;
}

.about_meet-numisart {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;  position: absolute;
  left: 0;
  right: 0;
  background-color: #222323;
  padding: 30px 0;
  margin-top: 20px;
}

.lightmode .about_meet-numisart {
  background-color: #F3FBFE;
}
.about_meet-numisart .title {
  font-size: 24px;
  margin: 20px auto;
  border-radius: 0;
  color: white;
}

.about_meet-numisart .desc {
  font-size: 14px;
}

.about_meet-numisart video,
.about_meet-numisart img {
  width: 90%;
  margin-top: 30px;
  border-radius: 0;
  max-width: 900px;
}
.about {
  /* min-height: 100vh;s */
}

.about hr {
  align-self: center;
  background: white;
  width: 100%;
}

.about-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
  background-image: url('../assets/images/about.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-banner .title {
  color: white;
  font-size: 24px;
  font-weight: 800;
}

.about-banner .sub-title {
  color: var(--dim-white);
  font-size: 24px;
  text-align: center;
}

.content-title {
  display: flex;
  margin: 4.5rem 0rem;
}

.content-title .title {
  color: white;
  font-size: 24px;
  font-weight: 800;
  min-width: fit-content;
  text-align: center;
  margin: 0px 15px;
}

.how-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3rem;
}

.how-content {
  width: 28%;
  display: flex;
  flex-direction: column;
  background-image: url('../assets/images/Path 369.png');
  background-repeat: no-repeat;
  background-size: contain;
  padding: 25px;
  min-height: 290px;
}

.how-content img {
  width: 60px;
}

.how-content .title {
  font-weight: 700;
  font-size: 24px;
  color: white;
  margin: 10px 0px;
}

.lightmode .about-banner .title {
  color: white;
}

.lightmode .title,
/* .lightmode .numismatic-content div:first-child, */
.lightmode .mission-container .title {
  color: var(--dark);
}

.how-content .content {
  margin-top: 0px;
  font-size: 1.1rem;
}

.numismatic-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5rem;
}

.numismatic-left-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 1.5rem;
}

.numismatic-left-container video {
  margin-bottom: 3rem;
  border:1px solid #e3af72;
  border-radius: 15px;
}

.numismatic-right-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 1.5rem;
}

.numismatic-content {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.numismatic-content div:first-child {
  /* color: white; */
  font-size: 24px;
  font-weight: 800;
  margin: 1rem 0rem;
}

.numismatic-content div:last-child {
  font-size: 1.1rem;
}

.numismatic-first-content,
.numismatic-first-content div:first-child {
  margin-top: 0rem;
}

.whyNFT-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
  background-color: #1f2636;
  border-radius: 10px;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
}

.lightmode .whyNFT-content {
  background-color: transparent;
}

.whyNFT-top-container {
  display: flex;
  flex-direction: row;
}

.whyNFT-content img {
  width: 100px;
  margin-bottom: 1.5rem;
}

.whyNFT-content .title {
  font-weight: 700;
  font-size: 24px;
  /* color: white; */
  margin: 10px 0px;
  text-align: center;
}

.whyNFT-content .content {
  margin-top: 0px;
  font-size: 1.1rem;
  text-align: center;
}

.whyNFT-left {
  width: 50%;
  margin-right: 1rem;
}

.whyNFT-right {
  width: 50%;
  margin-left: 1rem;
}

.last-whyNFT-content {
  margin-top: 2rem;
}

.mission-container {
  margin: 8rem 0rem;
  width: 100%;
  box-shadow: -5px -5px 30px 0 #3d3773 inset, 5px 5px 30px 0 #3d3773 inset;
  border: 1px solid #3d3773;
  border-radius: 30px;
  padding: 80px 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lightmode .mission-container {
  box-shadow: none;
  border: 1px solid transparent;
}

.mission-left-content {
  /* width: 55%; */
  text-align: left;
}

.lightmode .mission-left-content {
  /* width: 55%; */
  text-align: center;
}

.mission-right-content {
  width: 40%;
}

.mission-container .title {
  color: white;
  font-size: 24px;
  font-weight: 800;
}

.mission-container .content {
  display: flex;
  flex-direction: column;
  margin-top: 0%;
  font-size: 1.1rem;
}

.mission-container .content div {
  margin-top: 2rem;
}

.about-section {
  width: 90%;
  max-width: 1300px;
  margin: 0 auto 50px auto;
}

.about-section .about-wrapper {
  display: flex;
  flex-direction: row;
  /* padding-top: 10%; */
  gap: 50px;
}

.about-section .trigo-wrapper {
  display: flex;
  flex-direction: row;
  /* padding-top: 10%; */
  gap: 30px;
  width: 90%;
  margin: auto;
}

.about-section .trigo-container {
  margin-top: 100px;
}

.about-section .trigo-container .about_title {
  margin-bottom: 50px;
}

.about-section .about-wrapper .about-title {
  color: white;
  font-size: 42px;
  padding-bottom: 20px;
  line-height: 1.15;
}

.lightmode .about-section .about-wrapper .about-title {
  color: var(--dark);
}

.about-section .about-wrapper .left-content,
.about-section .trigo-wrapper .left-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 700px;
}

.about-section .trigo-wrapper .left-content {
  max-width: 600px;
}

.about-section .about-wrapper .left-content,
.about-section .trigo-wrapper .right-content {
  flex: 1;
}

.about-section .trigo-wrapper .right-content {
  border-radius: 27px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 50px;
  height: 500px;
}

.about-section .about-wrapper .right-content {
  text-align: end;
}

.about_icons-group {
  display: flex;
  margin: auto;
  width: 70%;
  max-width: 500px;
  justify-content: space-between;
}

.about_img-wrapper {
  text-align: center;
}

/* .about_content-wrapper {
  margin-top: 800px;
} */

.orangify {
  color: var(--gold);
}

@media only screen and (max-width: 768px) {
  .about-section .about-wrapper .about-title {
    font-size: 25px;
    }
    .about_title{
    /* font-size: auto; */
    font-size: 25px;
  } 

  .about_sub-title{
    font-size: 16px;
  }

  .about_content-wrapper {
    /* margin-top: 50vh; */
  }
  .about_icons-group {
    width: 100%;
    display: grid;
    /* grid-template-columns: 25% 25% 25% 25%; */
    grid-template-columns: 50% 50%;
    row-gap: 10px;
    text-align: center;
  }
  .about_icons-group img {
    width: 25px;
    height: auto;
  }
  .about-section .about-wrapper {
    width: 100%;
    flex-direction: column;
    margin: auto;
    text-align: center;
  }
  .container {
    max-width: 1400px;
    padding: 0% 8%;
  }

  .about-banner {
    padding: 0px 20px;
    min-height: 30vh;
  }

  .about-banner .title {
    color: white;
    font-size: 2.5rem;
    font-weight: 800;
  }

  .about-banner .sub-title {
    font-size: 1rem;
  }

  .content-title {
    margin: 2rem 0rem;
  }

  .content-title .title {
    font-size: 1.5rem;
    margin: 0px 5px;
  }

  .how-row {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0rem;
  }

  .how-content {
    width: 90%;
    margin-bottom: 2rem;
  }

  .how-content .title {
    font-size: 1.2rem;
  }

  .how-content .content {
    margin-top: 0px;
    font-size: 1rem;
  }

  .numismatic-container {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .numismatic-left-container {
    width: 100%;
    margin-right: 0rem;
  }

  .numismatic-left-container img {
    margin-bottom: 1.5rem;
  }

  .numismatic-right-container {
    width: 100%;
    margin-left: 0rem;
  }

  .numismatic-first-content {
    margin-top: 2rem;
  }

  .numismatic-first-content div:first-child {
    margin-top: 1rem;
  }

  .numismatic-content div:first-child {
    font-size: 1.2rem;
  }

  .numismatic-content div:last-child {
    font-size: 1rem;
  }

  .whyNFT-content {
    padding: 35px 20px;
  }

  .whyNFT-top-container {
    flex-direction: column;
  }

  .whyNFT-content .title {
    font-size: 1.2rem;
    margin: 10px 0px;
  }

  .whyNFT-content .content {
    font-size: 1rem;
  }

  .whyNFT-left {
    width: 100%;
    margin-right: 0rem;
  }

  .whyNFT-right {
    width: 100%;
    margin-top: 2rem;
    margin-left: 0rem;
  }

  .mission-container {
    flex-direction: column-reverse;
    padding: 35px 30px;
    text-align: center;
    margin: 5rem 0rem;
  }

  .mission-left-content,
  .mission-right-content {
    width: 100%;
  }

  .mission-container .title {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .mission-container .content {
    font-size: 1rem;
  }

  .about-section .trigo-wrapper {
    display: flex;
    flex-direction: column;
    /* padding-top: 10%; */
    gap: 30px;
    width: 90%;
    margin: auto;
  }
}

@media only screen and (min-width: 400px) and (max-width: 768px) {
  .content-title .title {
    margin: 0px 10px;
    width: 24in;
  }

}
</style>
