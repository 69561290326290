<template>
    <div class="arrangeNFT">
        <!-- Function Bar -->
        <div v-if="nfts.length > 0"  class="function-bar-container">
            <!-- Number -->
            <div>
                <span class="latest-number">{{latestNumber}}</span>
            </div>

            <!-- Function Btn -->
            <div class="d-flex">
                <button class="btn btn-function me-3" @click="reset">
                    <b-icon-arrow-repeat class="accordion-arrow" font-scale="0.8"></b-icon-arrow-repeat>
                    Reset
                </button>
                <button class="btn btn-function" @click="updateNFTPlace">
                    <b-icon-arrow-up class="accordion-arrow" font-scale="0.8"></b-icon-arrow-up>
                    Update
                </button>
            </div>
        </div>

        <div class="pt-4 ps-4">
            <router-link to="/profile">
                <b-icon-arrow-left-circle font-scale="2" style="color:var(--theme-color)"></b-icon-arrow-left-circle>
            </router-link>
        </div>

        <!-- NFT -->
        <div class="container">
            <div class="text-center py-5" v-if="nfts.length == 0">
                <span>NO NFT FOUND</span>
            </div>
            <div class="py-5 nft-container">
                <!-- <button @click="updateNFTPlace">Update</button> -->
                <div @click.prevent="selectNFT(index)" class="item" v-for="(nft,index) in nfts" :key="nft.pmg_cert">
                    <div class="info">
                        <div class="number-container">
                            <span class="num"></span>
                        </div>
                        <img :src="nft.thumbnail_picture" alt="numisart-img">
                        <span class="category">{{nft.category}}</span>
                        <span class="cert">{{nft.pmg_cert}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .arrangeNFT .nft-container{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 150px));
        grid-gap: 20px;
        align-items: center;
        justify-content: center;
        color: black;
    }

    .lightmode .arrangeNFT .nft-container .item{
        background-color: rgb(255, 255, 255);
        border: 1px solid var(--default);
        border-radius: 10px;
        position: relative;
        color: black;
    }

    .arrangeNFT .nft-container .item{
        background-color: #2F2F2F;
        border: none;
        /* border: 1px solid var(--default); */
        border-radius: 10px;
        position: relative;
        color: white;
    }

    .arrangeNFT .nft-container .item .number-container{
        position: absolute;
        right: -10px;
        top: -10px;
        background-color: var(--theme-color);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        display:none ;
        font-weight: bold;
        color: black;
        /* border-top-right-radius: 10px; */
    }

    .arrangeNFT .nft-container .item .info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .arrangeNFT .nft-container .item .info img{
        border-radius: 10px 10px 0 0;
    }

    .arrangeNFT .nft-container .item .info .category{
        width: 90%;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
    }

    .arrangeNFT .nft-container .item .info .cert{
        opacity: 0.6;
        font-size: 16px;
    }

    .arrangeNFT .function-bar-container{
        position: fixed;
        bottom: 0;
        background-color: var(--theme-color);
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        color: black;
        z-index: 100;
    }

    .arrangeNFT .function-bar-container .btn-function{
        display: flex;
        justify-content: space-around;
        align-items: center;
        border:1px solid black;
        width: 100px;
    }

    .arrangeNFT .function-bar-container .btn-function:hover{
        opacity: 0.5;
    }

    .arrangeNFT .function-bar-container .latest-number{
        font-size: 20px;
        font-weight: bold;
    }

    @media only screen and (min-width: 1000px) {
        .arrangeNFT .nft-container{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            grid-gap: 20px;
            align-items: center;
        }
    }
</style>

<script>
import axios from "axios";

export default {
    name:"ArrangeNFT",
    data(){
        return{
            baseUrl:process.env.VUE_APP_API,
            currentUser: localStorage.getItem('address'),
            nfts:[],
            oldSelectedNFT:[],
            newSelectedNFT:[],
            address:localStorage.getItem('address'),
            latestNumber:"Next : 1"
        }
    },
    mounted() {
        this.fetchNFT();
        localStorage.removeItem('removedIndex');
    },
    methods: {
        async fetchNFT(){
            const response = await axios.get(`${this.baseUrl}/pmg/address/others?owner_address=${this.currentUser}&other_owner_address=${this.address}&pageSize=9999&page=1`);
            console.log(response);
            this.nfts = response.data.results;
            const cert_number = response.data.results.map((item)=>item.pmg_cert);
            console.log(cert_number);
        },

        selectNFT(index){
            let items = document.querySelectorAll('.item');
            const checkExists = this.newSelectedNFT.includes(this.nfts[index].pmg_cert);
            let checkReplaceIndex = localStorage.getItem('removedIndex');

            if(checkExists){
                const itemIndex = this.newSelectedNFT.indexOf(this.nfts[index].pmg_cert);
                this.newSelectedNFT.splice(itemIndex,1);
        
                let number = items[index].querySelector('.num').textContent;

                let removedIndex = localStorage.getItem('removedIndex') ? JSON.parse(localStorage.getItem('removedIndex')) : [];

                // convert to index and pushed to localstorage
                removedIndex.push(number - 1);

                removedIndex = removedIndex.sort(function(a,b){
                    return parseFloat(a) - parseFloat(b);
                });

                localStorage.setItem('removedIndex',JSON.stringify(removedIndex));
                items[index].querySelector('.num').innerHTML = '';
                items[index].querySelector('.number-container').style.display = 'none';

                this.latestNumber = `Next : ${removedIndex[0] + 1}`;

                return;
            }

            if(checkReplaceIndex){
                let replaceIndex = JSON.parse(checkReplaceIndex);

                this.newSelectedNFT.splice(replaceIndex[0],0,this.nfts[index].pmg_cert);

                replaceIndex.shift();

                console.log(replaceIndex);  

                if(replaceIndex.length > 0){
                    localStorage.setItem('removedIndex',JSON.stringify(replaceIndex));
                    this.latestNumber = this.newSelectedNFT.length == this.nfts.length ? "Done" : `Next : ${replaceIndex[0] + 1}`;
                }else{
                    localStorage.removeItem('removedIndex');
                    this.latestNumber =  this.newSelectedNFT.length == this.nfts.length ? "Done" : `Next : ${this.newSelectedNFT.length + 1}`;
                }

                items[index].querySelector('.num').innerHTML = this.newSelectedNFT.indexOf(this.nfts[index].pmg_cert) + 1;
                items[index].querySelector('.number-container').style.display = 'flex';
                

                // this.latestNumber = "";
            }else{
                // push data to selected
                this.newSelectedNFT.push(this.nfts[index].pmg_cert);
                items[index].querySelector('.num').innerHTML = this.newSelectedNFT.length;
                items[index].querySelector('.number-container').style.display = 'flex';


                this.latestNumber =  this.newSelectedNFT.length == this.nfts.length ? "Done" : `Next : ${this.newSelectedNFT.length + 1}`;
            }
            
        },

        async updateNFTPlace(){
            this.$swal.fire({
                title: 'Updating',
                icon: 'info',
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading()
                },
            });

            let pmg_cert = this.nfts.map(item=>item.pmg_cert);

            let difference = pmg_cert.filter(item => !this.newSelectedNFT.includes(item));

            if(difference.length > 0){
                this.newSelectedNFT = [...this.newSelectedNFT, ...difference];
            }

            // console.log(this.newSelectedNFT);
            const response = await axios.post(`${this.baseUrl}/pmg/rearrangePmgOrder`,{
                owner_address:this.address,
                pmg_cert:this.newSelectedNFT
            });

            console.log(response);

            this.$swal('',this.$t("Update success"),'success');

            this.fetchNFT();
            this.reset();
        },

        reset(){
            this.newSelectedNFT = [];
            let number = document.querySelectorAll('.num');
            number.forEach(item=>{
                item.innerHTML = '';
            });
            let numberContainer = document.querySelectorAll('.number-container');
            numberContainer.forEach(item=>{
                item.style.display = "none";
            });
            localStorage.removeItem('removedIndex');
            this.latestNumber = "Next : 1";
        }
    },
}
</script>