<template>
<div>
    <div id="fullpage" @click="closeFullPage"></div>
  <!-- preloader start here -->
    <div v-if="isLoading" class="preloader">
        <div class="preloader-inner">
            <div class="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
    <!-- preloader ending here -->

    <!-- ==========Item Details Section start Here========== -->
    <div class="item-details-section padding-top padding-bottom">
        <div class="container">
            <div class="item-details-wrapper">
                <div class="row g-5" v-if="mintable.length == 1">
                    <div class="col-lg-6">
                        <div class="item-desc-part">
                            <div class="item-desc-inner">
                                <div class="item-desc-thumb">
                                    <!-- <img src="assets/images/nft-item/item-details.gif" alt="item-img"> -->
                                    <div class="flip-card">
                                        <input id="flip" type="checkbox" v-model="isback"/>
                                        <label for="flip" class="flip-card-inner">
                                            <div class="flip-card-front">
                                                <img :src="mintable[0].real_image_front" alt="item-img">
                                            </div>
                                            <div class="flip-card-back">
                                                <img :src="mintable[0].real_image_back" alt="item-img">
                                            </div>
                                        </label>
                                    </div>
                                    <div class="image-functions">
                                        <div class="flip-indication">
                                            <img :src="lightMode == 'light' ? flipIcons.black : flipIcons.white" alt="flip-image">
                                            <span>{{ $t('Click Banknote') }}</span>
                                        </div>
                                        <div class="fullpage-btn">
                                            <b-icon-zoom-in @click="viewFullpage"></b-icon-zoom-in>
                                            <span @click="viewFullpage">{{ $t('View Full Size') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="item-buy-part">
                            <div class="nft-item-title">
                                <h3>{{ mintable[0].category }}</h3>
                                <p>{{ mintable[0].pmg_cert }}</p>

                                <div class="share-btn">
                                    <div class=" dropstart">
                                        <a class=" dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                            aria-expanded="false" data-bs-offset="25,0">
                                            <!-- <i class="icofont-share-alt"></i> -->
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" href="#"><span>
                                                        <i class="icofont-twitter"></i>
                                                    </span> Twitter </a>
                                            </li>
                                            <li><a class="dropdown-item" href="#"><span><i
                                                            class="icofont-telegram"></i></span> Telegram</a></li>
                                            <li><a class="dropdown-item" href="#"><span><i
                                                            class="icofont-envelope"></i></span> Email</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="details-tab tab-pane fade show active" id="nav-details"
                                            role="tabpanel" aria-labelledby="nav-details-tab">

                                            <p>{{ mintable[0].description }}</p>
                                            <div class="author-profile d-flex flex-wrap align-items-center gap-15">
                                                <!-- <div class="author-p-thumb">
                                                    <a href="author.html"><img src="assets/images/seller/02.gif"
                                                            alt="author-img "></a>
                                                </div> -->
                                                
                                            </div>
                                            <ul class="other-info-list">
            
                                                <li class="item-other-info">
                                                    <div class="item-info-title">
                                                        <h6>{{ $t('Token ID') }}</h6>
                                                    </div>
                                                    <div class="item-info-details">
                                                        <p>{{ mintable[0].token_id }}</p>
                                                    </div>

                                                </li>
                                                <li class="item-other-info">
                                                    <div class="item-info-title">
                                                        <h6>{{ $t('Country') }}</h6>
                                                    </div>
                                                    <div class="item-info-details">
                                                        <p>{{ mintable[0].country }}</p>
                                                    </div>
                                                </li>

                                                <li class="item-other-info">
                                                    <div class="item-info-title">
                                                        <h6>{{ $t('Comment') }}</h6>
                                                    </div>
                                                    <div class="item-info-details">
                                                        <p>{{ mintable[0].comment }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                            <div class="buying-btns d-flex flex-wrap">
                                <!-- <a href="wallet.html" class="default-btn move-right"><span>Mint Now</span> </a> -->
                                <!-- <a href="wallet.html" class="default-btn move-right"><span>Start Auction</span> </a>
                                <a href="wallet.html" class="default-btn move-right"><span>Transfer</span> </a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ==========Item Details Section ends Here========== -->
</div>
</template>

<script>
import '../assets/css/bootstrap.min.css';
import '../assets/css/icofont.min.css';
import '../assets/css/lightcase.css';
import '../assets/css/animate.css';
import '../assets/css/swiper-bundle.min.css';
import '../assets/css/style.min.css';
// import '../assets/css/icofont.min.css';
import '../assets/css/animate.css';
import axios from "axios";
// import '../assets/js/functions.js';

export default {
    props: ["lightMode"],
    data () {
        return {
            isLoading: true,
            isback: false,
            mintable: [],
            flipIcons: {
                white: require("../assets/images/flip2.png"),
                black: require("../assets/images/flip.png"),
            }
        }
    },
    async mounted () {
        document.body.style.backgroundImage = "none"
        const baseUrl = process.env.VUE_APP_API;
        console.log(this.$route.params.id)
        axios.get(`${baseUrl}/pmg/id/${this.$route.params.id}`).then(res => {
            this.mintable = res.data;
            console.log('this.mintable', this.mintable);
        })
        this.isLoading = false
    },
    methods: {
        closeFullPage () {
            const fullPage = document.querySelector('#fullpage');
            fullPage.style.display = 'none';
        },
        viewFullpage () {
            console.log(this.isback);
            const fullPage = document.querySelector('#fullpage');
            let img = ''
            if (this.isback) {
                img = document.querySelector('.flip-card-back img');
            } else {
                img = document.querySelector('.flip-card-front img');
            }

            fullPage.style.backgroundImage = 'url(' + img.src + ')';
            fullPage.style.display = 'block';
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        getFrontImage(imageName) {
            var images = require.context('../assets/', false, /\.jpg$/)
            return images('./images/' + imageName + "-front.jpg")
        },
        getRearImage(imageName) {
            var images = require.context('../assets/', false, /\.jpg$/)
            return images('./images/' + imageName + "-back.jpg")
        }
    }
}
</script>

<style>
ol, ul {
    padding: 0 !important;
}
#flip {
    appearance: none;
    display: block;
}
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card .flip-indication {
    position: absolute;
    top: -50px;
    z-index: 99;
    right: 0;
}

/* .flip-card:hover .flip-card-inner, */
#flip:checked + .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: rgb(35, 38, 39);
    border-radius: 12px;
}

.flip-card-back {
    background-color: rgb(35, 38, 39);
    border-radius: 12px;
    transform: rotateY(180deg);
}

.image-functions {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
}
#fullpage {
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: black;
}
.flip-indication {
    display: flex;
    gap: 10px;
    align-items: center;
}
.flip-indication {
    font-size: 12px;
}
.flip-indication img {
    width: 20px;
    height: 20px !important;
}
@media screen and (min-width: 1000px) {
    .flip-card {
        height: 500px;
    }
}
</style>