<template>
  <div class="contact">
    <div class="contact_title">{{$t("Contact Us")}}</div>
    <div class="contact_sub">{{$t("Need a hand? Here's how to reach us")}}</div>
    <div class="contact_content">
        <div class="left-content">
            <div class="contact_subtitle">{{$t("We Love To Hear Your Feedback")}}</div>
            <div class="desc">{{ $t("Please fill the form below for any enquiries, and we shall get back to you as soon as we can.") }}</div>
            <input v-model="email" :placeholder="$t('Your Email Address')" type="text">
            <input v-model="phone" :placeholder="$t('Phone Number')" type="text">
            <textarea v-model="msg" :placeholder="$t('Your Message Here')" cols="30" rows="10"></textarea>
            <button @click="submitContact" class="submit-btn">{{$t("Submit")}}</button>
        </div>
        <div class="right-content">
            <!-- <img src="../assets/images/googleMap.png" alt="pmg_contact_location"> -->
            <div class="contact_bottom-content">
                <div class="contact_card">
                    <img src="../assets/images/icons/phone.png" alt="pmg_contact_phone">
                    <div class="card-title">
                        {{$t("Customer Service")}}
                    </div>
                    <div class="card-desc">
                       <a href="https://wa.me/60172772390" target="_blank">+6017-2772 390</a> 
                    </div>
                </div>
                <div class="contact_card">
                    <img src="../assets/images/icons/email.png" alt="pmg_contact_phone">
                    <div class="card-title">
                        {{$t("Email")}}
                    </div>
                    <div class="card-desc">
                        info@numisart.io
                    </div>
                </div>
                <div class="contact_card">
                    <img src="../assets/images/icons/location.png" alt="pmg_contact_phone">
                    <div class="card-title">
                        {{$t("Location")}}
                    </div>
                    <div class="card-desc">
                        Trigometric Sdn Bhd <br/>
                        A-3A-01, Eko Cheras Office Tower.<br/>
                        No. 693, Batu 5, Taman Mutiara Barat,<br/>
                        56000 Kuala Lumpur.
                    </div>
                </div>
            </div>
            <img class="map" src="../assets/images/map2.png" alt="pmg_contact_location">
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            email: "",
            phone: "",
            msg: ""
        }
    },
    methods: {
        submitContact () {
            if (this.email != "" && this.phone != "" && this.msg != ""){
                this.$swal.fire({
                    title: 'Contact Submitted!',
                    icon: 'success'
                }).then(() => {
                    this.email = "";
                    this.phone = "";
                    this.msg = "";
                })

            }
        }
    }
}
</script>

<style>
.contact {
    width: 90%;
    margin: auto;
}
.lightmode .contact_title {
    color: var(--gold);
}
.lightmode .contact_subtitle {
    color: var(--dark);
}
.contact_title {
    font-size: 25px;
    color: var(--light);
    text-align: center;
    margin-top: 1rem;
}
.contact_sub {
    font-size: 16px;
    color: var(--default);
    text-align: center;
    margin-bottom: 10%;
}
.contact .desc {
    color: var(--default);
}

.contact_subtitle {
    font-size: 16px;
    color: var(--light);
    font-weight: 600;
}

.contact_bottom-content, .contact_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.contact .left-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
    margin-bottom: 20px;
}
.contact .left-content input,
.contact .left-content textarea {
    background: #F1F1F1;
    border: 1px solid transparent;
    padding: 20px;
    border-radius: 12px;
}
.contact .submit-btn {
    align-self: inherit;
    width: 100%;
    height: 40px;
    background: var(--gold);
    color: white;
    border: 1px solid transparent;
    border-radius: 10px;
}
.contact .contact_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    height: 300px;
    border-bottom: 4px solid var(--gold);
    border-radius: 15px;
    justify-content: center;
    background: var(--dark);
    margin-bottom: 50px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px; 
}
.contact .contact_card a {
    color: var(--default);
}
.contact .card-title {
    color: var(--gold);
    font-size: 20px;
    font-weight: 700;
}
.contact .contact_card img {
    height: auto;
    width: 70px;
    margin-bottom: 10px;
}
.contact .contact_card .card-desc {
    color: var(--default);
    text-align: center;
}
.lightmode .contact .contact_card {
    background: var(--light);
}
.contact .right-content {
    width: 100%;
}
.contact .right-content .map {
    width: 100%;
    border-radius: 12px;
}
@media only screen and (min-width: 768px) {
    .contact {
        width: 90%;
        margin: 0 auto 50px auto;
    }
    .contact_sub {
        font-size: 25px;
    }
    .contact .contact_card {
        /* width: 320px; */
    }
    .contact_bottom-content, .contact_content {
        flex-direction: row;
        justify-content: space-between;
    }
    .contact_content {
        gap: 50px;
    }
    .contact_title {
        font-size: 70px;
    }
    .contact_subtitle {
        font-size: 45px;
    }
    .contact .submit-btn {
        align-self: flex-end;
        width: 145px;
    }
}
</style>