<template>
    <div :class="lightMode == 'light' ? 'verify container mt-3 light' : 'verify container mt-3'">
        <div class="title text-center">{{$t('Verification')}}</div>
        <!-- <div class="cert_sub-title">{{ $t("Authentication checking for PMG Cert and NFT Cert") }}</div> -->
        <div class="cert_sub-title">{{ $t("Verify Your PMG & NFT Certification Here") }}</div>
        <div class="verify-container col-12 m-auto">
            <div class="verify-pmg-cert">
                <div class="verify-pmg_wrapper">
                    <div class="verify-pmg_title">{{ $t("NFT Verification") }}</div>
                    <div class="verify-pmg_input-wrapper">
                        <input v-model="certNo" type="text">
                            <!-- <button @click="connect" data-hover="click me!">连接</button> -->
                        <button @click.prevent.stop="checkNFT" data-hover="»"><div>{{ $t("Verify") }}</div></button>
                    </div>
                    <p id="searchMsg" class="msg">{{ $t(searchMsg) }}</p>
                </div>
            </div>
            <div class="verify-item_wrapper">
                <div class="verify-item col-12 col-lg-6">
                    <img src="../assets/images/certificate/population.png" alt="numisart-population-logo">
                    <p>{{$t('PMG Population Report')}}</p>
                    <a href="https://www.pmgnotes.com/population-report/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                        </svg>
                        <b>{{$t('Get Started To Verify')}}</b>
                    </a>
                </div>
                <div class="verify-item col-12 col-lg-6">
                    <img src="../assets/images/certificate/certificate.png" alt="numisart-population-logo">
                    <p>{{$t('Check Certificate Number')}}</p>
                    <a href="https://www.pmgnotes.com/certlookup/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                        </svg>
                        <b>{{$t('Get Started To Verify')}}</b>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ethers } from "ethers";
import axios from "axios";
const NFT_ABI = require("@/abi/nftContractAbi.json");

export default {
    props: [
        "lightMode",
        "userAddress",
        "fullAddress",
        "provider"
    ],
    data() {
        return {
            isMinted: null,
            certNo: null,
            searchMsg: this.$t('Enter PMG Certificate to check if NFT has been minted')
        }
    },
    mounted() {
        document.body.style.backgroundImage = "none"
    },
    methods: {
        async checkNFT () {
            // console.log('clicked');

            const baseUrl = process.env.VUE_APP_API
            let dbRecord = await axios.get(`${baseUrl}/pmg/pmg_cert?pmg_cert=${this.certNo}`)

            let certRegex = /\d[-]\d/
            if (!(this.certNo.match(certRegex))) {
                this.searchMsg = this.$t('Enter the correct cert format');
                document.getElementById("searchMsg").style.color = "var(--gold)";
                return
            } 

            let pmgContract = process.env.VUE_APP_NFT_ADDRESS
            // let provider = new ethers.providers.Web3Provider(window.ethereum);
            let provider = this.provider;
            const contract = new ethers.Contract(pmgContract, NFT_ABI, provider);
            this.isMinted = await contract.cert(this.certNo)
            console.log('dbRecord: ', dbRecord);
            if (dbRecord.data.length > 0 && dbRecord.data[0].result.toLowerCase() == 'pending') {
                this.searchMsg = 'This NFT is processing'
                document.getElementById("searchMsg").style.color = "var(--gold)";
            } else if (parseInt(this.isMinted.toString()) > 0) {
                this.searchMsg = 'This NFT has been minted'
                document.getElementById("searchMsg").style.color = "var(--gold)";
            } else {
                this.searchMsg = this.$t('This NFT has not been minted');
                document.getElementById("searchMsg").style.color = "var(--gold)";
            }
        }
    }
}
</script>

<style scoped>
    .verify .title{
        color: var(--gold);
        font-size: 25px;
        /* font-size: auto; */
    }

    .verify .cert_sub-title {
        color: var(--default);
        font-size: auto;
        margin-bottom: 30px;
        text-align: center;
    }

    .verify-container{
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        /* background-color: #1C1922; */
            /* justify-content: center;; */
        /* height:  calc(100vh - 20vh); */
        box-shadow: #00000059 0px 5px 15px; 
        border-radius: 20px;
        padding: 10px;
    }

    .verify-container .verify-item_wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .light .verify-container{
        background-color: var(--light);
    }

    .verify-container .verify-item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .verify-container .verify-item img{
        width: 100px;
        height: auto;
    }

    .verify-pmg_title,
    .verify-container .verify-item p{
        font-size: 25px;
        font-weight: bold;
        margin:20px 0;
        /* color: white; */
    }

    .verify-pmg_wrapper .verify-pmg_input-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .verify-container .verify-item a{
        background-color: #FFA135;
        color: black;
        border: none;
        border-radius: 50px;
        padding: 10px 30px;
        font-size: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: .5s;
        text-decoration: unset;
        border: 1px solid transparent;
    }

    .verify-container .verify-item a:hover{
        color: black;
        background: white;
        border: 1px solid var(--gold);
    }

    .verify-container .verify-item a b{
        margin-left: 5px;
    }
    .verify-container .verify-pmg_wrapper {
        margin: 20px auto;
        /* width: -moz-max-content; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .verify-container .verify-pmg_title {
        text-align: center;
        border: 1px solid var(--gold);
        width: max-content;
        padding: 10px 20px;
        border-radius: 12px;
        box-shadow: #e3af72 0px 0px 10px;
    }
    .verify-pmg_wrapper button {
        background-color: transparent;
        border: 1px solid var(--gold);
        border-radius: 30px;
        color: var(--gold);
        padding: 6px 10px;
        margin-left: 6px;
        transition: 0.3s;
        overflow: hidden;
        position: relative;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .lightmode .verify-pmg_wrapper button {
        color: var(--dark);
    }
    /*button:before (attr data-hover)*/
    .verify-pmg_wrapper button:hover:before{opacity: 1; transform: translate(0,0);}
    .verify-pmg_wrapper button:before{
        content: attr(data-hover);
        position: absolute;
        left: 0;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 800;
        font-size: .8em;
        opacity: 0;
        transform: translate(-100%,0);
        transition: all .3s ease-in-out;
    }
    /*button div (button text before hover)*/
    .verify-pmg_wrapper button:hover div{
        opacity: 0;
        transform: translate(100%,0);
    }
    .verify-pmg_wrapper button div{
        text-transform: uppercase;
        font-weight: 800;
        /* font-size: .8em; */
        transition: all .3s ease-in-out;
    }

    .verify-pmg_wrapper button:active,
    .verify-pmg_wrapper button:focus {
        background-color: var(--gold);
        color: white;
    }
    .verify-pmg_wrapper .msg {
        text-align: center;
        font-size: 18px;
        color: var(--default);
    }
    @media only screen and (min-width: 1200px)  {
        .verify .title{
            font-size: 70px;
        }
        .verify .cert_sub-title {
            color: var(--default);
            font-size: 25px;
            text-align: center;
        }
        .verify-pmg_wrapper .verify-pmg_input-wrapper {
            flex-direction: row;
        }
    }

    
</style>
