import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueInputAutowidth from 'vue-input-autowidth'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'tippy.js/dist/tippy.css';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import 'vue-select/dist/vue-select.css';  
import 'v-skeletor/dist/v-skeletor.css';
import VueSkeletor from 'v-skeletor';
import vSelect from 'vue-select';
// import VueTippy, { TippyComponent } from "vue-tippy";
import VueApexCharts from 'vue-apexcharts';

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// Vue.use(VueTippy);
Vue.component('v-select', vSelect)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
// Vue.use(axios)
Vue.use(VueSweetalert2)
Vue.use(VueInputAutowidth)
Vue.use(VuePhoneNumberInput)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.use(VueSkeletor)
// Vue.component("tippy", TippyComponent);

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
