<template>
<div class="mystery mt-3">
    <!-- <div id="coralBG" class="bg-coral">
        <img :src="coral ? lightCoral : darkCoral" alt="pmg_mystery_coral">
    </div> -->
    <div class="mystery-container">
          <div class="headline-title">
              {{$t("Mystery Egg")}}
          </div>
          <div class="headline-subtitle">
              {{$t("Numisart First Physical NFT collection")}} <br />
          </div>
          <div class="mystery-preview">
              <div>
                  <img src="../assets/images/Minting page_egg.png" alt="pmg_mystery_egg">
              </div>
          </div>
          <div class="mystery-content">
              <!-- <div class="title">
                  {{$t("Mystery NFT")}}
              </div> -->
              <div class="desc">
                  <!-- {{$t("Mystery NFT")}} -->
                  {{$t("This test note is a combination of physical note and NFT as a collection.")}}  <br/>
                  {{$t("You can redeem the actual test note at the counter in the Numismatic Fair start from September 11 to 13, 2022.")}}
              </div>

              <!-- <div class="mystery_input-wrapper w-100">
                    <b-dropdown class="w-100">
                        <template #button-content>
                            <img class="me-2" :src="mintSupportCoins[selectedCoin].img" alt="nmc-coin-logo">
                            <span>{{mintSupportCoins[selectedCoin].symbol}}</span>
                        </template>

                        <b-dropdown-item href="#" v-for="(coin, index) in mintSupportCoins" :key="`coin ${coin.id}`" @click="selectedCoin = index">
                            <img class="me-2" :src="coin.img" alt="nmc-coin-logo">
                            <span>{{coin.symbol}}</span>
                        </b-dropdown-item>
                    </b-dropdown> 
              </div> -->

              <label class="mt-3">{{$t("Quantity")}}</label>
              <div class="mystery_input-wrapper mt-0 mb-3">
                  <button @click="decreaseAmount">-</button>
                  <span>{{amount}}</span>
                  <!-- <input type="number" v-model="amount" :max="maxMint" min="0"> -->
                  <button @click="increaseAmount">+</button>
              </div>

              <div>
                <p>{{$t("Cost")}} : {{cost}} {{mintSupportCoins[selectedCoin].symbol}}</p>
              </div>

              <!-- <p class="mb-0 mt-5">{{$t("Available:")}} {{availableNFT}} </p> -->
              <button @click="mintMystery" :class="`mystery-btn ${!mintable?'btn-disabled':''}`">
                  {{$t("Mint")}}
              </button>
          </div>

        <div v-if="nfts.length > 0" class="mystery-collection">
                <div class="sub-title">
                    {{$t("Your Egg Collection")}}
                </div>
              <div v-if="nfts.length > 0" class="collection-container">
                    <div v-for="(nft, key) in nfts" class="mystery-img" :key="nft.turtle_id">
                        <div v-if="nft.turtle_status == 'egg'">
                            <div class="img-area">
                                <div class="thumbnail">
                                    <img :src="nft.thumbnail_picture" alt="thumbnail">
                                </div>
                                <button class="switch-btn" @click="changeSrc($event, key)">
                                    <i class="bi bi-play-fill"></i>
                                </button>
                            </div>
                            <!-- <img :src="nft.thumbnail" alt=""> -->
                        </div>
                        <div v-else-if="nft.turtle_status == 'pre_reveal' " @click="revealNFT(nft.turtle_id, key)">
                            <img :src="nft.thumbnail_picture" alt="">
                            <div class="overlay">
                                <span class="reveal-text">{{$t("Click to reveal")}}</span>
                            </div>
                            <div class="serial-number">
                                <div class="doors d-flex">
                                    <div class="door">
                                        <div class="boxes">
                                        </div>
                                    </div>
                                
                                    <div class="door">
                                        <div class="boxes">
                                        </div>
                                    </div>
                                
                                    <div class="door">
                                        <div class="boxes">
                                        </div>
                                    </div>

                                    <div class="door">
                                        <div class="boxes">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <img :src="nft.thumbnail_picture" alt="nmc-turtle-img">
                            <div class="serial-number d-block">
                                <div class="doors d-flex">
                                    <div class="door">
                                        <div class="boxes">
                                            <div class="box">{{nft.serial_number[0]}}</div>
                                        </div>
                                    </div>
                                
                                    <div class="door">
                                        <div class="boxes">
                                            <div class="box">{{nft.serial_number[1]}}</div>
                                        </div>
                                    </div>
                                
                                    <div class="door">
                                        <div class="boxes">
                                            <div class="box">{{nft.serial_number[2]}}</div>
                                        </div>
                                    </div>

                                    <div class="door">
                                        <div class="boxes">
                                            <div class="box">{{nft.serial_number[3]}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                   
                    </div>
              </div>
              
              
              <div class="mystery-content mt-3" v-if="unRevealID.length == 0 && unClaimNFT != ''">
                    <button
                        class="mystery-btn"
                        @click="openClaimModal"
                    >
                        <span>{{ $t("Claim") }}</span>
                    </button>
              </div>
        </div>

          <div class="mystery-collection">
              <div class="sub-title">
                  <!-- {{$t("Your Egg Collection")}} -->
                  {{$t("Hatching Timeline")}}
              </div>

              <!-- demo -->
              <div class="collection-container">
                <div class="mystery-img">
                    <div>
                        <!-- <video loop autoplay muted alt="pmg_mystery_img" poster="../assets/images/playBtn.png"> -->
                        <!-- <video loop autoplay muted alt="pmg_mystery_img">
                            <source src="https://numisartgallery.sgp1.digitaloceanspaces.com/Turtle/Phase01.mp4" type="video/mp4">
                        </video> -->
                        <div class="img-area-demo">
                            <div class="thumbnail">
                                <img src="../assets/images/turtle/pic/phase01.png" alt="thumbnail">
                            </div>
                            <button class="switch-btn" @click="changeDemoSrc($event, 0)">
                                <i class="bi bi-play-fill"></i>
                            </button>
                        </div>
                        <div>{{$t("18-August to 25-August")}}</div>
                    </div>
                </div>
                <div class="mystery-img">
                    <div>
                        <!-- <video loop autoplay muted alt="pmg_mystery_img">
                            <source src="https://numisartgallery.sgp1.digitaloceanspaces.com/Turtle/Phase02.mp4" type="video/mp4">
                        </video> -->
                        <div class="img-area-demo">
                            <div class="thumbnail">
                                <img src="../assets/images/turtle/pic/phase02.png" alt="thumbnail">
                            </div>
                            <button class="switch-btn" @click="changeDemoSrc($event, 1)">
                                <i class="bi bi-play-fill"></i>
                            </button>
                        </div>
                        <div>{{$t("26-August to 2-September")}}</div>
                    </div>
                </div>
                <div class="mystery-img">
                    <div>
                        <!-- <video loop autoplay muted alt="pmg_mystery_img">
                            <source src="https://numisartgallery.sgp1.digitaloceanspaces.com/Turtle/Phase03.mp4" type="video/mp4">
                        </video> -->
                        <div class="img-area-demo">
                            <div class="thumbnail">
                                <img src="../assets/images/turtle/pic/phase03.png" alt="thumbnail">
                            </div>
                            <button class="switch-btn" @click="changeDemoSrc($event, 2)">
                                <i class="bi bi-play-fill"></i>
                            </button>
                        </div>
                        <div>{{$t("3-September to 10-September")}}</div>
                    </div>
                </div>
                <div class="mystery-img">
                    <div @click="revealNFT(1, nfts.length + 3, 1)">
                        <img src="https://numisartgallery.sgp1.digitaloceanspaces.com/Turtle/Phase04.png" alt="">
                        <div class="overlay">
                            <span class="reveal-text">{{$t("Click to reveal")}}</span>
                        </div>
                        <div class="serial-number">
                            <div class="doors d-flex">
                                <div class="door">
                                    <div class="boxes">
                                    </div>
                                </div>
                            
                                <div class="door">
                                    <div class="boxes">
                                    </div>
                                </div>
                            
                                <div class="door">
                                    <div class="boxes">
                                    </div>
                                </div>

                                <div class="door">
                                    <div class="boxes">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 10px">11-September {{$t("onwards")}}</div>
                </div>
              </div>
              <!-- demo -->
          </div>

          <div class="ar-collection">
            <div class="sub-title">
                <!-- {{$t("Your Egg Collection")}} -->
                {{$t("Augmented Reality (AR)")}}
            </div>

            <div class="collection-container">
                <div>
                    <div>
                        <!-- <video loop autoplay muted alt="pmg_mystery_img">
                            <source src="../assets/images/arLogo.mp4" type="video/mp4">
                        </video> -->
                        <!-- <img src="../assets/images/logoNote.gif" alt=""> -->
                        <div class="img-area-demo">
                            <div class="thumbnail">
                                <img src="../assets/images/turtle/pic/logoNote.png" alt="thumbnail">
                            </div>
                            <button class="switch-btn" @click="changeDemoSrc($event, 3, 'logoNote')">
                                <i class="bi bi-play-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <!-- <video loop autoplay muted alt="pmg_mystery_img">
                            <source src="../assets/images/arTurtle.mp4" type="video/mp4">
                        </video> -->
                        <!-- <img src="../assets/images/turtleNote.gif" alt=""> -->
                        <div class="img-area-demo">
                            <div class="thumbnail">
                                <img src="../assets/images/turtle/pic/turtleNote.png" alt="thumbnail">
                            </div>
                            <button class="switch-btn" @click="changeDemoSrc($event, 4, 'turtleNote')">
                                <i class="bi bi-play-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {{$t("Scan your physical banknotes with NumisArt App")}}
            </div>

            <div class="downloads">
                <a href=" https://apps.apple.com/my/app/numisart-ar/id1639903878" target="_blank">
                    <img src="../assets/images/app-store.png" alt="numisart-appstore-app">
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.Numisart.NumisartAR" target="_blank">
                    <img src="../assets/images/google-play.png" alt="numisart-googleplay-app">
                </a>
            </div>
          </div>

        <!-- claim modal start -->
        <b-modal
            :ok-title="$t('Confirm')"
            :cancel-title="$t('Cancel')"
            cancel-variant="danger"
            id="claimModal"
            ref="claimModal"
            :title="$t('Claim')"
            centered hide-footer
            >
            <div class="text-center">
                <vue-qrcode
                    :value="currentUserAddress"
                    :options="{
                    errorCorrectionLevel: 'M',
                    width: 200,
                    }"
                ></vue-qrcode>
            </div>
        </b-modal>
        <!-- claim modal end -->

        <!-- reveal all modal start -->
        <b-modal
            @ok="revealAllNFT"
            :ok-title="$t('Confirm')"
            :cancel-title="$t('Cancel')"
            cancel-variant="danger"
            ref="revealAllModal"
            id="revealAllModal"
            :title="$t('Claim')"
            >
            <div class="text-center">
                <p>{{$t('Are you sure want to reveal all NFT ?')}}</p>
            </div>
        </b-modal>
        <!-- reveal all modal end -->
    </div>
</div>
</template>

<script>
import { ethers } from "ethers";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import axios from "axios";
import tokenAbi from "../../abi/token.json";
// import SlotMachine from 'vue-slot-machine-gen';


const config = {
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem("access_token"),
  },
}

const MYSTERY_ABI = require("@/abi/mysteryMintAbi.json");
export default {
    props: [
        "fullAddress", "lightMode", "provider", "connectStatus"
    ],
    components: { VueQrcode },
    data(){
        return{
            isClicked: false,
            // arr: [
            //     {
            //         thumbnail: require('../assets/images/mint/Phase01.png'),
            //         real_image_front: require('../assets/images/mint/Phase01.mp4')
            //     },
            //     {
            //         thumbnail: require('../assets/images/mint/Phase02.png'),
            //         real_image_front: require('../assets/images/mint/Phase02.mp4')
            //     },
            //     {
            //         thumbnail: require('../assets/images/mint/Phase03.png'),
            //         real_image_front: require('../assets/images/mint/Phase03.mp4')
            //     }
            // ],
            mysteryAddr: process.env.VUE_APP_MYSTERY_ADDRESS,
            amount: 0,
            maxMint: null,
            amountMinted: null,
            whitelisted: null,
            mintable: true,
            lightBG: require('../assets/images/mint/lightNew.png'),
            darkBG: require('../assets/images/mint/darkNew.png'),
            claimNFTs:[],
            nfts:[],
            unRevealID:[],
            unClaimNFT:'',
            currentUserAddress: localStorage.getItem("address"),
            availableNFT: 0,
            coral: true,
            lightCoral: require('../assets/images/mint/lightCoral.png'),
            darkCoral: require('../assets/images/mint/darkCoral.png'),
            currentScroll: 0,
            demo: [
                {
                    'img':require('../assets/images/turtle/pic/phase01.png'),
                    'gif':require('../assets/images/turtle/gif/phase01.gif')
                },
                {
                    'img':require('../assets/images/turtle/pic/phase02.png'),
                    'gif':require('../assets/images/turtle/gif/phase02.gif')
                },
                {
                    'img':require('../assets/images/turtle/pic/phase03.png'),
                    'gif':require('../assets/images/turtle/gif/phase03.gif')
                },
                {
                    'img':require('../assets/images/turtle/pic/logoNote.png'),
                    'gif':require('../assets/images/turtle/gif/logoNote.gif')
                },
                {
                    'img':require('../assets/images/turtle/pic/turtleNote.png'),
                    'gif':require('../assets/images/turtle/gif/turtleNote.gif')
                },
            ],
            cost: 0,
            price: 0,
            selectedCoin:0,
            mintSupportCoins:[
                {
                    'id':1,
                    'address':'0x55d398326f99059fF775485246999027B3197955',
                    'img':"https://numisartgallery.sgp1.digitaloceanspaces.com/Coin/USDT.png",
                    'symbol':'USDT'
                },
                {
                    'id':2,
                    'address':"0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
                    'img':"https://numisartgallery.sgp1.digitaloceanspaces.com/Coin/USDC.png",
                    'symbol':'USDC'
                },
                {
                    'id':3,
                    'address':'0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
                    'img':"https://numisartgallery.sgp1.digitaloceanspaces.com/Coin/BUSD.png",
                    'symbol':'BUSD'
                },
            ],
        }
    },
    computed:{
        mintSupportCoinsFilter(){
            return this.mintSupportCoins.filter((item,index)=>{
                return index != this.selectedCoin
            });
        },
    },
    created() {
        // window.addEventListener("scroll", this.displayCoral);
    },
    // destroyed() {
    //     window.removeEventListener("scroll", this.displayCoral);
    // },
    async mounted () {   
        console.log(ethers.utils.parseEther("0.001"));
        if (this.lightMode == 'light') {
            document.body.style.backgroundImage = `url(${this.lightBG})`;
            this.coral = true;
        } else {
            document.body.style.backgroundImage = `url(${this.darkBG})`;
            this.coral = false;
        }
        
        // const baseUrl = process.env.VUE_APP_API;
        // try{
        //     axios.get(`${baseUrl}/mystery-box-one/unclaimed_turtle/?pageSize=10&page=1&owner_address=${address}&is_hidden=0`).then(res => {
        //         this.arr = res.data.results
        //     })
        
        // } catch(error){
        //     console.log('/mystery-box-one/unclaimed_turtle api error',error);
        // }
        await this.getMintedInit();
        await this.fetchUnclaimUnrevealNFT();
    },
    watch: {
        amountMinted () {
            this.maxMint
        },
        lightMode(){
            document.body.style.backgroundColor = "none";
            if (this.lightMode == 'light') {
                document.body.style.backgroundImage = `url(${this.lightBG})`;
                document.body.style.backgroundSize = "cover";
                document.body.style.backgroundPosition = "0px -500px";
                document.body.style.backgroundRepeat = "no-repeat";
                this.coral = true;
            } else {
                document.body.style.backgroundImage = `url(${this.darkBG})`;
                document.body.style.backgroundSize = "cover";
                document.body.style.backgroundPosition = "0px -500px";
                document.body.style.backgroundRepeat = "no-repeat";
                this.coral = false;
            }
        }
    },
    methods: {
        showConnectWalletModal(){
            this.$bvModal.show('homeConnectWalletModal');	
        },
        async getMintedInit () {
            let address = localStorage.getItem('address')
            // let provider = new ethers.providers.Web3Provider(window.ethereum);
            let provider = this.provider;
            const contract = new ethers.Contract(this.mysteryAddr, MYSTERY_ABI, provider)
            // contract.numberMinted(address).then(res => {
            //     this.amountMinted = parseInt(res.toString())
            // })
            
            let nftPrice = await contract.minting_fee();
            // console.log(nftPrice);
            this.price = ethers.utils.formatEther(nftPrice.toString());

            let amountMinted =  await contract.numberMinted(address);
            this.amountMinted = parseInt(amountMinted.toString());

            // this.whitelisted = await contract.whitelist(address);
            // // console.log('this.amountMinted: ', this.amountMinted.toString());
            // // console.log('this.whitelisted: ', this.whitelisted);
            // if (!this.whitelisted && this.amountMinted >= 10) {
            //     this.mintable = false
            // }

            let owner = await contract.owner();
            let isOwner = address && address.toUpperCase == owner.toUpperCase() ? true : false;

            if (!isOwner && this.amountMinted >= 20) {
                this.mintable = false
            }

            this.maxMint = 20 - this.amountMinted

            let totalSupply = await contract.MAX_SUPPLY();
            let numberMinted = await contract.totalSupply();

            // console.log(totalSupply.toString(), numberMinted.toString())
            this.availableNFT = totalSupply - numberMinted;

            // if(isOwner){
            //     this.maxMint = this.availableNFT;
            // }

            // if (this.whitelisted == true) {
            //     this.maxMint = 5 - this.amountMinted
            // } else {
            //     this.maxMint = 2 - this.amountMinted
            // }
        },
        displayCoral(){
            this.currentScroll = window.scrollY;
            document.getElementById("coralBG").style.transform = `translate(-${this.currentScroll/2}px, 0px)`
        },
        showNumber(){
            let numbers = document.querySelector('.serial-number');

            numbers.classList.add('animated');
        },
        changeSrc(element,index){
            
            let switchBtnSelectors = document.querySelectorAll('.img-area .switch-btn');
            let thumbnailSelectors = document.querySelectorAll('.img-area .thumbnail');

            // check current element is video or img
            let currentElementClass = thumbnailSelectors[index].className;
            if(currentElementClass == 'thumbnail is_play'){
                let img = this.nfts[index].thumbnail_picture;

                thumbnailSelectors[index].classList.remove('is_play');
                thumbnailSelectors[index].innerHTML = `<img src="${img}" alt="thumbnail">`;
                switchBtnSelectors[index].innerHTML = `<i class="bi bi-play-fill"></i>`;

                element.preventDefault();
                return;
            }

            // set target to pause
            // set others to play
            switchBtnSelectors = [...switchBtnSelectors];

            switchBtnSelectors[index].innerHTML = `<i class="bi bi-pause-fill"></i>`;

            switchBtnSelectors.splice(index,1);

            switchBtnSelectors.forEach((item)=>{
                item.innerHTML = `<i class="bi bi-play-fill"></i>`
            });


            // change target from img to video
            // change others from video to img
            thumbnailSelectors = [...thumbnailSelectors]

            // let thumbnailVideo = "https://numisartgallery.sgp1.digitaloceanspaces.com/Turtle/Phase01.mp4";
            // thumbnailSelectors[index].innerHTML = `<video autoplay loop muted playsinline>
            //                                             <source src="${thumbnailVideo}" type="video/mp4">
            //                                         </video>`;

            let thumbnailVideo = this.nfts[index].thumbnail;

            thumbnailSelectors[index].innerHTML = `<img src="${thumbnailVideo}">`;

            thumbnailSelectors[index].classList.add('is_play');
            
            thumbnailSelectors.forEach((item,i)=>{
                if(i == index){
                    return;
                }
                
                let img = require('../assets/images/turtle/pic/phase01.png');

                item.innerHTML = `<img src="${img}" alt="thumbnail">`;
                item.classList.remove('is_play');
            }); 
             
            element.preventDefault();
            
            return false;
        },

        changeDemoSrc(element,index ){
            
            let switchBtnSelectors = document.querySelectorAll('.img-area-demo .switch-btn');
            let thumbnailSelectors = document.querySelectorAll('.img-area-demo .thumbnail');

            // check current element is video or img
            let currentElementClass = thumbnailSelectors[index].className;
            if(currentElementClass == 'thumbnail is_play'){
                let img = this.demo[index].img;

                thumbnailSelectors[index].classList.remove('is_play');
                thumbnailSelectors[index].innerHTML = `<img src="${img}" alt="thumbnail">`;
                switchBtnSelectors[index].innerHTML = `<i class="bi bi-play-fill"></i>`;

                element.preventDefault();
                return;
            }

            // set target to pause
            // set others to play
            switchBtnSelectors = [...switchBtnSelectors];

            switchBtnSelectors[index].innerHTML = `<i class="bi bi-pause-fill"></i>`;

            switchBtnSelectors.splice(index,1);

            switchBtnSelectors.forEach((item)=>{
                item.innerHTML = `<i class="bi bi-play-fill"></i>`
            });


            // change target from img to video
            // change others from video to img
            thumbnailSelectors = [...thumbnailSelectors]

            let thumbnailVideo = this.demo[index].gif;

            thumbnailSelectors[index].innerHTML = `<img src="${thumbnailVideo}">`;

            thumbnailSelectors[index].classList.add('is_play');
            
            thumbnailSelectors.forEach((item,i)=>{
                if(i == index){
                    return;
                }
                
                let img = this.demo[i].img ;

                item.innerHTML = `<img src="${img}" alt="thumbnail">`;
                item.classList.remove('is_play');
            }); 
             
            element.preventDefault();
            
            return false;
        },


        async mintMystery () {
            if(!this.connectStatus){
                this.showConnectWalletModal();
                return;
            }

            if(!this.mintable){
                this.$swal.fire({
                    title: this.$t("Error"),
                    text: this.$t("Exceed maximum limit"),
                    allowOutsideClick: false,
                    icon: "error",
                });
                return;
            }

            if(this.amount <= 0){
                this.$swal.fire({
                    title: this.$t("Error"),
                    text: this.$t("Mint amount cannot be 0 or less than 0"),
                    allowOutsideClick: false,
                    icon: "error",
                });
                return;
            }
            // let provider = new ethers.providers.Web3Provider(window.ethereum);
            let provider = this.provider;
            let signer = provider.getSigner();
            console.log(await signer.getAddress());
            const contract = new ethers.Contract(this.mysteryAddr, MYSTERY_ABI, signer);

            // const userAddress = await signer.getAddress();
            // const tokenContract = new ethers.Contract(this.mintSupportCoins[this.selectedCoin].address, tokenAbi, signer);
            // const balance = await tokenContract.balanceOf(userAddress);
            // const formatedBalance = ethers.utils.formatEther(balance);
            // if(formatedBalance < this.cost){
            //     this.$swal(this.$t('Error'),this.$t('Infsufficient funds'),'error');
            //     return false;
            // }

            const allowance = await this.getAllowance(signer, this.mintSupportCoins[this.selectedCoin].address);
            if (!allowance) {
                return false;
            }

            const connectedContract = contract.connect(signer)
            // if (this.whitelisted && this.amountMinted == 0) {
            //     this.maxMint = 5
            // }

            this.$swal.fire({
                title: this.$t("Minting"),
                allowOutsideClick: false,
                icon: "info",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            
            try{
                const mintAction = await connectedContract.mint(this.amount, this.mintSupportCoins[this.selectedCoin].id);

                let txReceipt = await provider.waitForTransaction(mintAction.hash);
                if (!txReceipt) {
                    this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
                    return false;
                }

                this.$swal.fire({
                    title: this.$t("Success"),
                    icon: 'success',
                    confirmButtonColor: "#ffa409",
                    confirmButtonText: this.$t("Close"),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            }catch(error){
                console.error(error);
                this.$swal.fire({
                    title: this.$t("Process incomplete"),
                    icon: 'error',
                    onfirmButtonText: 'OK'

                });
            }
        },

        increaseAmount(){
            console.log(this.maxMint);
            if(this.amount == this.maxMint){
                return;
            }
            this.amount ++;
            this.cost = this.amount * this.price;
        },

        decreaseAmount(){
            if(this.amount == 0){
                return;
            }

            this.amount --;
            this.cost = this.amount * this.price;
        },

        openClaimModal(){
            this.$bvModal.show('claimModal');
        },           

        async fetchUnclaimUnrevealNFT(){
            const baseUrl = process.env.VUE_APP_API;
            try {
                let nfts = await axios.get(
                `${baseUrl}/mystery-box-one/unclaimed_turtle?pageSize=20&page=1&owner_address=${this.currentUserAddress}&is_hidden=0`
                );

                this.nfts = nfts.data.results.map((item)=>{
                    if(item['serial_number']){
                        item['serial_number'] = item['serial_number'].padStart(4,'0');
                    }

                    return item;
                });

                let unRevealID = [];
                let unClaimNFT = [];

                this.unRevealID = [];
                this.unClaimNFT = [];

                for(let i=0; i<nfts.data.results.length; i++){
                    if(nfts.data.results[i].turtle_status == 'turtle'){
                        unClaimNFT.push({
                            current_owner : `${nfts.data.results[i].current_owner.substr(0,4)}...${nfts.data.results[i].current_owner.substr(-4,4)}`,
                            turtle_id: nfts.data.results[i].turtle_id,
                            serial_number: nfts.data.results[i].serial_number
                        })
                    }else if(nfts.data.results[i].turtle_status == 'pre_reveal'){
                        unRevealID.push(nfts.data.results[i].turtle_id);
                    }
                }

                this.unRevealID = unRevealID;
                this.unClaimNFT = unClaimNFT.length > 0 ? JSON.stringify(unClaimNFT) : '';

            } catch (error) {
                console.log(error);
            }
        },

        async revealNFT(turtleId, index, fixedNumber = 0){

            try {
                let result = '102';

                if(!fixedNumber){
                    const baseUrl = process.env.VUE_APP_API;
                    const body = {
                        "turtle_id":turtleId
                    };
                    const response = await axios.post(
                        `${baseUrl}/mystery-box-one/update/claim_status`,
                        body,
                        config
                    );

                    result = response.data.serial_num;
                }
                let mysteyImg = document.querySelectorAll('.mystery-img');
                console.log(index);

                mysteyImg[index].querySelector('.overlay').style.display = 'none';
                mysteyImg[index].querySelector('.serial-number').style.display = 'block';

                // document.querySelector(`.mystery-img:nth-child(${index + 1}) .overlay`).style.display = 'none';
                // document.querySelector(`.mystery-img:nth-child(${index + 1}) .serial-number`).style.display = 'block';

                this.spinSlotMachine(result.toString().padStart(4,'0'), index);
                // this.spinSlotMachine(('888').padStart(4,'0'), index);

            } catch (error) {
                console.log(error);
            }
        },

        initSlotMachine(firstInit = true, duration = 1, result = '1', index = 0){
            
            const items = [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
            ];
            

            // let alldoors = document.querySelectorAll('.doors');
            let mysteyImg = document.querySelectorAll('.mystery-img');


            let alldoors = mysteyImg[index].querySelector(`.serial-number .doors`);
            let doors = alldoors.querySelectorAll('.door')

            // if(firstInit){
            //     doors = document.querySelectorAll('.door');
            // }


            console.log(doors);
            for (const [key,door] of doors.entries()) {

                if (firstInit) {
                    door.dataset.spinned = '0';
                } else if (door.dataset.spinned === '1') {
                    return;
                }

                const boxes = door.querySelector('.boxes');
                const boxesClone = boxes.cloneNode(false);

                const pool = ['0'];

                if (!firstInit) {

                    const arr = [];
                    arr.push(...items);
                    arr.push(result[key]);
                    pool.push(...arr);

                    
                    boxesClone.addEventListener(
                    'transitionstart',
                    function () {
                        door.dataset.spinned = '1';
                        this.querySelectorAll('.box').forEach((box) => {
                        box.style.filter = 'blur(1px)';
                        });
                    },
                    { once: true }
                    );

                    boxesClone.addEventListener(
                    'transitionend',
                    function () {
                        this.querySelectorAll('.box').forEach((box, index) => {
                            box.style.filter = 'blur(0)';
                            if (index > 0) this.removeChild(box);
                        });
                    },
                    { once: true }
                    );
                }
                
                for (let i = pool.length - 1; i >= 0; i--) {
                    const box = document.createElement('div');
                    box.classList.add('box');
                    box.style.width = door.clientWidth + 'px';
                    box.style.height = door.clientHeight + 'px';
                    box.textContent = pool[i];
                    // console.log(pool[i]);
                    boxesClone.appendChild(box);
                }

                boxesClone.style.transitionDuration = `${duration > 0 ? duration : 1}s`;
                boxesClone.style.transform = `translateY(-${door.clientHeight * (pool.length - 1)}px)`;
                door.replaceChild(boxesClone, boxes);

                // break;
            }
        },

        async spinSlotMachine(result, index){

            this.initSlotMachine(false, 1, result, index);
            
            let mysteyImg = document.querySelectorAll('.mystery-img');

            let alldoors = mysteyImg[index].querySelector(`.serial-number .doors`);
            let doors = alldoors.querySelectorAll('.door')
            for (const door of doors) {
                const boxes = door.querySelector('.boxes');
                const duration = parseInt(boxes.style.transitionDuration);
                boxes.style.transform = 'translateY(0)';
                await new Promise((resolve) => setTimeout(resolve, duration * 1000));
            }
            this.fetchUnclaimUnrevealNFT();
        },

        async revealAllNFT(){
            const baseUrl = process.env.VUE_APP_API;
            const body = {
                "turtle_id":[
                    this.unRevealID
                ]
            }
            try {
                await axios.post(
                    `${baseUrl}/mystery-box-one/update/claim_status`,
                    body,
                    config
                );

                this.$swal
                    .fire({
                        title: this.$t("Success"),
                        text: this.$t("Reveal Success"),
                        icon: 'success',
                        confirmButtonColor: "#ffa409",
                        confirmButtonText: this.$t("Close"),
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                          this.fetchUnclaimUnrevealNFT();
                        }
                    });
                
            } catch (error) {
                console.log(error);
            }
        },

        async getAllowance(signer, tokenAddress) {
            const userAddress = await signer.getAddress();

            const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
            const allowance = await tokenContract.allowance(
                userAddress,
                this.mysteryAddr
            );
            const formatedAllowance = ethers.utils.formatEther(allowance);
            // console.log(formatedAllowance);
            if (formatedAllowance == 0) {
                // update user allowance to max
                const approveAllowance = await tokenContract.approve(
                this.mysteryAddr,
                ethers.BigNumber.from(
                    "115792089237316195423570985008687907853269984665640564039457584007913129639935"
                )
                );
                this.$swal.fire({
                title: this.$t("Approving Allowance"),
                icon: "info",
                didOpen: () => {
                    this.$swal.showLoading();
                },
                });
                // let provider = new ethers.providers.Web3Provider(window.ethereum);
                let provider = this.provider;
                const txReceipt = await provider.waitForTransaction(
                approveAllowance.hash
                );
                if (!txReceipt) {
                this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
                return false;
                }
                this.$swal.fire({
                title: this.$t("Allowance Approved"),
                icon: "success",
                });
            }

            return true;
        },
    }
}
</script>

<style>
.bg-coral {
    position: absolute;
    width: 368px;
    transition: .5s;
}
.mystery {
    color: white;
}
.lightmode .mystery {
    color: var(--dark);
}
.mystery-container {
    width: 90%;
    margin: auto;
}
.mystery-preview {
    /* width: 300px; */
    margin: auto;
    padding: 20px;
    text-align: center;
}

.mystery-preview img {
    width: 200px;
    max-width: none;
    margin-top: 30px;
}
.mystery-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.mystery-preview img {
    width: 100%;
    max-width: none;
}
.mystery-content .desc {
    width: auto;
    padding: 10px 0;
}
.mystery-content .title {
    font-size: 25px;
}
.mystery-container .headline-title {
    font-size: 27px;
    text-align: center;
    /* margin-top: 30px; */
    color: var(--gold);
    /* font-weight: 700; */
}

.mystery-container .headline-subtitle {
    font-size: 25px;
    text-align: center;
}

.mystery-content .mystery-btn {
    width: 280px;
    margin-bottom: 20px;
    align-items: center;
    background-color: var(--gold);
    /* background-image: linear-gradient(var(--gold), rgba(105, 67, 24, 0.84) 60%); */
    border-radius: 42px;
    border-width: 0;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-family: Quicksand,sans-serif;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .04em;
    line-height: 16px;
    padding: 18px 18px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    transition: .5s;
}

.mystery-content .mystery-btn:hover {
    text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px,rgba(255, 255, 255, 0.2) 0 0 12px,rgba(57, 31, 91, 0.6) 1px 1px 4px,rgba(57, 31, 91, 0.32) 4px 4px 16px;
    box-shadow: rgba(91, 77, 31, 0.24) 0 2px 2px,rgba(201, 188, 132, 0.4) 0 4px 8px;
}

.mystery-container .mystery-collection,
.mystery-container .ar-collection{
    text-align: center;
    margin-top: 30px;
}

.collection-container {
    margin-top: 20px;
    display: grid;
    /* grid-template-columns: 1fr; */
    grid-template-columns: repeat(auto-fit, 300px);
    gap: 30px;
    justify-content: center;
}

.mystery-collection .sub-title,
.ar-collection .sub-title {
    font-size: 25px;
}

.ar-collection .sub-title {
    padding-top: 40px;
}

.mystery-img {
    width: 100%;
    position: relative;
    height: 300px;
}

.mystery-img img{
    border-radius: 16px;
}

.mystery-img video {
    width: 100%;
    position: relative;
    border-radius: 16px;
}
.tag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* display: none; */
    opacity: 0;
    /* transform: translate(0, 250px);
    transition: all 1s; */
}

.tag.visible {
    /* display: block; */
    opacity: 1;
    z-index: 2;
    /* animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s; */
    transition: all 1s;
}
.tag.visible + img {
    /* display: block; */
    opacity: 0;
    /* animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s; */
    transition: all 1s;
}

.mystery-container .mystery_input-wrapper{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color:#27292d;
    /* padding: 10px; */
    width: 100%;
    max-width: 200px;
    font-size: 25px;
    border-radius: 30px;
    height: 50px;
    padding: 0 10px;
    box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 8px;
}

.mystery-container .mystery_input-wrapper button{
    border: none;
    color: white;
    background-color: transparent;
    font-size: 20px;
}

.mystery-container .mystery_input-wrapper .dropdown-menu{
    width: 100%;
}

.lightmode .mystery-container .mystery_input-wrapper{
    background-color: #d5e7f7;
    color: black;
}

.lightmode .mystery-container .mystery_input-wrapper button{
    color: black;
}

.mystery-container .btn-disabled{
    opacity: 0.8;
}

.mystery-container .reveal-btn {
    width: 100%;
    border-radius: 8px;
    background-image: linear-gradient(var(--gold), rgba(105, 67, 24, 0.84) 60%);
    height: 30px;
    border: none;
    color: white;
}

/* .mystery-container .serial-number {
    position: absolute;
    top: 38px;
    left: 55%;
    right: 50%;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 25px;
} */

.mystery-container .overlay{
    position: absolute;
    background:rgba(0,0,0, 0.5);
    width: 100%;
    height: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    cursor: pointer;
}

.mystery-container .reveal-text{
    font-family: "Montserrat", Helvetica, Arial;    
    text-align: center;
    font-size: 2rem;
    letter-spacing: 5px;
    text-shadow: 1px 1px 2px var(--gold);
    color: white;
    transform: rotate(330deg);
}

.mystery-container .serial-number {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 60%;
    margin: auto;
    font-family: "Montserrat", Helvetica, Arial;
    display: none;
    /* animation-delay: 1s; */
}

.mystery-container .door {
    /* background: #fafafa; */
    width: 100%;
    height: 40px;
    overflow: hidden;
    border-radius: 5px;
    margin: 5px;
}

.mystery-container .boxes {
    /* transform: translateY(0); */
    transition: transform 1s ease-in-out;
}

.mystery-container .box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.animated{
    display: block !important;
    animation: showNumber 5s ease-in;
}
/* 
@keyframes showNumber {
    from {opacity: 0.1;}
    to {opacity: 1;}
} */
.downloads {
    display:  flex;
    gap: 20px;
    flex-direction: column;
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
}

.downloads img {
    margin: 20px;
    width: 250px;
    height: auto;
    object-fit: contain;
}

.mystery .img-area-demo{
    width: 100%;
    /* height: 320px; */
    position: relative;
}

.mystery .img-area-demo .thumbnail{
    width: 100%;
    height: 100%;
}

.mystery .img-area-demo .thumbnail img,
.mystery .img-area-demo .thumbnail video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* border-radius: 10px 10px 0 0; */
}

.mystery .img-area-demo .switch-btn{
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
}

.mystery .img-area-demo .switch-btn:hover{
    opacity: 0.2;
}

.mystery .img-area .switch-btn{
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
}

.mystery .img-area .switch-btn:hover{
    opacity: 0.2;
}

.mystery .mystery_input-wrapper img{
    width: 30px;
    height: 30px;
}

.mystery .headline-subtitle{
    font-size: 16px;
    /* color: lightgray; */
}

@media (min-width: 768px) {
    .mystery .headline-subtitle{
        font-size: 25px;
    }
    .downloads {
        flex-direction: row;
    }
    .mystery-preview img {
        width: 400px;
        max-width: none;
    }
    .mystery-content .desc {
        width: 750px;
        padding: 10px 0;
    }
    .button-72 {
        font-size: 21px;
        padding: 18px 34px;
    }
    .collection-container {
        /* margin-top: 20px; */
        display: grid;
        /* grid-template-columns: 20% 20% 20% 20% 20%; */
        /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
        margin: 20px auto;
    }
    .mystery-preview img {
        width: 400px;
        max-width: none;
    }
    .mystery-content .desc {
        width: 750px;
        padding: 10px 0;
    }
    .mystery-container .headline-title {
        font-size: 70px;
        text-align: center;
        /* margin-top: 30px; */
        color: var(--gold);
    }
}
</style>