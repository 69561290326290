<template>
  <div :class="lightMode && lightMode == 'dark' ? 'home' : 'home lightmode'">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="banner-bg">
      <div class="banner-wrapper">
        <div v-if="homeNft" class="home_note-img-wrapper">
          <!-- <div class="home_note-info">
            <div class="note-wrapper">
              <img class="note-profile-img" :src="homeNft.profile_pic" alt="landing_banner">
              <img class="note-profile-img" src="../assets/images/default_profile.jpg" alt="landing_banner">
              <div class="note-info-wrapper">
                <span>Straits Settlements #6021843-001</span>
                <span>Dato”Eric</span>
                <span>{{ homeNft.category + " #" + homeNft.pmg_cert }}</span>
                <span>{{ homeNft.name }}</span>
              </div>
            </div>
            <b-icon-info-circle scale="1.2"></b-icon-info-circle>
          </div> -->
          <div class="home_landing-banner-title">
            {{ $t("Create Your Own Specialized Gallery") }}
          </div>
          <img class="home_landing-banner-bg" :src="lightMode == 'light' ? banner.dark : banner.light" alt="landing_banner" />
          <!-- <img class="note-img" :src="homeNft.front_img" alt="landing_banner"> -->
        </div>
        <div v-if="lightMode && lightMode == 'dark'" class="bg-sphere"></div>
        <div class="landing-content">
          <div class="title">
            {{ $t('Discover, Collect,') }}<br/>
            {{ $t('Buy & Sell') }}
          </div>
          <div class="sub-content">
            {{ $t("The World's First & Largest Numismatic NFT Marketplace") }}
          </div>
          <div class="home_content">
            {{ $t("Together with NumisArt, Let's redefine old currency trading,") }} <br />
            {{ $t("Change its course, Elevate it into an art,") }} <br />
            {{ $t("And become a platform that safeguards your ownership!") }} <br />
          </div>
          <router-link to="/all/owner" class="banner-btn">
            {{ $t('Explore') }}
          </router-link>
          <a href="https://docs.numisart.io/creating/how-do-i-create-an-nft" target="_blank" class="banner-btn responsive">
            {{ $t('Create') }}
          </a>
          <br/>
          <div class="certik-wrapper">
            <a href="https://www.certik.com/projects/numisart-marketplace" target="_blank">
              <img :src="lightMode && lightMode == 'dark' ? certikLogo.light : certikLogo.dark" alt="">
            </a>
            <span>{{ $t('Decentralize marketplace audited by Certik') }}</span>
            <!-- <a href="" target="_blank">report link</a> -->
          </div>
        </div>
      </div>
    </div>

    <div class="ads">
      <img v-if="!isMobile" src="../assets/images/ads-banner.png" alt="pmg_bsc_ads">
      <img v-if="isMobile" src="../assets/images/ads-banner-mobile.png" alt="pmg_bsc_ads">
      <a href="https://docs.numisart.io/getting-started/which-blockchains-does-numisart-support" target="_blank" class="ads-btn">
        {{ $t('Explore') }}
      </a>
    </div>

    <div class="home_container">

      <!-- <div class="stats-content">
        <div class="stats-wrapper">
          <img
            class="diamond"
            src="../assets/images/bnb-diamond.png"
            alt="diamond"
          />
          <div class="value-wrapper">
            <div class="value">
              42,127
            </div>
            <div class="title">
              {{ $t('User Active') }}
            </div>
          </div>
        </div>
        <div class="stats-wrapper">
          <img
            class="diamond"
            src="../assets/images/bnb-diamond.png"
            alt="diamond"
          />
          <div class="value-wrapper">
            <div class="value">
              1,136,278
            </div>
            <div class="title">
              {{ $t('NTFs Token') }}
            </div>
          </div>
        </div>
        <div class="stats-wrapper">
          <img
            class="diamond"
            src="../assets/images/bnb-diamond.png"
            alt="diamond"
          />
          <div class="value-wrapper">
            <div class="value">
              1,152,362
            </div>
            <div class="title">
              {{ $t('Minted NFTs') }}
            </div>
          </div>
        </div>
        <div class="stats-wrapper">
          <img
            class="diamond"
            src="../assets/images/bnb-diamond.png"
            alt="diamond"
          />
          <div class="value-wrapper">
            <div class="value">
              362,176
            </div>
            <div class="title">
              {{ $t('Total Sales') }}
            </div>
          </div>
        </div>
      </div> -->
      <div v-if="$i18n.locale == 'en'" class="sub-content"><iframe class="edu_vid" src="https://www.youtube.com/embed/nsQBaXTSrM0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      <div v-if="$i18n.locale == 'zh'" class="sub-content"><iframe class="edu_vid" src="https://www.youtube.com/embed/6tSx6T25xQM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>

      <div class="sub-content">{{ $t("What is the relationship between currencies and NFTs?") }}</div>

      <div class="sub-content">
        {{ $t('What is an NFT?') }}
      </div>

      <div class="section-container">
        <div class="content-wrapper">
          <div class="left-content">
            <div>{{ $t("A Non-Fungible Token (NFT) is a unique and irreplaceable digital token, it turns a physical object such as artwork into an irreplaceable digital asset. While physical objects will age and might even be destroyed over time, NFTs can be preserved forever.") }}</div>
            <div>{{ $t("NFTs use blockchain technology to record all of their transactions. So even if the NFT changes hands over time, the name of the NFT’s original creator will always be there, and they will forever enjoy the NFT’s royalty fees.") }}</div>
          </div>
          <div class="right-content">
            <img class="banner" src="../assets/images/homepage/what-is-nft.png" alt="pmg_what_is_nft">
          </div>
        </div>
      </div>

      <div class="sub-content">
        {{ $t('The similarities between Currencies and NFTs') }}
      </div>

      <div class="section-container">
        <div class="content-wrapper">
          <div class="left-content">
            <img class="banner" src="../assets/images/homepage/home-similarities.png" alt="similarities_nft">
          </div>
          <div class="right-content">
            <div>{{ $t("Old currencies and NFTs can be considered a match made in heaven. It is the perfect marriage between the physical and the virtual.") }}</div>
            <div>{{ $t("Each coin has its own serial number, so there will never be two coins that are the same in the world. This is the same for NFTs, as each is unique and irreplaceable. ") }}</div>
            <div>{{ $t("Hence, old currencies are the most suitable candidate for NFTs. Currency is an irreplaceable physical asset in the real world whereas NFTs play the same role in the virtual world. ") }}</div>
            <div>{{ $t("By combining the two, you will therefore have the world’s most valuable assets.") }}</div>
          </div>
        </div>
      </div>

      <div ></div>
      <div class="sub-content">
        {{ $t('Why mint your currency with NumisArt?') }}
      </div>

      <div class="section-container">
        <div style="margin: auto; text-align:center; max-width: 1300px; ">
          {{ $t("NumisArt is the world's first and largest numismatic NFT marketplace and a trusted trading platform. Here you can safely mint your cherished currencies into NFTs and enjoy all the following benefits:") }}
        </div>
        <div class="content-wrapper">
          <div class="left-content">
            <div><b>{{ $t("First: ") }}</b>{{ $t("Only NumisArt can safeguard the creator's ownership forever. As long as it is an NFT you have created, you will receive a 5% royalty fee for every transaction it goes through! So even though the physical currency is not in your hands anymore, it can still continue to grant you profits!") }}</div>
            <div><b>{{ $t("Second: ") }}</b>{{ $t("NumisArt can give you eternal ownership! In reality, once an old currency changes hands a few times, it will be difficult to trace its former owner. But with NumisArt, if you created the numismatic NFT, you'll be recognized as its creator forever.") }}</div>
            <div><b>{{ $t("Third: ") }}</b>{{ $t("NumisArt provides an international old currency trading market. Compared to the real world, your numismatic NFTs will be exposed to even more buyers from all over the world and therefore increase its value.") }}</div>
            <div><b>{{ $t("Fourth: ") }}</b>{{ $t("The transactions are fast, simple and transparent! You can set a fixed price, or hold a public auction, it's all up to you! Instant transactions and instant collection of money are also easy!") }}</div>
          </div>
          <div class="right-content">
            <img class="banner" src="../assets/images/homepage/why-mint.png" alt="pmg_why_mint">
          </div>
        </div>
      </div>

      <!-- <div class="bg-section">
        <div class="sub-content">
          {{ $t('How Does NUMISMATICS & NFT Relate ?') }}
        </div>
        <div class="sub-desc">
          {{ $t('A banknote is one-of-a-kind, no two are the same because ') }}<span class="bold">{{ $t('the serial numbers in each banknote is unique.') }} </span><br/> <br/>
          <span>{{$t('Not every collectible item can be an NFT, seeing as how NFT is a digital certificate in the first place. The physical entity must be able to be uniquely identified.')}}</span> <br> <br>
          <span class="bold">{{ $t("A banknote is the perfect example of what a non-fungible token is. ") }}</span>{{ $t("If you traded it for a different banknote, you'd have something completely different, because no two serial numbers are the same.") }} <br/> <br/>
          {{ $t('If an ')}}<span class="bold">{{ $t('NFT is a digital asset that represents real-world objects, then a banknote can be considered a physical asset that represents NFT.') }}</span> <br/> <br/>
        </div>
      </div> -->
      
      <!-- <div class="section-wrapper">
        <div class="sub-content">
          {{ $t('What is ') }}<span class="orangify"> Numisart.io ?</span>
        </div>
  
        <div class="section-container">
          <div class="content-wrapper">
            <div class="left-content">
              <div class="orangify-bold" style="display: flex; align-items: center;">
                <img style="padding-right: 10px; width: 20px;" src="../assets/images/homepage/orange-dot.png" alt="pmg_orange_dot">
                <span>{{ $t("WHO WE ARE?") }}</span>
              </div>
              <div class="bold">{{ $t("NumisArt is specifically curated for Numismatics NFTs marketplace and developed under the Binance Blockchain Technology.") }}</div>
              <div>{{ $t("NumisArt offers a marketplace allowing for numismatics NFTs to be sold directly at a fixed price, or through an auction, at the users discretion.") }}</div>
              <div><span class="orangify-bold">{{ $t("To Sell: ") }}</span>{{ $t("Users can showcase their entire collection or choose to hide certain collections. Navigating is completely flexible and user friendly.") }}</div>
              <div><span class="orangify-bold">{{ $t("To Buy: ") }}</span>{{ $t("Users can navigate and browse other collectors' collections using the filter selection, and can also choose to place a bid or buy outright a fixed price set by the owner.") }}</div>
            </div>
            <div class="right-content">
              <img class="round-img" src="../assets/images/homepage/what-is-numisart.png" alt="pmg_what_is_numisart">
            </div>
          </div>
        </div>
      </div> -->

      <div class="sub-content">
        {{$t('Collect and trade confidently with NumisArt')}}
        <!-- {{ $t('What are the')}} <span class="orangify-bold">{{ $t('benefits') }}</span> {{ $t('of listing on Numisart.io?') }} -->
      </div>
      <div class="benefits">
        <div class="instructions-wrapper benefit">
          <div v-for="b in benefits" :key="b" class="item-wrapper">
            <img :src="lightMode == 'light' ? b.dark : b.light" alt="pmg_steps">
            <div class="sub-title">
              {{ $t(b.title) }}
            </div>
            <div>
              {{ $t(b.desc) }}
            </div>
          </div>
          <!-- <div class="item-wrapper">
            <img src="../assets/images/icons/create-yr-collection.png" alt="pmg_steps">
            <div class="sub-title">
              {{ $t('Create your collection') }}
            </div>
            <div>
              {{ $t("Click My Collections and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.") }}
            </div>
          </div>
          <div class="item-wrapper">
            <img src="../assets/images/icons/mint-yr-nfts.png" alt="pmg_steps">
            <div class="sub-title">
              {{ $t('Mint your NFTs') }}
            </div>
            <div>
              {{ $t('Handover your original banknote to Trigo Office, we will help you to scan, add a title and description, and generate your NFTs with properties, stats and unlockable content, you just click "Mint".') }}
            </div>
          </div>
          <div class="item-wrapper">
            <img src="../assets/images/icons/list-em-for-sale.png" alt="pmg_steps">
            <div class="sub-title">
              {{ $t('List them for sale') }}
            </div>
            <div>
              {{ $t("Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs, and we help you sell them!") }}
            </div>
          </div> -->
        </div>
      </div>

      <div class="sub-content">
        {{ $t('Notable Drops') }}
      </div>
      <VueSlickCarousel v-if="topCollection && topCollection.length > 0" class="slideshow-img" v-bind="isMobile ? slickSettingsM : slickSettings">      
        <div class="img-wrapper">
          <!-- <img @click="this.$router.push({ name: 'Profile', params: { address: userAddress } })" src="../assets/images/about.png" alt="pmg_notables"> -->
          <img src="../assets/images/homepage/notable-left.png" alt="pmg_notables">
        </div>
        <div class="img-wrapper">
          <!-- <img @click="this.$router.push({ name: 'Profile', params: { address: userAddress } })" src="../assets/images/about.png" alt="pmg_notables"> -->
          <img src="../assets/images/homepage/notable-mid.png" alt="pmg_notables">
        </div>
        <div class="img-wrapper">
          <!-- <img @click="this.$router.push({ name: 'Profile', params: { address: userAddress } })" src="../assets/images/about.png" alt="pmg_notables"> -->
          <img src="../assets/images/homepage/notable-right.png" alt="pmg_notables">
        </div>
        <div v-for="(top, index) in topCollection" :key="index" class="img-wrapper">
          <!-- <img @click="this.$router.push({ name: 'Profile', params: { address: userAddress } })" src="../assets/images/about.png" alt="pmg_notables"> -->
          <img :src="top.banner" alt="pmg_notables">
        </div>
        <!-- <div class="img-wrapper">
          <img src="../assets/images/bg.jpg" alt="pmg_notables">
          <span>test3</span>
        </div>
        <div class="img-wrapper">
          <img src="../assets/images/mission-coin.png" alt="pmg_notables">
          <span>test4</span>
        </div>
        <div class="img-wrapper">
          <img src="../assets/images/roadmap.png" alt="pmg_notables">
          <span>test5</span>
        </div> -->
      </VueSlickCarousel>

      <div class="sub-content">
        {{ $t('Top collections') }}
      </div>

      <div class="top-collections">
        <div class="top-collection-wrapper">
            <div @click="handleRoute(top.current_owner)" v-for="(top, index) in topCollection" :key="top" class="top-content-wrapper">
              <div>{{index + 1}}</div>
              <img :src="top.profile_pic" alt="pmg_top">
              <div class="name">{{ top.name }}</div>
              <div class="nft-numbers-wrapper">
                <span>{{ $t('No. of NFTs') }}</span>
                <span>{{ top.total_owned }}</span>
              </div>
            </div>
        </div>
      </div>

      <div class="sub-content">
        {{ $t('4 Easy Steps - Mint & Sell Your NFT') }}
      </div>

      <div class="create-n-sell">
        <div class="instructions-wrapper resources">
          <div v-for="r in resources" :key="r" class="item-wrapper">
            <img :src="lightMode == 'light' ? r.dark : r.light" alt="pmg_steps">
            <div class="sub-title">
              {{ $t(r.title) }}
            </div>
            <div>
              {{ $t(r.desc) }}
            </div>
          </div>
          <!-- <div class="item-wrapper">
            <img src="../assets/images/icons/create-yr-collection.png" alt="pmg_steps">
            <div class="sub-title">
              {{ $t('Create your collection') }}
            </div>
            <div>
              {{ $t("Click My Collections and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.") }}
            </div>
          </div>
          <div class="item-wrapper">
            <img src="../assets/images/icons/mint-yr-nfts.png" alt="pmg_steps">
            <div class="sub-title">
              {{ $t('Mint your NFTs') }}
            </div>
            <div>
              {{ $t('Handover your original banknote to Trigo Office, we will help you to scan, add a title and description, and generate your NFTs with properties, stats and unlockable content, you just click "Mint".') }}
            </div>
          </div>
          <div class="item-wrapper">
            <img src="../assets/images/icons/list-em-for-sale.png" alt="pmg_steps">
            <div class="sub-title">
              {{ $t('List them for sale') }}
            </div>
            <div>
              {{ $t("Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs, and we help you sell them!") }}
            </div>
          </div> -->
        </div>
      </div>

      <!-- <div class="how-row">
        <div v-if="lightMode && lightMode == 'dark'" class="bg-sphere"></div>

        <div class="how-left">
          <div class="title">{{ $t('How it works') }}</div>

          <div class="sub-content">
            {{ $t("Getting started and selling your NFTs. Explore the full process so you don't have any confusions.") }}
            
          </div>

          <router-link to="/about" tag="button" class="learn-more-btn web-btn">
            {{ $t('Learn More') }}
          </router-link>
        </div>

        <div class="how-right">
          <div class="how-right-top">
            <div class="how-content">
              <img src="../assets/images/icon_nft.png" alt="nft" />
              <div class="title">{{ $t('NFT Digital Assets') }}</div>
              <div class="content">
                {{ $t('NFT certifies digital assets to be unique, tracked on blockchains and provide the owner with a proof of ownership.') }}
              </div>
            </div>

            <div class="how-content">
              <img src="../assets/images/icon_metamask.png" alt="metamask" />
              <div class="title">{{ $t('MetaMask') }}</div>
              <div class="content">
                {{ $t('MetaMask allows users to store, manage, send and receive digital assets in a secure and safe environment.') }}
              </div>
            </div>
          </div>

          <div class="how-right-bottom">
            <div class="how-content">
              <img src="../assets/images/icon_numisart.png" alt="numisart" />
              <div class="title">NumisArt</div>
              <div class="content">
                {{ $t('NumisArt is a marketplace, specifically curated for Numismatic NFTs on the BSC blockchain technology. Here you can buy, sell & auction assets.') }}
              </div>
            </div>
          </div>
        </div>

        <router-link to="/about" tag="button" class="learn-more-btn mobile-btn">
          Learn More
        </router-link>
      </div> -->
      <div class="sub-content">
        {{ $t('Resources for getting started') }}
      </div>

      <div class="resources">
        <div class="content-wrapper">
          <a href="https://docs.numisart.io/getting-started/how-do-i-create-an-numisart-account" target="_blank"><img src="../assets/images/how-2-easily-setup.png" alt="pmg_setup_metamask"></a>
          <a href="https://docs.numisart.io/getting-started/how-do-i-purchase-bnb-and-usdt" target="_blank"><img src="../assets/images/how-2-fund.png" alt="pmg_fund_metamask"></a>
          <a href="https://docs.numisart.io/getting-started/how-do-i-search-for-nfts" target="_blank"><img src="../assets/images/how-to-find-nft.png" alt="pmg_find_nft"></a>
        </div>
      </div>

      <div class="sub-content">
        {{ $t('Browse by category') }}
      </div>

      <div class="marketplace">
        <div class="marketplace-container">
          <div class="marketplace-box">
            <img src="../assets/images/notes/1st-series.jpg">
            <div class="marketplace-content">
              Malaysia, 1st Series, 1000 Ringgit
            </div>
          </div>

          <div class="marketplace-box">
            <img src="../assets/images/notes/2nd-series.jpg">
            <div class="marketplace-content">
              Malaysia, 2nd Series, 1000 Ringgit
            </div>
          </div>

          <div class="marketplace-box">
            <img src="../assets/images/notes/3rd-series.jpg">
            <div class="marketplace-content">
              Malaysia, 3rd Series, 10 Ringgit
            </div>
          </div>
        </div>

        <div class="marketplace-container">
          <div class="marketplace-box">
            <img src="../assets/images/notes/4th-series.jpg">
            <div class="marketplace-content">
              Malaysia, 4th Series, 10 Ringgit
            </div>
          </div>

          <div class="marketplace-box">
            <img src="../assets/images/notes/5th-series.jpg">
            <div class="marketplace-content">
              Malaysia, 5th Series, 500 Ringgit
            </div>
          </div>

          <div class="marketplace-box">
            <img src="../assets/images/notes/6th-series.jpg">
            <div class="marketplace-content">
              Malaysia, 6th Series, 20 Ringgit
            </div>
          </div>
        </div>

        <div class="marketplace-container">
          <div class="marketplace-box">
            <img src="../assets/images/notes/7th-series.jpg">
            <div class="marketplace-content">
              Malaysia, 7th Series, 10 Ringgit
            </div>
          </div>

          <div class="marketplace-box">
            <img src="../assets/images/notes/sarawak.jpg">
            <div class="marketplace-content">
              Sarwak Banknotes, 10 Ringgit
            </div>
          </div>

          <div class="marketplace-box">
            <img src="../assets/images/notes/straits-settlements.jpg">
            <div class="marketplace-content">
              Straits Settlements, 10 Dollar
            </div>
          </div>
        </div>
        <!-- <button class="load-more-btn mt-5">{{ $t('Load More') }}</button> -->
      </div>

      <!-- <div class="get-in-touch">
        <div class="img-div">
          <img src="../assets/images/get_in_touch.png" />
        </div>

        <div class="content-container">
          <div class="title">{{ $t('Get in touch with us') }}</div>
          <div class="content">
            {{ $t('Are you passionate about history, coins and collectibles? Then this place is the right place for you! Become a NumisNFT collector, and start collecting art, history and culture.') }}
          </div>

          <div class="email-input">
            <input v-model="email" type="email" placeholder="Enter your email" />
            <button @click="subscribe" > {{ $t('Send') }} </button>
          </div>
        </div>
      </div> -->
      
    </div>
    <div class="meet-numisart">
      <div class="title">{{ $t('Meet NumisArt') }}</div>
      <div class="desc">{{ $t('The NFT marketplace with all numismatic item for everyone') }}</div>
      <img v-if="!isPlaying" @click="isPlaying = true" src="../assets/images/homepage/numisart-video.png" alt="nmc_video">
      <video v-if="isPlaying" autoplay controls src="../assets/images/homepage/meet-numisart.mp4"></video>
      <router-link to="/marketplace" class="banner-btn">
        {{ $t('Explore the marketplace') }}
      </router-link>
    </div>

    <div class="icons-container">
      <div class="sub-content">
        {{ $t("Strategic Partners") }}
      </div>
      <div class="icons-wrapper">
        <div><img src="../assets/images/homepage/pmg.png" alt="pmg_pmg"></div>
        <div><img src="../assets/images/homepage/ngc.png" alt="pmg_ngc"></div>
        <div><img src="../assets/images/homepage/pcgs.png" alt="pmg_pcgs"></div>
        <div><img src="../assets/images/homepage/legacy.png" alt="pmg_legacy"></div>

      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import $ from 'jquery'
import axios from "axios";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
const config = {
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem("access_token"),
  },
}

export default {
  name: 'Home',
  components: {
    VueSlickCarousel 
  },
  props: {
    lightMode: String,
    userAddress: String
  },
  data () {
    return {
      banner:{
        dark: require('../assets/images/homepage/sixseriesnotesV2.png'),
        light: require('../assets/images/homepage/sixseriesnotesV2Dark.png')
      },
      isPlaying: false,
      email: '',
      vw: null,
      isMobile: false,
      slickSettings: {
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
      slickSettingsM: {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 5
      },
      topCollection: [],
      total: null,
      resources: [
        {
          dark: require('../assets/images/icons/set-up-yr-wallet.png'),
          light: require('../assets/images/icons/set-up-yr-wallet_white.png'),
          title: this.$t('Set up your wallet'),
          desc: this.$t("First, download the MetaMask App, then set up your Crypto Wallet. Then connect it to the Numisart platform.")
        },
        {
          dark: require('../assets/images/icons/create-yr-collection.png'),
          light: require('../assets/images/icons/create-yr-collection_white.png'),
          title: this.$t('Create your collection'),
          desc: this.$t("Contact our designated minting team who will enter all your banknote details into the system, then place the banknotes in a secure envelope and deliver the envelope to Trigometric's office.")

        },
        {
          dark: require('../assets/images/icons/mint-yr-nfts.png'),
          light: require('../assets/images/icons/mint-yr-nfts_white.png'),
          title: this.$t('Mint your NFTs'),
          desc: this.$t("Trigometric will confirm, scan, and convert your banknotes into NFTs with properties, stats and unlockable content, all you need to do is click 'Mint' on your mobile phone.")
        },
        {
          dark: require('../assets/images/icons/list-em-for-sale.png'),
          light: require('../assets/images/icons/list-em-for-sale_white.png'),
          title: this.$t('List them for sale'),
          desc: this.$t("You can choose to sell at auction or set your own price. You can even choose the way you want to sell, and the system will automatically trade according to your settings!")
        }
      ],
      benefits: [
        {
          dark: require('../assets/images/icons/safeguard.png'),
          light: require('../assets/images/icons/safeguard-light.png'),
          title: this.$t("Safeguards the Creator's Ownership"),
          desc: this.$t("The creator of the NFT will forever be recognised as the first owner of the coin. Once recorded in the blockchain, the creator's ownership will be recorded forever, which is difficult to tamper with and cannot be deleted.")

        },
        {
          dark: require('../assets/images/icons/royalty.png'),
          light: require('../assets/images/icons/royalty_white.png'),
          title: this.$t("Earn Royalties for Life"),
          desc: this.$t("NFT creators will receive a 5% royalty fee on each transaction, for life.")
        },
        {
          dark: require('../assets/images/icons/showcase.png'),
          light: require('../assets/images/icons/showcase_white.png'),
          title: this.$t("Easy Minting, Perfect Displaying"),
          desc: this.$t("Collectors can easily mint their numismatic NFTs and proudly present their collections to the world.")
        },
        {
          dark: require('../assets/images/icons/exposure.png'),
          light: require('../assets/images/icons/exposure_white.png'),
          title: this.$t('Increase Exposure to Global Markets'),
          desc: this.$t("NFT creators can immerse themselves in the vast currency market and the global NFT market, increase the exposure of their collection, and easily find kindred spirits and better trading opportunities.")
        },
        {
          dark: require('../assets/images/icons/cert.png'),
          light: require('../assets/images/icons/cert-light.png'),
          title: this.$t("PMG Certification & Verification"),
          desc: this.$t("PMG is the largest third-party banknote grading service in the world, with more than 6 million banknotes certified. Every banknote that PMG grades is trusted by buyers and sellers. Through our partnership with PMG, our currency NFTs are certified and verified with their own number and code.")

        },
        {
          dark: require('../assets/images/icons/transected.png'),
          light: require('../assets/images/icons/transected_white.png'),
          title: this.$t('Fast and Secure Transactions'),
          desc: this.$t("NumisArt platform has been certified by CERTIK, a third-party blockchain security audit company, which is safe and transparent. Users can reliably trade their numismatic NFTs on NumisArt quickly, transparently and securely.")
        }
      ],
      certikLogo: {
        light: require("../assets/images/icons/certik-light-logo.png"),
        dark: require("../assets/images/icons/certik-dark-logo.png")
      },
      homeNft: null
    }
  },
  watch: {
    vw () {
      if (this.vw > 1200) {
        this.isMobile = false
      } else {
        this.isMobile = true
      }
    }
  },
  async created () {
    const baseUrl = process.env.VUE_APP_API
    const ownerList = await axios.get(`${baseUrl}/user/top`,{params:{pageSize:15,page:1}});
    this.topCollection = ownerList.data.results
    this.total = ownerList.data.metadata.total
    const noteNft = await axios.get(`${baseUrl}/nft/randpho`);
    this.homeNft = noteNft.data
    console.log('noteNft: ', this.homeNft);

  },
  mounted () {
    document.body.style.backgroundImage = "none"
    this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    this.updateNft();
  },
  methods: {
    updateNft () {
      const baseUrl = process.env.VUE_APP_API
      axios.get(`${baseUrl}/nft/randpho`).then((res) => {
        console.log('noteNft: ', this.homeNft);
        this.homeNft = res.data;
        // setTimeout(() => { this.updateNft() }, 5000);
      });
    },
    handleRoute (address) {
      console.log('clicked!');
      this.$router.push({ name: 'ProfileOthers', params: { address: address } })
    },
    subscribe () {
      const baseUrl = process.env.VUE_APP_API
      axios.post(`${baseUrl}/user/subscription/${this.email}`, {}, config).then(() => {
        this.$swal.fire({
            title: this.$t('Email subscribed!'),
            icon: 'success'
        });
      })
      
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');
.slick-slider {
  width: 90%;
  margin: auto;
}
.home {
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
  margin: auto;
}

.home .bg-sphere {
  background: radial-gradient(rgba(64, 30, 81, 0.4), #151517);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0.4;
  position: absolute;
  left: -100px;
  z-index: -1;
}

.web-btn {
  display: none;
}
.banner-bg {
  background-image: url('../assets/images/home-bg.png');
  background-size: cover;
  -webkit-mask: linear-gradient(rgb(255, 255, 255) 95%, transparent);
}

.ads-btn {
  top: 10px;
  right: 10px;
  position: absolute;
  background-color: #ffa135;
  color: white;
  border-radius: 14px;
  border: none;
  padding: 17px 24px;
  font-weight: bold;
  text-decoration: none;
  width: 127px;
  text-align: center;
  border: 1px solid #ffa135;
}

.ads-btn:hover {
  background-color: white;
  color: var(--gold);
}

.banner-wrapper {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  padding: 20px 0;
  align-items: center;
}

.banner-wrapper .banner-img {
  margin-top: 20px;
}

/* .banner-wrapper::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255,255,255,0.85);
  height: 100%;
} */

.banner-wrapper .landing-content {
  width: 90%;
  margin: auto;
}

.landing-content .title,
.landing-content .sub-content {
  color: white;
  text-align: left;
  font-weight: 600;
}

.sub-content {
  color: white;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
}

.lightmode .banner-wrapper .title,
.lightmode .sub-content {
  color: var(--dark);
}

.home.lightmode .title {
  color: var(--dark) !important;
  font-weight: bold;
}

.banner-wrapper .home_note-img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.banner-wrapper .home_note-img-wrapper .note-img {
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.banner-wrapper .home_note-info {
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.banner-wrapper .note-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.banner-wrapper .note-info-wrapper {
  display: flex;
  flex-direction: column;
}

.banner-wrapper .note-info-wrapper span:nth-child(1) {
  font-weight: 700;
}

.banner-wrapper .note-info-wrapper span:last-child {
  font-weight: 600;
  color: #2382E2;
}

.banner-wrapper .note-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.banner-wrapper .title {
  font-size: 28px;
  font-weight: 800;
}

.banner-wrapper .sub-content {
  margin: 10px 0;
  font-size: 24px;
  color: var(--default);
}

.lightmode .banner-wrapper .sub-content {
  color: var(--dark);
}

.sub-content span {
  color: var(--gold);
}

/* .banner-wrapper .banner-btn {
  background: var(--gold-gradient);
  color: white;
  border: 1px solid transparent;
  border-radius: 12px;
  padding: 10px 30px;
} */

.stats-content {
  display: grid;
  margin: auto;
  grid-template-columns: 50% 50%;
}

.stats-wrapper {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  margin: 20px 0;
  align-items: center;
}

.stats-wrapper img {
  flex-grow: 0;
  width: 30px;
  object-fit: contain;
}

.stats-wrapper .value-wrapper {
  padding: 10px;
  text-align: start;
}

.stats-wrapper .value-wrapper .value {
  font-size: 1.8rem;
  color: white;
}

.stats-wrapper .value-wrapper .title {
  color: var(--dim-white);
}

.diamond {
  height: 30px;
}

.how-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.how-row .title {
  margin: 2rem 0rem 1rem 0rem;
  color: white;
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
}

.how-row .sub-content {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: var(--dark-font);
  text-align: center;
}

.how-left {
  flex: 0.6;
}

.how-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.how-right-top,
.how-right-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.how-content {
  width: 80%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  background-image: url('../assets/images/Path 369.png');
  background-repeat: no-repeat;
  background-size: contain;
  padding: 25px;
  min-height: 290px;
}

.how-content img {
  width: 60px;
}

.how-content .title {
  font-weight: 700;
  font-size: 1.2rem;
  color: white;
  margin: 10px 0px;
  text-align: left;
}

.how-content .content {
  margin-top: 0px;
  font-size: 1rem;
}

.banner-btn,
.learn-more-btn,
.load-more-btn {
  background-color: #ffa135;
  color: white;
  border-radius: 14px;
  border: none;
  padding: 17px 24px;
  font-weight: bold;
  text-decoration: none;
  width: 127px;
  text-align: center;
}

.banner-btn:hover,
.learn-more-btn:hover,
.load-more-btn:hover {
  background-color: white !important;
  color: var(--gold) !important;
  border: 1px solid var(--gold);
}


.banner-btn.responsive {
  margin-left: 20px;
  background-color: rgb(53, 56, 64);
  color: white;
  border: 1px solid #ffa135;
}

.lightmode .banner-btn.responsive {
  background-color: white;
  color: #ffa135;
}

.marketplace {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.marketplace > .title {
  margin: 2rem 0rem 1rem 0rem;
  color: white;
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
}

.marketplace-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.marketplace-box {
  display: flex;
  flex-direction: column;
  /* width: 80%; */
  width: 350px;
  /* background: linear-gradient(135deg, #1c1922 80%, #2b1a49 100%); */
  /* background: white; */
  background: var(--bs-gray-900);
  border-radius: 15px;
  margin-top: 1.5rem;
  /* border:1px solid #e3af72; */
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
}

.lightmode .marketplace-box {
  background: white;
}

.marketplace-box img{
  border-radius: 15px 15px 0 0;
  height: 200px;
  object-fit: cover;
  object-position: top;
}

/* .marketplace-box img{
  border-radius: 15px 15px 0 0;
} */

.marketplace-content {
  padding: 20px;
  color: var(--default);
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  white-space: pre-wrap;
  font-weight: 600;
  text-align: center;
}

.lightmode .marketplace-content {
  color: var(--dark);
}

.get-in-touch {
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #3f4bba 80%, #20265d 100%);
  border-radius: 35px;
  margin: 0 auto;
  margin-top: 5rem;
  text-align: center;
  width: 90%;
  padding: 0px 20px 30px 20px;
}

.content-container {
  display: flex;
  flex-direction: column;
}

.get-in-touch .title {
  font-weight: 800;
  color: white;
  font-size: 1.5rem;
  margin: 1rem 0rem;
  text-align: center;
}

.get-in-touch .content {
  margin-top: 0px;
  color: white;
  text-align: center;
}

.ads {
  position: relative;
  margin: 40px auto;
  text-align: center;
  width: 95%;
  max-width: 1300px
}

.ads img {
  object-fit: contain;
  height: auto;
  width: 100%;
  max-width: 1300px;
}

button.slick-prev:before {
  content: '\02F1' !important;
}

button.slick-next:before {
  content: '\02F2' !important;
}

button.slick-prev, button.slick-next {
  width: 50px;
  height: 50px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

button.slick-prev:before, button.slick-next:before {
  background-color: #FFFFFF !important;
  font-size: 50px;
  border-radius: 50%;
  color: var(--default);
  width: 50px;
  height: 50px;
  line-height: 0;
  z-index: 2;
}

.slick-dots li.slick-active button:before {
  color: white;
}

.lightmode .slick-dots li.slick-active button:before {
  color: black;
}

.slick-dots li button:before {
  color: var(--default);
}

.section-wrapper {
  padding-top: 50px;
  margin-bottom: 30px;
}

.slideshow-img {
  margin-bottom: 30px;
}

.slideshow-img div.img-wrapper {
  display: flex !important;
  justify-content: space-between;
  position: relative;
  width: 95% !important;
  margin: auto;
}

.slideshow-img img {
  width: 100%;
  height: 750px;
  object-fit: cover;
  border-radius: 14px;
  transition: .5s;
}

.slideshow-img img:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  border-radius: 14px;

}

.slideshow-img span {
  position: absolute;
  bottom: 30px;
  left: 10px;
  color: white;
}

.home_container .sub-content {
  font-size: 24px;
  text-align: center;
  padding: 30px 10px;
  color: white;
  font-weight: 600;
  z-index: 2;
  margin: auto;
  width: 100%;
}

.lightmode .home_container .sub-content {
  color: var(--dark);
}

.home .top-collections .top-collection-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  justify-content: space-between;
}

.home .top-collections .collection-col {
  margin-right: 44px;
  min-width: 30%;
}

.home .top-collections .top-content-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent rgb(229, 232, 235);
  padding: 16px;
  height: 88px;
  gap: 10px;
  color: white;
}

.home.lightmode .top-collections .top-content-wrapper {
  color: var(--dark);
}

.home .top-collections .top-content-wrapper .name {
  flex: 1 1 auto;
  flex-flow: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 16px;
  overflow: hidden;
  font-size: 16px;
  cursor: pointer;
}

.home .top-collections .top-content-wrapper img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.home .top-collections .top-content-wrapper .nft-numbers-wrapper {
  display: flex;
  flex: 0 0 auto;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;
}

.home .top-collections,
.home .create-n-sell,
.home .benefits,
.home .resources {
  width: 90%;
  margin: 30px auto;
}

.home .instructions-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
}

.home .instructions-wrapper.benefit {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
}

.home .instructions-wrapper .item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 250px;
  gap: 20px;
  margin-bottom: 40px;
  align-self: center;
  justify-self: center;
}

.home .instructions-wrapper .item-wrapper .sub-title {
  font-weight: 600;
  font-size: 14px;
  color: white;
  flex-basis: 0.5;
}

.home.lightmode .instructions-wrapper .item-wrapper .sub-title {
  font-weight: 600;
  font-size: 14px;
  color: var(--dark);
  flex-basis: 0.5;
}

.home .instructions-wrapper .item-wrapper .sub-title + div {
  font-size: 15px;
}

.home .instructions-wrapper .item-wrapper img {
  height: 55px;
  width: auto;
}

.home .resources .content-wrapper {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  /* max-width: 100%; */
  margin: auto;
  height: auto;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}

/* .home .resources .content-wrapper img { */
.home .resources .content-wrapper a {
  display: contents;
}

.home .resources .content-wrapper img {
  width: 100%;
}

.home .resources {
  width: 100%;
}

.home .meet-numisart {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 0;
  background-color: #222323;
  margin-top: 20px;
}

.home.lightmode .meet-numisart {
  background-color: #F3FBFE;
}

.home .meet-numisart .title {
  font-size: 24px;
  margin: 20px auto;
  border-radius: 0;
  color: white;
}

.home .meet-numisart .desc {
  font-size: 14px;
}

.home .meet-numisart video,
.home .meet-numisart img {
  width: 90%;
  margin-top: 30px;
  border-radius: 0;
}

.home .meet-numisart .banner-btn {
  margin: 80px 0;
  width: auto;
  border: 1px solid var(--gold);
}

.home .icons-container  {
  margin: 20px auto;
}

.home .icons-container .icons-wrapper {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  flex-direction: row;
}

.home .icons-container .icons-wrapper {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  flex-direction: row;
  text-align: center;
}

.home .icons-container .icons-wrapper div {
  padding: 20px;
}

.home .icons-container .icons-wrapper img {
  width: 65px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(184deg) brightness(200%) contrast(105%)
}

.home.lightmode .icons-container .icons-wrapper img {
  filter: none;
}

.home + .footer-container {
  margin-top: 0 !important;
}

.home .section-container .content-wrapper {
  display: flex;
  flex-direction: column;
}

.home.lightmode .section-container .content-wrapper {
  color: var(--dark);
}

.home .section-container .content-wrapper .left-content,
.home .section-container .content-wrapper .right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 30px;
  padding: 20px;
  font-size: 20px;
}

.home .section-container .content-wrapper .left-content .banner,
.home .section-container .content-wrapper .right-content .banner {
  max-width: 100%;
  height: 100%;
  width: auto;
  object-fit: contain;
}

.home .section-container .content-wrapper .left-content .banner {
  max-width: 100%;
  height: 450px;
  width: auto;
  object-fit: contain;
}

.home .section-container .content-wrapper .right-content {
  flex: 1;
}

.home .section-container .content-wrapper .right-content .round-img {
  height: 400px;
  width: auto;
  object-fit: contain;
}

.orangify {
  color: var(--gold);
}

.bold {
  font-weight: 600;
}

.orangify-bold {
  font-weight: 600;
  color: var(--gold);
}

.home .bg-section {
  background-image: url('../assets/images/homepage/coin-bg.png') ;
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 30px;
  margin-top: 30px;
}

.home .sub-desc {
  text-align: center;
  color: var(--dark);
  width: 80%;
  margin: auto;
}

.banner-wrapper .title,
.banner-wrapper .sub-content {
  text-align: center;
}

.home .certik-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  max-width: none;
  text-align: center;
  gap: 10px;
  flex-direction: column;
}

.home .certik-wrapper img {
  width: 180px;
}

.home .certik-wrapper span {
  font-weight: 700;
}

.home .home_content {
  margin: 20px 0;
}

.edu_vid {
  width: 300px;
  height: 169px;
}

.home_landing-banner-bg {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: 90%;
}

.home_landing-banner-title {
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: var(--gold);
  font-size: 24px;
  padding: 10px 0;
  color: white;
  width: 90%;
}

@media only screen and (min-width: 768px) {
  .home_container .sub-content {
    font-size: 35px;
  }
  .edu_vid {
    width: 1080px;
    height: 608px;
  }
  .home .certik-wrapper {
    align-items: flex-start;
    max-width: 350px;
  }
  .home .section-container .content-wrapper {
    flex-direction: row;
    width: 90%;
    margin: auto;
    max-width: 1300px;
  }
  .home .icons-container .icons-wrapper img {
    width: 120px;
  }
  .home .icons-container .icons-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
    gap: 20px;
    flex-direction: row;
  }
  .home .meet-numisart video,
  .home .meet-numisart img {
    width: 70%;
    max-width: 1270px;
  }
  .home .resources .content-wrapper {
    flex-direction: row !important;
    gap: 50px;
  }
  .home .resources .content-wrapper img {
    max-width: 30%;
  }
  .home .top-collections .top-collection-wrapper {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    column-gap: 50px;
    width: 90%;
    justify-content: space-between;
  }
  .home .instructions-wrapper.resources {
    /* flex-direction: row; */
    align-items: baseline;
    display: grid;
    margin: auto;
    /* flex-direction: column; */
    gap: 50px;
    justify-content: center;
    align-items: center;
    grid-template-columns: 20% 20% 20% 20%;
    padding-top: 40px;
  }
  .home .instructions-wrapper.benefit {
    /* flex-direction: row; */
    align-items: baseline;
    display: grid;
    margin: auto;
    /* flex-direction: column; */
    gap: 50px;
    justify-content: center;
    align-items: center;
    grid-template-columns: 28% 28% 28%;
    padding-top: 40px;
  }
  .home .instructions-wrapper .item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 350px;
    gap: 20px;
    margin: unset;
    margin-bottom: 20px;
    align-self: baseline;
  }
  .ads-btn {
    top: 30%;
    right: 30px;
  }
  .banner-btn,
  .learn-more-btn,
  .load-more-btn {
    width: 167px;
  }

  .banner-wrapper .banner-img {
    max-width: 550px;
    object-fit: contain;
    /* margin: 50px; */
    width: 50%;
  }

  .banner-wrapper .home_note-img-wrapper {
    max-width: 550px;
    margin-top: 0;
  }

  .banner-wrapper .title {
    font-size: 45px;
    line-height: 1.2;
  } 

  .banner-wrapper .title,
  .banner-wrapper .sub-content {
    text-align: left;
  }

  .banner-wrapper .landing-content {
    flex: 1;
  }

  .stats-wrapper {
    justify-content: center;
  }
  
  .stats-wrapper .value-wrapper .value {
    font-size: 2rem;
  }

  .stats-content {
    /* width: 70%; */
    display: grid;
    margin: auto;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .mobile-btn {
    display: none;
  }

  .web-btn {
    display: block;
  }

  .how-row {
    flex-direction: row;
    margin-top: 3rem;
  }

  .how-left .title {
    font-size: 3.5rem;
    text-align: left;
  }

  .how-left .sub-content {
    font-size: 1.2rem;
    text-align: left;
    margin: 2.5rem 0rem;
  }

  .how-content {
    width: 340px;
  }

  .how-content .title {
    font-weight: 700;
    font-size: 2rem;
    color: white;
    margin: 10px 0px;
  }

  .how-content .content {
    font-size: 1.1rem;
  }

  .how-right-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85%;
  }

  .how-right-bottom {
    width: 85%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .marketplace > .title {
    font-size: 3.5rem;
  }

  .marketplace-container {
    display: flex;
    flex-direction: row;
  }

  /* .marketplace-container div:not(:first-child) {
    margin-left: 2.5rem;
  } */

  .get-in-touch {
    flex-direction: row-reverse;
    padding: 0px;
    width: 100%;
  }

  .img-div {
    flex: 0.8;
  }

  .content-container {
    flex: 1;
    padding: 45px 65px;
    justify-content: center;
  }

  .get-in-touch .title {
    font-size: 2.5rem;
    text-align: left;
  }

  .get-in-touch .content {
    text-align: left;
    font-size: 1.1rem;
  }

}
@media only screen and (min-width: 1200px) {
    .banner-wrapper {
      text-align: center;
      flex-direction: row-reverse;
      width: 90%;
      margin: auto;
      align-items: flex-start;
      text-align: left;
      max-width: 1300px;
      transform: translateX(10px);
      padding: 100px 0;
    }
}
</style>