<template>
  <div class="nft-details">
    <div id="fullpage" @click="closeFullPage">
      <!-- <b-icon-x-circle @click="closeFullPage"></b-icon-x-circle> -->
    </div>
    <!-- preloader start here -->
    <div v-if="isLoading" class="preloader">
      <div class="preloader-inner">
        <div class="preloader-icon">
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <!-- preloader ending here -->

    <!-- ==========Item Details Section start Here========== -->
    <div class="item-details-section">
      <!-- <div class="container"> -->
        <div class="item-details-wrapper">
          <div class="row g-5 m-0" v-if="nftDetails.length == 1">
            <div class="col-lg-6 p-0 pe-lg-3 pt-lg-3">
              <div class="item-desc-part">
                <div class="item-desc-inner">
                  <div class="item-desc-thumb">
                    <!-- <img src="assets/images/nft-item/item-details.gif" alt="item-img"> -->
                    <div class="flip-card">
                      <input id="flip" type="checkbox" v-model="isback" />
                      <label for="flip" class="flip-card-inner">
                        <div class="flip-card-front">
                          <img
                            :src="nftDetails[0].real_image_front"
                            alt="item-img"
                          />
                          <h3 class="flip-serial-number" v-if="nftDetails[0].serial_number && nftDetails[0].turtle_status == 'banknote'">NM{{nftDetails[0].serial_number.toString().padStart(6,'0')}}</h3>
                        </div>
                        <div class="flip-card-back">
                          <img
                            :src="nftDetails[0].real_image_back"
                            alt="item-img"
                          />
                        </div>
                      </label>
                    </div>
                    <div class="image-functions">
                      <div class="flip-indication">
                        <img
                          :src="lightMode == 'light' ? flipIcons.black : flipIcons.white"
                          alt="flip-image"
                        />
                        <span>{{ $t("Click Banknote") }}</span>
                      </div>
                      <div class="fullpage-btn">
                        <b-icon-zoom-in @click="viewFullpage"></b-icon-zoom-in>
                        <span @click="viewFullpage">{{
                          $t("View Full Size")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <b-tabs class="item-desc-content" content-class="mt-3">
                      <b-tab :title="$t('Details')" active>
                        <div
                          class="details-tab tab-pane fade show active"
                          id="nav-details"
                          role="tabpanel"
                          aria-labelledby="nav-details-tab"
                        >
                          <p>
                            {{ $t("Comments") }}: {{ nftDetails[0].comment }}
                          </p>
                          <div
                            class="author-profile d-flex flex-wrap align-items-center gap-15"
                          >
                            <!-- <div class="author-p-thumb">
                                <a href="author.html"><img src="assets/images/seller/02.gif"
                                        alt="author-img "></a>
                            </div> -->
                          </div>
                          <ul class="other-info-list">
                            <li class="item-other-info">
                              <div class="item-info-title">
                                <h6>{{ $t("Pioneer Address") }}</h6>
                              </div>
                              <div class="item-info-details">
                                <!-- <a
                                  class="address"
                                  :href="`https://bscscan.com/address/${pioneerAddress}`"
                                  target="_blank"
                                  >{{pioneerAddress.substr(0,5)}}...{{pioneerAddress.substr(-5,5)}}</a
                                > -->
                                <router-link class="address" :to="'/profile/'+pioneerAddress">{{pioneerAddress.substr(0,5)}}...{{pioneerAddress.substr(-5,5)}}</router-link>
                                <button class="copy-btn" @click="copyAddr(pioneerAddress)">
                                  <i class="icofont-ui-copy copy-icon" aria-hidden="true"></i>
                                </button>
                              </div>
                            </li>
                            <li class="item-other-info">
                              <!-- <div class="author-p-info">
                                        <p class="mb-0">Owner Address</p>
                                        <span><a href="author.html">Yourself</a></span>
                                    </div> -->
                              <div class="item-info-title">
                                <h6>{{ $t("Owner Address") }}</h6>
                              </div>
                              <div class="item-info-details">
                                <!-- <a
                                  class="address"
                                  :href="`https://bscscan.com/address/${nftDetails[0].current_owner}`"
                                  target="_blank"
                                  >{{
                                    nftDetails[0].current_owner.substr(0, 5)
                                  }}...{{
                                    nftDetails[0].current_owner.substr(-5, 5)
                                  }}
                                </a> -->
                                <router-link class="address" :to="'/profile/'+nftDetails[0].current_owner">
                                  {{
                                    nftDetails[0].current_owner.substr(0, 5)
                                  }}...{{
                                    nftDetails[0].current_owner.substr(-5, 5)
                                  }}
                                </router-link>
                                <button class="copy-btn" @click="copyAddr(nftDetails[0].current_owner)">
                                  <i class="icofont-ui-copy copy-icon" aria-hidden="true"></i>
                                </button>
                                <!-- <div id="cryptoCode" class="crypto-page">
                                                            <input class="address-input" id="ownerAddress"
                                                                :value="nftDetails[0].current_owner"
                                                                readonly>
                                                            <div id="cryptoCopy" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Copy Address">
                                                                <span class="copy-icon">
                                                                    <i class="icofont-ui-copy copyBtn" aria-hidden="true" data-clipboard-target="#ownerAddress"
                                                                        data-copytarget="#cryptoLink"></i>
                                                                </span>

                                                            </div>
                                                        </div> -->
                              </div>
                            </li>
                            <li class="item-other-info">
                              <div class="item-info-title">
                                <h6>{{ $t("Contract Address") }}</h6>
                              </div>
                              <div class="item-info-details">
                                   <a
                                  class="address"
                                  :href="`https://bscscan.com/address/${nftDetails[0].contract_address}`"
                                  target="_blank"
                                  >{{
                                    nftDetails[0].contract_address.substr(0, 5)
                                  }}...{{
                                    nftDetails[0].contract_address.substr(-5, 5)
                                  }}
                                </a>
                                <!-- <div id="cryptoCode" class="crypto-page">
                                      <input class="address-input" id="contractAddress"
                                          value="0x273Fd37D7727E7f5584FC568BcB94Af2F416C543"
                                          readonly>
                                      <div id="cryptoCopy" data-bs-toggle="tooltip"
                                          data-bs-placement="top" title="Copy Address">
                                          <span class="copy-icon">
                                              <i class="icofont-ui-copy copyBtn" data-clipboard-target="#contractAddress" aria-hidden="true"
                                              ></i>
                                          </span>

                                      </div>
                                  </div> -->
                              </div>
                            </li>
                            <li class="item-other-info">
                              <div class="item-info-title">
                                <h6>{{ $t("Note") }} #</h6>
                              </div>
                              <div class="item-info-details">
                                <p>{{ nftDetails[0].note }}</p>
                              </div>
                            </li>
                            <li class="item-other-info">
                              <div class="item-info-title">
                                <h6>{{ $t("Token ID") }}</h6>
                              </div>
                              <div class="item-info-details">
                                <p>{{ nftDetails[0].token_id }}</p>
                              </div>
                            </li>
                            <li class="item-other-info">
                              <div class="item-info-title">
                                <h6>{{ $t("Country") }}</h6>
                              </div>
                              <div class="item-info-details">
                                <p>{{ nftDetails[0].country }}</p>
                              </div>
                            </li>

                            <li class="item-other-info">
                              <div class="item-info-title">
                                <h6>{{ $t("Signatures / Vignettes") }}</h6>
                              </div>
                              <div class="item-info-details">
                                <p>- {{ nftDetails[0].signature }}</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </b-tab>
                      <!-- <b-tab :title="$t('Ex Owners')">
                        <div class="owner-container ms-4">
                          <div class="owner-content" v-for="(owner,index) in owners" :key="`owner${index}`">
                              <div class="index">
                                  <span>{{index + 1}}</span>
                              </div>
                              <span>{{ owner.substr(0,4) }} ... {{ owner.substr(-4,4) }}</span>
                          </div>
                        </div>
                          <div class="ms-4" v-if="owners.length == 0">
                            <p>{{$t('No ex owner found')}}</p>
                        </div>
                      </b-tab> -->
                      <!-- <b-tab title="History">
                            <div class="history-tab tab-pane" id="nav-history" role="tabpanel"
                                aria-labelledby="nav-history-tab">
                                <ul class="item-histo-list">
                                    <li class="histo-item">
                                        <p>Created by <a href="author.html">@alex joe</a></p>
                                        <time>2021-08-04 23:05:07</time>
                                    </li>
                                    <li class="histo-item">
                                        <p>Listed by <a href="author.html">@alex joe</a></p>
                                        <time>2021-08-04 20:05:07</time>
                                    </li>
                                    <li class="histo-item">
                                        <p>Owned by <a href="author.html">@alex joe</a></p>
                                        <time>2021-08-04 22:05:07</time>
                                    </li>
                                </ul>
                            </div>
                        </b-tab> -->
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 ps-lg-3 pt-lg-3 items-right-nft">
              <div class="item-buy-part" id="nft-details-part">
                <div class="nft-item-title">
                  <div class="nft-item-title-info">
                    <h3>{{ nftDetails[0].category }}</h3>
                    <p>{{ nftDetails[0].pmg_cert }}</p>
                    <p>{{ nftDetails[0].description }}</p>
                    <div class="nft-item-title-count">
                      <p>
                        <b-icon-eye-fill class="me-2"></b-icon-eye-fill>
                        <span>{{shortenPriceObj(nftDetails[0].view_count).price + shortenPriceObj(nftDetails[0].view_count).suffix + " " + $t('views')}} </span>
                      </p>
                      <p class="ms-3">
                        <b-icon-heart-fill class="me-2"></b-icon-heart-fill>
                        <span>{{nftDetails[0].total_likes+ " " + $t('favorites')}} </span>
                      </p>
                    </div>
                    <h3 v-if="nftDetails[0].serial_number && nftDetails[0].turtle_status == 'banknote'">Serial Number: NM{{nftDetails[0].serial_number.toString().padStart(6,'0')}}</h3>
                  </div>
                  <div class="share-btn">
                    <div class="dropstart">
                      <!-- <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                            aria-expanded="false" data-bs-offset="25,0"> -->
                      <i v-b-modal.shareModal class="icofont-share"></i>
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
                                                <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
                                            </svg> -->
                      <!-- </a> -->

                      <!-- <ul class="dropdown-menu">
                              <li><a class="dropdown-item" href="#"><span>
                                          <i class="icofont-twitter"></i>
                                      </span> Twitter </a>
                              </li>
                              <li><a class="dropdown-item" href="#"><span><i
                                              class="icofont-telegram"></i></span> Telegram</a></li>
                              <li><a class="dropdown-item" href="#"><span><i
                                              class="icofont-envelope"></i></span> Email</a></li>
                          </ul> -->
                    </div>
                  </div>
                </div>
                <div class="buying-btns function-wrappers">
                  <!-- <a class="default-btn move-right" @click="alertNoBanknote"><span>{{ this.nftDetails[0].current_owner.toUpperCase() == this.currentUser.toUpperCase() ? "List Now" : "Offer Now"}}</span> </a> -->
                  <div v-if="nftDetails[0].collection_name != 'Turtle'">
                    <div v-if="!isAuction">
                      <div v-if="isListing" class="item-price">
                        <h4>{{$t("Price")}}</h4>
                        <p>
                          <span>
                            <i class="icofont-coins"></i>
                            {{ formatNumber(nftDetails[0].price, nftDetails[0].symbol) }}
                            {{ nftDetails[0].symbol }}
                          </span>
                          <br>
                          ≈ {{ formatNumber((nftDetails[0].price*1) * (nftDetails[0].currency_price*1), 'USD') }} USD
                        </p>
                      </div>
                      
                      <div class="btn-layout">
                        <button
                          v-if="!isOwner && isListing && connectStatus"
                          class="default-btn move-right me-2 mb-2"
                          @click="confirmBankNoteAction('buy')"
                          ><span>{{ $t("Buy") }}</span>
                        </button>

                        <button
                          v-if="!isOwner && isListing && !connectStatus"
                          class="default-btn move-right me-2 mb-2"
                          v-b-modal="'homeConnectWalletModal'"
                          ><span>{{ $t("Buy") }}</span>
                        </button>

                        <button
                          v-if="!isOwner && !isAuction && !isOfferBefore"
                          class="default-btn move-right"
                          v-b-modal="connectStatus ? 'offerModal' : 'homeConnectWalletModal'"
                          ><span>{{ $t("Offer Now") }}</span>
                        </button>

                        <button
                          v-if="!isOwner && !isAuction && isOfferBefore"
                          class="default-btn move-right btn-disabled"
                          ><span>{{ $t("Offered") }}</span>
                        </button>
                      </div>

                      <div v-if="isOwner && !isAuction && !isListing">
                        <div class="btn-layout">
                          <a class="default-btn move-right me-0 me-lg-3" v-b-modal.listModal
                            ><span>{{ $t("List Now") }}</span>
                          </a>
                          <a
                            :class="`default-btn move-right mt-3 mt-lg-0 ${nftDetails[0].status.toUpperCase() != 'HOLDING' ? 'btn-disabled' : ''}`"
                            v-b-modal.auctionModal
                            :disabled = "nftDetails[0].status.toUpperCase() != 'HOLDING' ? true : false"
                            ><span>{{ $t("Auction") }}</span>
                          </a> 
                        </div>
                        <div class="col-7 col-lg-8 mt-3" v-if="nftDetails[0].status.toUpperCase() != 'HOLDING'">
                            <small class="text-secondary">{{$t('To resume the auction, kindly return the physical banknotes to Numisart.')}}</small>
                        </div>
                      </div>
                      <a
                        v-if="isOwner && isListing"
                        class="default-btn move-right"
                        @click="delistNFT"
                        ><span>{{ $t("Cancel") }}</span>
                      </a>
                      <!-- <a v-if="isOwner" class="default-btn move-right" @click="alertNoBanknote"><span>{{ $t('Place Bid') }}</span> </a> -->
                    </div>

                    <div v-if="isAuction">
                      <!-- auction start -->
                      <div v-if="!isAuctionEnd">
                        <div class="item-details-countdown">
                          <h4>{{$t('Ends In')}}:</h4>
                          <ul
                            class="item-countdown-list count-down"
                            data-date="June 05, 2022 21:14:01"
                          >
                            <li>
                              <span class="days">{{ countDown.day }}</span
                              ><span class="count-txt">{{$t("Days")}}</span>
                            </li>
                            <li>
                              <span class="hours">{{ countDown.hour }}</span
                              ><span class="count-txt">{{$t("Hours")}}</span>
                            </li>
                            <li>
                              <span class="minutes">{{ countDown.minutes }}</span
                              ><span class="count-txt">{{$t("Mins")}}</span>
                            </li>
                            <li>
                              <span class="seconds">{{ countDown.second }}</span
                              ><span class="count-txt">{{$t("Secs")}}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="item-price">
                          <h4>
                            {{$t("Price")}} 
                            <span class="text-theme ms-2" v-if="highestBidder.buyer_address.toLowerCase() == userFullAddress.toLowerCase()">
                              {{$t("Winning")}}
                            </span>
                            <span class="text-theme ms-2" v-else-if="highestBidder.buyer_address.toLowerCase() == '0x0000000000000'">
                              {{$t("Opening Bid")}}
                            </span>
                            <span class="text-theme ms-2" v-else>
                              {{$t("Current Bid")}}
                            </span>
                          </h4>
                          <p v-if="highestBidder.currency">
                            <span>
                              <i class="icofont-coins"></i>
                              {{ formatNumber(highestBidder.amount) }}
                              {{ highestBidder.currency.symbol }}
                            </span>
                            <br>
                            ≈ {{formatNumber(highestBidder.amount * highestBidder.currency.price, 'USD')}} USD
                          </p>
                          <p v-else>
                            <span>
                              <i class="icofont-coins"></i>
                              {{ formatNumber(nftDetails[0].price, nftDetails[0].symbol) }} {{ nftDetails[0].symbol }}
                            </span>
                            <br>
                            ≈ {{ formatNumber((nftDetails[0].price*1) * (nftDetails[0].currency_price*1), 'USD') }} USD
                          </p>
                        </div>
                        <div v-if="!isOwner && highestBidder.buyer_address.toLowerCase() != userFullAddress.toLowerCase() " class="buying-btns d-flex flex-wrap">
                          <button class="default-btn move-right" v-b-modal="connectStatus ? 'bidModal' : 'homeConnectWalletModal'">
                            <span>{{$t("Place a Bid")}}</span>
                          </button>
                        </div>
                      </div>
                      <!-- auction end -->
                      <div v-else>
                        <div class="item-price">
                          <h4>
                            {{$t("Price")}} 
                            <span class="text-theme ms-2" v-if="highestBidder.buyer_address.toLowerCase() == userFullAddress.toLowerCase()">
                              {{$t("Won")}}
                            </span>
                            <span class="text-theme ms-2" v-else-if="highestBidder.buyer_address.toLowerCase() == '0x0000000000000'">
                              {{$t("Passed")}}
                            </span>
                            <span class="text-theme ms-3" v-else>
                              {{$t("Auction Sold")}}
                            </span>
                          </h4>
                          <p v-if="highestBidder.currency">
                            <span>
                              <i class="icofont-coins"></i>
                              {{ formatNumber(highestBidder.amount, highestBidder.currency.symbol) }}
                              {{ highestBidder.currency.symbol }}
                            </span>
                            <br>
                            ≈ {{ formatNumber(highestBidder.amount * highestBidder.currency.price, 'USD')}} USD
                          </p>
                          <p v-else>
                            <span>
                              <i class="icofont-coins"></i>
                              {{ formatNumber(nftDetails[0].price, nftDetails[0].symbol) }} {{ nftDetails[0].symbol }}
                            </span>
                            <br>
                            ≈ {{ formatNumber((nftDetails[0].price*1) * (nftDetails[0].currency_price*1), 'USD') }} USD
                          </p>
                        </div>
                        <!-- Owner -->
                        <div class="btn-layout" v-if="isOwner && highestBidder.buyer_address == '0x0000000000000'">
                            <a class="default-btn move-right ms-sm-3 ms-lg-0 mt-3" v-b-modal.reauctionModal
                              ><span>{{ $t("Reauction") }}</span>
                            </a>
                            <a
                              class="default-btn move-right ms-sm-3 mt-3"
                              @click="ownerClaimBackNFT"
                              ><span>{{ $t("Claim") }}</span>
                            </a>
                        </div>
                        <div class="btn-layout" v-else>
                            <a
                              v-if="highestBidder && highestBidder.buyer_address.toLowerCase() == userFullAddress.toLowerCase()"
                              class="default-btn move-right ms-sm-3 ms-lg-0 mt-3"
                              @click="winnerClaimNFT"
                              ><span>{{ $t("Claim NFT") }}</span>
                            </a>
                            <a
                              v-if=" nftDetails[0].current_owner.toLowerCase() == userFullAddress.toLowerCase()"
                              class="default-btn move-right ms-sm-3 mt-3"
                              @click="winnerClaimNFT"
                              ><span>{{ $t("Claim") }}</span>
                            </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <b-button @click="openReturnModal" class="status-logo">
                    <img
                      :src="
                        nftDetails &&
                        nftDetails[0].status.toUpperCase() == 'HOLDING'
                          ? statusIcons['holding']
                          : statusIcons['returned']
                      "
                      alt="status-icon"
                    />
                    <div class="desc">
                      {{
                        nftDetails[0].status.toUpperCase() == "HOLDING"
                          ? $t("Physical Banknote with Numisart")
                          : $t("Physical Banknote with owner")
                      }}
                    </div>
                    <span v-if="nftDetails[0].status.toUpperCase() == 'HOLDING' && isOwner" class="status-remark">{{$t('Click to request return')}}</span>
                  </b-button>
                </div>
              </div>

              <!-- Price History Start -->
              <div class="content-collapse">
                <b-button v-b-toggle.collapse-priceHistory class="m-1 content-collapse-header">
                  {{$t('Price History')}}
                  <b-icon-chevron-down></b-icon-chevron-down>
                </b-button>
                <b-collapse :visible="priceHistory.length > 0" id="collapse-priceHistory">
                  <div :class="`offer item-buy-part content-collapse-body ${priceHistory.length > 0 ? 'align-items-start' : 'align-items-center'}`">
                    <!-- <div class="nft-item-title" v-b-toggle.collapse-price-history>
                        <h3>{{ $t("Price History") }}</h3>
                    </div> -->
                    <div>
                      <div v-if="priceHistory.length > 0" class="table-responsive">
                        <apexchart type="line" :options="chartOptions" :series="series" :width="windowScreenSize > 1200 ? '550':'350'"></apexchart>
                      </div>
                      <div v-else class="d-flex flex-column justify-content-center align-items-center">
                        <img src="../assets/images/item-empty/no-activity.png" width="150px" height="150px" alt="shibafarm-no-listings">
                        <p class="mt-2"><b>{{ $t("No price history yet") }}</b></p>
                      </div>
                    </div> 
                  </div>  
                </b-collapse>
              </div>

              <!-- <div class="offer item-buy-part mt-3" id="price-history-part">
                <div class="nft-item-title" v-b-toggle.collapse-price-history>
                    <h3>{{ $t("Price History") }}</h3>
                </div>
                <div>
                  <div v-if="priceHistory.length > 0" class="table-responsive">
                  </div>
                  <div v-else class="d-flex flex-column justify-content-center align-items-center">
                    <img src="../assets/images/item-empty/no-activity.png" width="150px" height="150px" alt="shibafarm-no-listings">
                    <p class="mt-2"><b>{{ $t("No price history yet") }}</b></p>
                  </div>
                </div> 
              </div>   -->
              <!-- Price History End -->

              <!-- Offer Details Start -->
              <div class="content-collapse">
                <b-button v-b-toggle.collapse-offers class="m-1 content-collapse-header">
                  {{$t('Offers')}}
                  <b-icon-chevron-down></b-icon-chevron-down>
                </b-button>
                <b-collapse class="offer-wrapper" :visible="offers.length > 0" id="collapse-offers">
                    <div class="offer-warning">
                      <span>{{ $t('Please double check the offer price before accept') }}</span>
                    </div>
                    <div :class="`offer item-buy-part content-collapse-body ${offers.length > 0 ? 'align-items-start' : 'align-items-center'}`" id="offer-details" @scroll="onScrollOffers">
                      <div v-if="offers.length > 0" class="table-responsive w-100">
                          <table class="table text-center">
                              <thead>
                                  <tr>
                                      <th>{{ $t("Price") }}</th>
                                      <!-- <th>{{ $t("Expiration") }}</th>    -->
                                      <th>{{ $t("From") }}</th>
                                      <!-- <th></th> -->
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="offer in offers" :key="offer.id">
                                      <td>
                                        <div class="d-flex flex-column">
                                          <div class="coin-amount"><img :src="offer.currency.image" width="20px" height="20px">{{formatNumber(offer.amount, offer.symbol)}} {{offer.symbol}}</div>
                                          <div class="latest-usdPrice"> ≈ {{formatNumber(offer.amount * offer.currency.price, 'USD')}} USD</div>
                                        </div>
                                      </td>
                                      <!-- <td>{{ formatDate(offer.expiry_date) }}</td> -->
                                      <td>
                                        <div class="offer-td-div">
                                          {{ offer.buyer_address.substr(0,4) }} ... {{ offer.buyer_address.substr(-4,4) }}

                                          <button class="btn btn-danger btn-offer-table" @click="cancelOffer(offer.offer_id)"
                                          v-if="userFullAddress && userFullAddress.toLowerCase() == offer.buyer_address.toLowerCase()">
                                            {{$t("Cancel")}}
                                          </button>

                                          <button class="btn btn-primary btn-offer-table" @click="acceptOffer(offer.offer_id, offer.offer_nft_status)"
                                          v-else-if="!isListing && userFullAddress && userFullAddress.toLowerCase() == nftDetails[0].current_owner.toLowerCase()"
                                          >
                                            {{$t('Accept')}}
                                          </button>

                                          <span v-else></span>
                                        </div>
                                      </td>
                                      <!-- <td v-if="userFullAddress && userFullAddress.toLowerCase() == offer.buyer_address.toLowerCase()">
                                          <button class="btn btn-danger" @click="cancelOffer(offer.offer_id)">Cancel</button>
                                      </td>
                                      <td v-else-if="!isListing && userFullAddress && userFullAddress.toLowerCase() == nftDetails[0].current_owner.toLowerCase()">
                                          <button class="btn btn-primary" @click="acceptOffer(offer.offer_id)">Accept</button>
                                      </td>
                                      <td v-else></td> -->
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div v-else class="d-flex flex-column justify-content-center align-items-center">
                          <img src="../assets/images/item-empty/no-activity.png" width="150px" height="150px" alt="shibafarm-no-listings">
                          <p class="mt-2"><b>{{ $t("Nothing offers yet") }}</b></p>
                      </div> 
                  </div>  
                </b-collapse>
              </div>
              <!-- Offer Details End -->

              <!-- Latest Auction Start -->
              <div class="content-collapse">
                <b-button v-b-toggle.collapse-latestAuction class="m-1 content-collapse-header">
                  {{$t('Latest Auction')}}
                  <b-icon-chevron-down></b-icon-chevron-down>
                </b-button>
                <b-collapse :visible="latestAuctions.length > 0" id="collapse-latestAuction">
                  <div :class="`offer item-buy-part content-collapse-body ${latestAuctions.length > 0 ? 'align-items-start' : 'align-items-center'}`" id="latest-auction" @scroll="onScrollAuctions">
                    <div v-if="latestAuctions.length > 0" class="table-responsive w-100">
                      <table class="table text-center">
                        <thead>
                          <tr>
                            <th>{{ $t("Price") }}</th>
                            <!-- <th>{{ $t("USD Price") }}</th> -->
                            <!-- <th>{{ $t("Expiration") }}</th>    -->
                            <th>{{ $t("From") }}</th>
                            <!-- <th></th> -->
                          </tr>
                        </thead>
                        <tbody v-for="(item,index) in latestAuctions" :key="'latestAuction'+item.id">
                          <tr :class="index == 0 ? 'text-theme' : ''">
                            <td>
                              <div class="d-flex flex-column">
                                <div class="coin-amount"><img :src="item.currency_image" width="20px" height="20px">{{formatNumber(item.amount, item.symbol)}}</div>
                                <div class="latest-usdPrice"> ≈ {{formatNumber(item.amount * item.currency_price, 'USD')}} USD</div>
                              </div>
                            </td>
                            <!-- <td>${{formatNumber(item.amount * item.currency_price)}}</td> -->
                            <td>
                              {{item.buyer_address.substr(0,4)}}...{{item.buyer_address.substr(-4,4)}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-else class="d-flex flex-column justify-content-center align-items-center">
                        <img src="../assets/images/item-empty/no-activity.png" width="150px" height="150px" alt="shibafarm-no-listings">
                        <p class="mt-2"><b>{{ $t("Nothing auction yet") }}</b></p>
                    </div> 
                  </div>  
                </b-collapse>
              </div>

              <!-- <div class="offer item-buy-part mt-3" id="latest-auction" @scroll="onScrollAuctions">
                <div class="nft-item-title">
                    <h3>{{ $t("Latest Auction") }}</h3>
                </div>
                <div v-if="latestAuctions.length > 0" class="table-responsive">
                  <table class="table text-center">
                    <thead>
                      <tr>
                        <th>{{ $t("Price") }}</th>
                        <th>{{ $t("From") }}</th>
                      </tr>
                    </thead>
                    <tbody v-for="(item,index) in latestAuctions" :key="'latestAuction'+item.id">
                      <tr :class="index == 0 ? 'text-theme' : ''">
                        <td>
                          <div class="d-flex flex-column">
                            <div class="coin-amount"><img :src="item.currency_image" width="20px" height="20px">{{formatNumber(item.amount, item.symbol)}}</div>
                            <div class="latest-usdPrice"> ≈ {{formatNumber(item.amount * item.currency_price, 'USD')}} USD</div>
                          </div>
                        </td>
                        <td>
                          {{item.buyer_address.substr(0,4)}}...{{item.buyer_address.substr(-4,4)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else class="d-flex flex-column justify-content-center align-items-center">
                    <img src="../assets/images/item-empty/no-activity.png" width="150px" height="150px" alt="shibafarm-no-listings">
                    <p class="mt-2"><b>{{ $t("Nothing auction yet") }}</b></p>
                </div> 
              </div>   -->
              <!-- Latest Auction End -->
            </div>
          </div>

          <!-- Activity Start -->
          <div class="col-12 items-activity-part">
            <div class="item-buy-part mt-3" id="activity-div">
              <div class="nft-item-title">
                <h3>{{ $t("Activity") }}</h3>
              </div>

              <!-- filter activity start -->
              <div v-b-toggle.activity class="filter-activity" @click="sidebarToggleClick">
                <div>{{$t("Filter")}}</div>
                <div><b-icon :icon="sidebarToggle ? 'chevron-up' : 'chevron-down'"></b-icon></div>
              </div>

              <b-collapse id="activity" class="mt-2 position-relative">
                <b-card id="filter-activity-content">
                  <div class="form-check" v-for="(r, index) in remarks" :key="index">
                    <input class="form-check-input" type="checkbox" v-model="selectedRemark" :key="'remark'+index" :id="'remark'+index" :value="r">
                    <label class="form-check-label" :for="'remark'+index">
                      {{$t(event[r])}}
                    </label>
                  </div>
                </b-card>
              </b-collapse>
              
              <div class="selected-content">
                <div class="selected-content-box" v-for="selected in selectedRemark" :key="selected">
                  <div class="selected-text">{{selected}}</div>
                  <div><button class="remove-selected" @click="removeSelectedRemark(selected)">x</button></div>
                </div>

                <button v-if="selectedRemark.length > 0" @click="clearFilterRemark" class="btn-clearAll">{{$t("Clear All")}}</button>
              </div>
              <!-- filter activity end -->
              
              <!-- <div v-if="activities.length > 0" class="table-responsive" id="activity-table" @scroll="onScrollActivities"> -->
              <div v-if="activities.length > 0" class="table-responsive" :id="selectedRemark.length != 0 ? 'activity-table-selected': 'activity-table'" @scroll="onScrollActivities">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th>{{ $t("Event") }}</th>
                      <th>{{ $t("Price") }}</th>
                      <th>{{ $t("From") }}</th>   
                      <th>{{ $t("To") }}</th>
                      <th>{{ $t("Date") }}</th>
                    </tr>
                  </thead>
                  <tbody v-for="activity in activities" :key="'activity'+activity.id">
                    <tr>
                      <td class="event">
                        <img class="me-2" width="30px" height="30px" :src="lightMode && lightMode == 'light' ? eventIconsDark[activity.remarks] : eventIcons[activity.remarks]" alt="">
                        <span>{{$t( event[activity.remarks] )}}</span>
                      </td>
                      <td class="coin-amount"><img :src="activity.currency.image" width="20px" height="20px"> <span>{{formatNumber(activity.amount, activity.currency.symbol)}} {{activity.currency.symbol}}</span></td>
                      <!-- <td>{{activity.from.substr(0,4)}}...{{activity.from.substr(-4,4)}}</td> -->
                      <td>{{activity.from == "Marketplace" ? activity.from : `${activity.from.substr(0,4)}...${activity.from.substr(-4,4)}`}}</td>
                      <td>{{activity.to == "Marketplace" ? activity.to : `${activity.to.substr(0,4)}...${activity.to.substr(-4,4)}`}}</td>
                      <td>{{ formatDate(activity.created_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="d-flex flex-column justify-content-center align-items-center">
                <img src="../assets/images/item-empty/no-activity.png" width="150px" height="150px" alt="shibafarm-no-listings">
                <p class="mt-2"><b>{{ $t("Nothing activity yet") }}</b></p>
              </div> 
            </div>  
          </div>
          <!-- Activity End -->
        </div>
      <!-- </div> -->
    </div>
    <!-- ==========Item Details Section ends Here========== -->

    <!-- Prompt for Details Modal Start-->
    <b-modal
      id="returnBanknoteModal"
      centered
      :content-class="mode"
      :title="$t('Apply for Physical Banknote Return')"
      @ok="submitShippingDetails"
    >
      <div class="input-wrapper">
        <label for="returnReceiver">{{ $t("Receiver's name") }}: </label>
        <input
          v-model="returnReceiver"
          type="text"
          name="returnReceiver"
          id="returnReceiver"
        />
      </div>
      <div class="input-wrapper">
        <label for="returnContactNo">{{ $t("Contact No") }}: </label>
        <vue-phone-number-input
          default-country-code="MY"
          dark
          id="returnContactNo"
          v-model="returnPhoneNo"
        ></vue-phone-number-input>
      </div>
      <div class="input-wrapper">
        <label for="returnAddress">{{ $t("Receiver's address") }}: </label>
        <textarea
          v-model="returnAddress"
          type="text"
          name="returnAddress"
          id="returnAddress"
        />
      </div>
      <p>
        {{
          $t(
            "Postage fees is to be beared by user and Numisart is not held responsible for any loss or damage during the process of shipment"
          )
        }}
      </p>
    </b-modal>
    <!-- Prompt for Details Modal End-->

    <!-- share modal start -->
    <b-modal :content-class="mode" id="shareModal" :title="$t('Share NFT')" centered hide-footer>
      <div class="text-center">
        <figure class="qrcode">
          <vue-qrcode
            :value="`https://numisart.io/nft/${this.$route.params.id}`"
            :options="{
              errorCorrectionLevel: 'Q',
              width: 200,
              color: {
                dark: '#F9A300',
                light: '#111213',
              },
            }"
          ></vue-qrcode>
          <img
            class="qrcode__image"
            :src="require('../assets/images/numisart-logo-white.png')"
            alt="Chen Fengyuan"
          />
        </figure>

        <p class="d-flex align-items-center justify-content-center mt-3">
          <span id="shareLink"
            >{{ `https://numisart.io/nft/${this.$route.params.id}` }}
          </span>
          <button
            class="copyBtn btn"
            data-clipboard-target="#shareLink"
          >
            <i class="icofont-ui-copy" aria-hidden="true"></i>
          </button>
        </p>
      </div>
    </b-modal>
    <!-- share modal end -->

    <!-- list modal start -->
    <b-modal
      @ok="handleListOk"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      :content-class="mode"
      cancel-variant="danger"
      id="listModal"
      :title="$t('List NFT')"
    >
      <form ref="listForm" @submit.stop.prevent="listNFT">
        <b-form-group
          id="input-group-1"
          :label="$t('Listing By')"
          label-for="input-1"
          :state="listState"
        >
          <!-- <b-form-select
            class="form-control"
            v-model="listForm.selectedToken"
            :options="tokens"
            required
          ></b-form-select> -->
          <select class="form-control" v-model="listForm.selectedToken">
            <option v-for="token in tokens" :key="token.id" :value="token.contract_address">{{token.symbol}}</option>
          </select>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="$t('Price')"
          label-for="input-2"
          :invalid-feedback="$t('Listing price is required')"
        >
          <input
            class="form-control"
            v-model="listForm.price"
            :placeholder="$t('Please input a price')"
            required
            type="number"
            step="0.001"
          />
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="$t('Realized Gain : ( Rebate included )')"
          label-for="input-2"
          :invalid-feedback="$t('Realized Gain is required')"
        >
          <input
            class="form-control"
            v-model="listForm.gain"
            :placeholder="$t('Please input a realized gain')"
            required
            type="number"
            step="0.001"
          />
        </b-form-group>

        <p>{{$t('** All NFT sale, 2.5% to NumisArt, 2.5% to Third-party Grader, 3.5% to Agent Fee, 5% to Pioneer Rebate and 2.5% to Ex-owner')}}</p>
      </form>
    </b-modal>
    <!-- list modal end -->

    <!-- auction modal start -->
    <b-modal
      :content-class="mode"
      @ok="handleAuctionOk"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      cancel-variant="danger"
      id="auctionModal"
      :title="$t('Auction NFT')"
      v-if="isWithNumisart"
    >
      <form ref="auctionForm" @submit.stop.prevent="auction">
        <b-form-group
          id="input-group-1"
          :label="$t('Auction By')"
          label-for="input-1"
        >
          <!-- <b-form-select
            class="form-control"
            v-model="auctionForm.selectedToken"
            :options="tokens"
            required
          ></b-form-select> -->
          <select class="form-control" v-model="auctionForm.selectedToken">
            <option v-for="token in tokens" :key="token.id" :value="token.contract_address">{{token.symbol}}</option>
          </select>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="$t('Starting Bid')"
          label-for="input-2"
          :invalid-feedback="$t('Starting bid is required')"
          :state="auctionState"
        >
          <small> <b-icon-info-circle-fill></b-icon-info-circle-fill> {{$t("Starting bid is your selling price")}}</small>
          <input
            class="form-control"
            v-model="auctionForm.price"
            :placeholder="$t('Please input a starting bid')"
            required
          >
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="$t('Realized Gain : ( Rebate included )')"
          label-for="input-2"
          :invalid-feedback="$t('Realized Gain is required')"
        >
          <input
            class="form-control"
            v-model="auctionForm.gain"
            :placeholder="$t('Please input a realized gain')"
            required
            type="number"
            step="0.001"
          />
        </b-form-group>

        <b-form-group
          id="input-group-3"
          :label="$t('Bid increment')"
          label-for="input-3"
          :invalid-feedback="$t('Bid increment is required')"
          :state="auctionState"
        >
          <input
            class="form-control"
            v-model="auctionForm.minBid"
            :placeholder="$t('Please input a bid increment')"
            required
          >
        </b-form-group>

        <b-form-group
          id="input-group-4"
          :label="$t('Duration (Days)')"
          label-for="input-4"
        >
          <!-- <b-form-input
            v-model="auctionForm.day"
            placeholder="Please input a end day"
            required
          ></b-form-input> -->
          <select class="form-control" v-model="auctionForm.day">
            <option v-for="day in auctionEndDay" :key="day" :value="day">{{day}}</option>
          </select>
        </b-form-group>
      </form>
    </b-modal>
    <!-- auction modal end -->

    <!-- bid modal start -->
    <b-modal
      :content-class="mode"
      v-if="isAuction"
      @ok="handleBidOk"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      cancel-variant="danger"
      id="bidModal"
      :title="$t('Place a bid')"
    >
    <form ref="bidForm">
      <div class="bid-input">
          <div class="col-2">
            <b-icon-triangle-fill rotate="-90"  @click="decreaseBidPrice"></b-icon-triangle-fill>
          </div>

          <div class="col-8 d-flex justify-content-center align-items-center">
            <img class="me-2" width="35px" height="35px" :src="nftDetails[0].currency_image" alt="">
            <p class="price mb-0 me-2"><span>{{formatNumber(bidForm.price, nftDetails[0].symbol)}}</span> <span class="bid-symbol">{{ nftDetails[0].symbol }}</span></p>
          </div>

          <div class="col-2 text-end">
            <b-icon-triangle-fill rotate="90" @click="increaseBidPirce"></b-icon-triangle-fill>
          </div>
      </div>
    </form>
    <div slot="modal-footer" class="w-100 d-flex justify-content-center">
        <drag-verify 
          :width="300"
          :height="30"
          :text="'swipe to bid'"
          :progressBarBg="'#F9A300'"
          @passcallback="bidNFT">
        </drag-verify>
    </div>
    </b-modal>
    <!-- bid modal end -->

    <!-- offer modal start -->
    <b-modal
      @ok="handleOfferOk"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      cancel-variant="danger"
      :content-class="mode"
      ref="offerModal"
      id="offerModal"
      :title="$t('Offer now')"
    >
      <form ref="offerForm">
        <b-form-group
          id="input-group-1"
          :label="$t('Offer By')"
          label-for="input-1"
        >
          <!-- <b-form-select
            class="form-control"
            v-model="offerForm.selectedToken"
            :options="tokens"
            required
          ></b-form-select> -->
          <select class="form-control" v-model="offerForm.selectedToken">
            <option v-for="token in tokens" :key="token.id" :value="token.contract_address">{{token.symbol}}</option>
          </select>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="$t('Price')"
          label-for="input-2"
          :invalid-feedback="$t('Price is required')"
          :state="offerState"
        >
          <input
            class="form-control"
            v-model="offerForm.price"
            :placeholder="$t('Please input a offer price')"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
    <!-- offer modal end -->

    <!-- reauction modal start -->
    <b-modal
      :content-class="mode"
      @ok="handleReauctionOk"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      cancel-variant="danger"
      id="reauctionModal"
      :title="$t('Reauction NFT')"
    >
      <form ref="auctionForm">
        <!-- <b-form-group
          id="input-group-1"
          label="Auction By"
          label-for="input-1"
        >
          <select class="form-control" v-model="auctionForm.selectedToken">
            <option v-for="token in tokens" :key="token.id" :value="token.contract_address">{{token.symbol}}</option>
          </select>
        </b-form-group> -->

        <b-form-group
          id="input-group-2"
          :label="$t('Starting Bid')"
          label-for="input-2"
          :invalid-feedback="$t('Starting bid is required')"
          :state="auctionState"
        >
          <input
            class="form-control"
            v-model="auctionForm.price"
            :placeholder="$t('Please input a starting bid')"
            required
          >
        </b-form-group>

        <b-form-group
          id="input-group-3"
          :label="$t('Bid increment')"
          label-for="input-3"
          :invalid-feedback="$t('Bid increment is required')"
          :state="auctionState"
        >
          <input
            class="form-control"
            v-model="auctionForm.minBid"
            :placeholder="$t('Please input a bid increment')"
            required
          >
        </b-form-group>

        <b-form-group
          id="input-group-4"
          :label="$t('Duration (Days)')"
          label-for="input-4"
        >
          <!-- <b-form-input
            v-model="auctionForm.day"
            placeholder="Please input a end day"
            required
          ></b-form-input> -->
          <select class="form-control" v-model="auctionForm.day">
            <option v-for="day in auctionEndDay" :key="day" :value="day">{{day}}</option>
          </select>
        </b-form-group>
      </form>
    </b-modal>
    <!-- reauction modal end -->

    <!-- confirm with wallet modal start -->
    <b-modal
      :content-class="mode"
      @ok.prevent="confirmActionWithWallet"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      cancel-variant="danger"
      ref="confirmWithWalletModal"
      id="confirmWithWalletModal"
      :title="$t('Confirmation')"
    >
      <p>{{$t("Insufficient pool balance.Contract will direct interact with your wallet funds.Please confirm.")}}</p>
    </b-modal>
    <!-- confirm with wallet modal end -->

    <!-- connect wallet modal start -->
    <b-modal
      id="connectWalletModal"
      @ok.prevent="connectWallet"
      :ok-title="$t('Connect')"
      :cancel-title="$t('Cancel')"
      cancel-variant="danger"
      :title="$t('Connect Wallet')"
    >
      <p>{{ $t("Connect with your wallet to continue the action") }}</p>
    </b-modal>
    <!-- connect wallet modal end -->

    <!-- confirm bank note modal start -->
    <b-modal
      :content-class="mode"
      id="confirmBankNoteModal"
      ref="confirmBankNoteModal"
      @ok.prevent="handleConfirmBankNoteOk"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      cancel-variant="danger"
      :title="$t('FAIR WARNING')"
      v-if="nftDetails.length > 0"
    >
      <p>#{{nftDetails[0].pmg_cert}} {{ $t("NFT physical Banknote is not with NumisArt. To proceed with this deal, please deal with the Banknote owner directly after the purchase. Unfortunately, as the physical Banknote is not with NumisArt, any complications that arise will not be held responsible by NumisArt") }}</p>
    </b-modal>
    <!-- confirm bank note modal end -->
    
  </div>
</template>

<script>
import "../assets/css/bootstrap.min.css";
import "../assets/css/icofont.min.css";
import "../assets/css/lightcase.css";
import "../assets/css/animate.css";
import "../assets/css/swiper-bundle.min.css";
import "../assets/css/style.min.css";
// import '../assets/css/icofont.min.css';
import "../assets/css/animate.css";
import axios from "axios";
import { ethers } from "ethers";
import moment from "moment";
// import '../assets/js/functions.js';
import ClipboardJS from "clipboard";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import nftAbi from "../../abi/nft.json";
import tokenAbi from "../../abi/token.json";
import mysteryAbi from "../abi/mysteryMintAbi.json";
// import marketplaceAbi from "../abi/marketplace.json";
const marketplaceAbi = require("@/abi/marketplace.json");
const STRAT_ABI = require("@/abi/poolStratAbi.json");
const numeral = require("numeral");
import dragVerify from 'vue-drag-verify';

const config = {
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem("access_token"),
  },
}

export default {
  components: { VueQrcode,dragVerify },
  data() {
    return {
      mode:'',
      nftTokenAbi:{
        '0xc98A921148f0EFa6f2C07af3fBAa76c83974A24f':nftAbi,
        '0xBe1F97f67B47E2bAA70d120e87b99910b1219Fe0':mysteryAbi
      },
      interval:'',
      isOwner: false,
      isListing: false,
      isAuction: false,
      isAuctionEnd:false,
      isWithNumisart:false,
      isLoading: true,
      isOfferBefore:false,
      statusIcons: {
        holding: require("../assets/images/status-green.png"),
        returned: require("../assets/images/status-red.png"),
      },
      flipIcons: {
        white: require("../assets/images/flip2.png"),
        black: require("../assets/images/flip.png"),
      },
      banknoteStatus: "",
      pioneerAddress:"",
      nftDetails: [
      //   {
      //   amount: "10",
      //   auction_id: null,
      //   category: "Sarawak Banknotes",
      //   comment: "",
      //   contract_address: "0xBe1F97f67B47E2bAA70d120e87b99910b1219Fe0",
      //   country: "SWK",
      //   currency_address: null,
      //   currency_price: null,
      //   current_owner: "0x45277ec4f5718620ce65665E888724E377778327",
      //   description: "Sarawak, Government of Sarawak, 10 Dollars KNB22a 1929",
      //   expiry_date: "2022-06-20T09:21:04.000Z",
      //   grade: "VF 30",
      //   image: null,
      //   is_claimed: 1,
      //   listing_id: null,
      //   min_price: null,
      //   nft_id: 7,
      //   nft_status: "auction",
      //   note: "SWK16",
      //   pioneer_address: "0x75177cbE06cB603aE87d659Abe4179b0DFcc168C",
      //   pmg_cert: "1189992-032",
      //   price: "0",
      //   real_image_back: "https://numisartgallery.sgp1.digitaloceanspaces.com/Back/a0bc5d1f-ece3-4599-ba4e-6f9e9a2d14f1_1189992-032_back.jpg",
      //   real_image_front: "https://numisartgallery.sgp1.digitaloceanspaces.com/Front/a0bc5d1f-ece3-4599-ba4e-6f9e9a2d14f1_1189992-032_front.jpg",
      //   serial: "C/1 198066",
      //   signature: "- Printer: BWC",
      //   status: "holding",
      //   symbol: "USDT",
      //   token_id: 7,
      //   total_likes: 1,
      //   type: "Paper Money",
      //   view_count: 0,
      //   year: "1929",
      // }
      ],
      isback: false,
      currentUser: localStorage.getItem("address"),
      returnReceiver: null,
      returnPhoneNo: null,
      returnAddress: null,
      highestBidder: {
        buyer_address : "0x0000000000000",
        currency: null
      },
      marketplaceAddress: process.env.VUE_APP_MARKETPLACE_ADDRESS,
      nftAddress: process.env.VUE_APP_NFT_ADDRESS,
      tokens: [],
      offers:[],
      listForm: {
        // selectedToken: "0x734778123652973B37053c1b3CeD4256b2FEdbCb", //staging
        selectedToken: "0x55d398326f99059fF775485246999027B3197955",
        price: null,
        gain: null
      },
      auctionForm: {
        // selectedToken: "0x734778123652973B37053c1b3CeD4256b2FEdbCb", //staging
        selectedToken: "0x55d398326f99059fF775485246999027B3197955",
        price: null,
        minBid: null,
        day: 1,
      },
      bidForm: {
        price: null,
        symbol: null
      }, 
      offerForm:{
        // selectedToken:"0x734778123652973B37053c1b3CeD4256b2FEdbCb", //staging
        selectedToken: "0x55d398326f99059fF775485246999027B3197955",
        price:null
      },
      countDown: {
        day: 0,
        hour: 0,
        minutes: 0,
        second: 0,
      },
      listState:null,
      auctionState:null,
      offerState:null,
      bidState:null,
      auctionEndDay:[],
      bnbPoolAddress: process.env.VUE_APP_BNB_STRAT_ADDRESS,
      usdtPoolAddress: process.env.VUE_APP_USDT_STRAT_ADDRESS,
      activities:[],
      confirmAction:'',
      userFullAddress:'sample',
      latestAuctions:[],
      selectedRemark: [],
      priceHistory: [],
      remarks: [],
      sidebarToggle: false,
      pageSize:10,
      currentPage:1,
      totalAuction: 0,
      totalOffers: 0,
      totalActivities: 0,
      poolAddress: {
        // staging
        '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c':process.env.VUE_APP_BNB_STRAT_ADDRESS, //bnb
        '0x55d398326f99059fF775485246999027B3197955':process.env.VUE_APP_USDT_STRAT_ADDRESS, //usdt
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56':process.env.VUE_APP_BUSD_STRAT_ADDRESS, //busd
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d':process.env.VUE_APP_USDC_STRAT_ADDRESS, //usdc
        '0x2170Ed0880ac9A755fd29B2688956BD959F933F8':process.env.VUE_APP_WETH_STRAT_ADDRESS, //weth
      },
      owners:[],
      event:{
        "direct sold":this.$t("Sold"),
        "offer accepted":this.$t("Offer Accepted"),
        "sold to highest bidder":this.$t("Auction Sold"),
        "start auction":this.$t("Start Auction"),
        "start listing":this.$t("Listing"),
        "delisted":this.$t("Delist")
      },
      eventIcons: {
        "direct sold":require('../assets/images/icons/buy-light.png'),
        "offer accepted":require('../assets/images/icons/buy-light.png'),
        "sold to highest bidder":require('../assets/images/icons/buy-light.png'),
        "start auction":require('../assets/images/icons/auction-light.png'),
        "start listing":require('../assets/images/icons/buy-light.png'),
        "delisted":require('../assets/images/icons/buy-light.png')
      },
      eventIconsDark: {
        "direct sold":require('../assets/images/icons/buy.png'),
        "offer accepted":require('../assets/images/icons/buy.png'),
        "sold to highest bidder":require('../assets/images/icons/buy.png'),
        "start auction":require('../assets/images/icons/auction.png'),
        "start listing":require('../assets/images/icons/buy.png'),
        "delisted":require('../assets/images/icons/buy.png')
      },
      confirmBankNoteState:"",
      chartOptions: {
        chart: {
          id: 'vuechart-example',
          width: '50%',
          height: '100%',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
          labels:{
            style: {
              colors: 'black',
            },
          }
        },
        yaxis: {
          labels:{
            style: {
              colors: 'black',
            },
          }
        },
        colors: ['#F9A300', '#F9A300', '#F9A300'], // line color
      },
      series: [{
        name: 'Price (USD)',
        data: [30, 40, 35, 50, 49, 60, 70, 91]
      }],
      windowScreenSize: window.innerWidth,

    };
  },
  props: ["userAddress", "signer", "provider",'fullAddress','connectStatus', "lightMode"],
  created() {
    if (this.statusIcons) {
      this.banknoteStatus = this.statusIcons["with_company"];
    }
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    selectedRemark(){
      this.fetchActivity();
    },
    'listForm.price':function(newVal){
      let pioneerFee = 0;

      if(this.pioneerAddress.toUpperCase() == this.userFullAddress.toUpperCase()){
        pioneerFee = 0.05;
        console.log('is pionner');
      }

      let exownerFee = 0.025;

      let finalFee = 0.16 - exownerFee - pioneerFee;
      console.log(finalFee);

      let gain = newVal*(1 - finalFee);

      this.listForm.gain = Math.round(gain * 1000)/1000;
    },
    
    'listForm.gain':function(newVal){
      let pioneerFee = 0

      if(this.pioneerAddress.toUpperCase() == this.userFullAddress.toUpperCase()){
        pioneerFee = 0.05;
      }

      let exownerFee = 0.025;

      let finalFee = 0.16 - exownerFee - pioneerFee;

      let price = newVal/(1 - finalFee);

      this.listForm.price = Math.round(price * 1000) / 1000;
    },

    'auctionForm.price':function(newVal){
      let pioneerFee = 0;

      if(this.pioneerAddress.toUpperCase() == this.userFullAddress.toUpperCase()){
        pioneerFee = 0.05;
        console.log('is pionner');
      }

      let exownerFee = 0.025;

      let finalFee = 0.16 - exownerFee - pioneerFee;
      console.log(finalFee);

      let gain = newVal*(1 - finalFee);

      this.auctionForm.gain = Math.round(gain * 1000)/1000;
    },
    
    'auctionForm.gain':function(newVal){
      let pioneerFee = 0

      if(this.pioneerAddress.toUpperCase() == this.userFullAddress.toUpperCase()){
        pioneerFee = 0.05;
      }

      let exownerFee = 0.025;

      let finalFee = 0.16 - exownerFee - pioneerFee;

      let price = newVal/(1 - finalFee);

      this.auctionForm.price = Math.round(price * 1000) / 1000;
    },
  },
  mounted() {
    this.mode = this.lightMode+'mode'; 
    this.$parent.$on('changeMode', this.changeMode);

    console.log('modeeeeee',this.lightMode);
    document.body.style.backgroundImage = "none";
    window.scrollTo(0, 0);

    if(this.currentUser){
      this.userFullAddress = this.currentUser;
    }

    console.log(this.userFullAddress);

    let filter = {
        address: this.marketplaceAddress,
        topics: [
          '0xd21fbaad97462831ad0c216f300fefb33a10b03bb18bb70ed668562e88d15d53'   
        ]
    }
    this.provider.on(filter, () => {
        // console.log(log);
        this.fetchHighestBidder();
        this.fetchLatestAuction();
    })
    
    let clipboard = "";
    clipboard = new ClipboardJS(".copyBtn");

    clipboard.on("success", function (e) {
      // alert('Copied');
      e.clearSelection();
    });
    
    this.isLoading = false;
    this.addViewCount();
    this.fetchNFT();
    this.fetchRemarks();
  },
  methods: {
    copyAddr (addr) {
      var TempText = document.createElement("input");
      TempText.value = addr;
      document.body.appendChild(TempText);
      TempText.select();
      TempText.setSelectionRange(0, 99999); /* For mobile devices */
      
      document.execCommand("copy");
      this.$swal('',this.$t(`Copied ${addr}`),'success');

      document.body.removeChild(TempText);
      
      // alert("Copied the text: " + TempText.value);
    },
    changeMode(mode){
      this.mode = `${mode}mode`;
      this.chartOptions = {
        xaxis: {
          categories : this.priceHistory.map((item)=>moment(item.created_at).format('MM/DD')),
          labels:{
            style: {
              colors: mode == 'light' ? 'black' : 'white',
              
            },
          }
        },
        yaxis: {
          labels:{
            style: {
              colors: mode == 'light' ? 'black' : 'white',
            },
          }
        },
      };
      
    },
    onResize() {
      this.windowScreenSize = window.innerWidth;
      console.log(this.windowScreenSize);
    },

    onScrollAuctions(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      if ((scrollTop + offsetHeight) >= scrollHeight) {
        console.log('bottom!')
        if (this.latestAuctions.length < this.totalAuction) {
          this.loadMoreAuctions()
        }
      }
    },

    onScrollOffers(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      if ((scrollTop + offsetHeight) >= scrollHeight) {
        console.log('bottom!')
        if (this.offers.length < this.totalOffers) {
          this.loadMoreOffers()
        }
      }
    },

    onScrollActivities(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      console.log((scrollTop + offsetHeight))
      console.log(scrollHeight)
      if ((scrollTop + offsetHeight + 50) >= scrollHeight) {
        console.log('bottom!')
        if (this.activities.length < this.totalActivities) {
          this.loadMoreActivities()
        }
      }
    },

    sidebarToggleClick(){
      this.sidebarToggle = !this.sidebarToggle
      // this.$set(this.sidebarToggle, index, this.sidebarToggle)
    },

    fetchNFT(){
      const baseUrl = process.env.VUE_APP_API;
      axios
      .get(`${baseUrl}/pmg/id/${this.$route.params.id}`)
      .then(async (res) => {
        this.nftDetails = res.data;
        this.fetchTokens();
        this.fetchActivity();

        let userAddress = this.currentUser ? this.currentUser.toUpperCase() : ''

        if(this.nftDetails[0].status.toUpperCase() == 'HOLDING'){
          this.isWithNumisart = true;
        }

        if( res.data[0].current_owner.toUpperCase() == userAddress) {
          this.isOwner = true;
          let endDayArr = [];
          for(let i=1; i<8; i++){
            endDayArr.push(i)
          }
          this.auctionEndDay = endDayArr;
        }

        if (res.data[0].nft_status == "listing") {
          this.isListing = true;
        }

        if (res.data[0].nft_status == "auction") {

          if(moment(res.data[0].expiry_date).format('x') < moment().format('x')){
            this.isAuctionEnd = true;
          }

          this.isAuction = true;

          let expiredTime = moment(this.nftDetails[0].expiry_date).format("x");
          let now = moment(). format("x");
          let timeLeft = expiredTime - now;

          if(timeLeft > 0){
            this.auctionCountDown();
          }else{
            // console.log('will clear interval')
            clearInterval(this.interval);
          }

          this.fetchHighestBidder();
          this.fetchLatestAuction();
    
        } else {
          this.fetchOfferList();
          this.fetchLastRoundAction();
        }

        this.fetchPriceHistory();
        // this.fetchOwners();
        this.fetchPioneerAddress();
      });

    },

    addViewCount(){
      const baseUrl = process.env.VUE_APP_API;
      const id = this.$route.params.id;

      axios.post(`${baseUrl}/nft/addViewerCount/${id}`,{},config)
            .then(response=>{
              console.log(response);
            })
            .catch(error=>{
              console.log(error);
            });

    },
    
    async fetchPioneerAddress(){
      const marketPlaceContract = new ethers.Contract(
          this.marketplaceAddress,
          marketplaceAbi,
          this.provider
      );
      const pioneerDetail = await marketPlaceContract.pioneerDetail(this.nftDetails[0].token_id, this.marketplaceAddress);
      if(pioneerDetail != '0x0000000000000000000000000000000000000000'){
        this.pioneerAddress = pioneerDetail;
      }else{
        this.pioneerAddress = this.nftDetails[0].pioneer_address
      }
        
    },

    async fetchOwners(){
      // const signer = await this.provider.getSigner();
      const marketPlaceContract = new ethers.Contract(
          this.marketplaceAddress,
          marketplaceAbi,
          this.provider
      );

      const owners = await marketPlaceContract.nftProfitDetail(this.nftDetails[0].token_id, this.nftDetails[0].contract_address);
      // console.log(owners);
      this.owners = owners[1].filter((item)=>item != '0x0000000000000000000000000000000000000000');
    },

    auctionCountDown() {
      this.interval = setInterval(() => {
        let expiredTime = moment(this.nftDetails[0].expiry_date).format("x");
        let now = moment().format("x");
        let timeLeft = expiredTime - now;
        if(timeLeft < 0){
          this.fetchNFT();
        }

        this.countDown.day = moment.duration(timeLeft).days();
        this.countDown.hour = moment.duration(timeLeft).hours();
        this.countDown.minutes = moment.duration(timeLeft).minutes();
        this.countDown.second = moment.duration(timeLeft).seconds();
      }, 1000);
    },

    checkFormValidity(form) {
      let valid;
      switch(form){
        case "list":
          valid = true;
          this.listState = valid;
        break;

        case "offer":
          valid = this.$refs.offerForm.checkValidity()
          this.offerState = valid;
        break;

        case "auction":
          valid = this.$refs.auctionForm.checkValidity()
          this.auctionState = valid;
        break;

        case "bid":
          valid = this.$refs.bidForm.checkValidity()
          this.bidState = valid;
        break;
      }
      return valid
    },

    handleListOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      // Exit when the form isn't valid
      if (!this.checkFormValidity("list")) {
        return
      }

      // Trigger submit handler
      this.listNFT();
    },

    handleAuctionOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      // Exit when the form isn't valid
      if (!this.checkFormValidity("auction")) {
        return
      }

      // Trigger submit handler
      this.auctionNFT();
    },

    handleBidOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      // Exit when the form isn't valid
      if (!this.checkFormValidity("bid")) {
        return
      }

      // Trigger submit handler
      this.bidNFT();
    },

    handleOfferOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      
      // Exit when the form isn't valid
      if (!this.checkFormValidity("offer")) {
        return
      }

      this.$nextTick(() => {      
        this.$bvModal.hide('offerModal')
      })

      // Trigger submit handler
      this.confirmBankNoteAction('offer');
    },

    handleReauctionOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      
      // Exit when the form isn't valid
      if (!this.checkFormValidity("auction")) {
        return
      }

      // Trigger submit handler
      // this.offerNFT();
      this.reauctionNFT();
    },

    handleConfirmBankNoteOk(){
      // Prevent modal from closing
      // bvModalEvent.preventDefault();

      // Trigger submit handler
      if(this.confirmBankNoteState == "buy"){
        this.buyNFT();
      }else{
        this.offerNFT();
      }
    },

    confirmBankNoteAction(action){
      this.confirmBankNoteState = action;

      if(this.isWithNumisart){

        if(action == "buy"){
          this.confirmBankNoteState = "buy"
          this.buyNFT();
        }else{
          this.confirmBankNoteState = "offer";
          this.offerNFT();
        }

      }else{
        this.$bvModal.show("confirmBankNoteModal");
      }
    },

    closeFullPage() {
      const fullPage = document.querySelector("#fullpage");
      fullPage.style.display = "none";
    },

    viewFullpage() {
      // console.log(this.isback);
      const fullPage = document.querySelector("#fullpage");
      let img = "";
      if (this.isback) {
        img = document.querySelector(".flip-card-back img");
      } else {
        img = document.querySelector(".flip-card-front img");
      }

      fullPage.style.backgroundImage = "url(" + img.src + ")";
      fullPage.style.display = "block";
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    openReturnModal() {
      if (
        this.nftDetails[0].status.toUpperCase() == "HOLDING" &&
        this.nftDetails[0].current_owner.toUpperCase() == this.currentUser.toUpperCase() &&
        this.nftDetails[0].nft_status.toUpperCase() == "PASSIVE"
      ) {
        const baseUrl = process.env.VUE_APP_API;
        axios.get(`${baseUrl}/user/${this.currentUser}`).then((res) => {
          this.returnReceiver = res.data.real_name;
          this.returnContactNo = res.data.phone_number;
          this.returnAddress = res.data.shipping_address;
        });
        // console.log(
        //   this.returnReceiver,
        //   this.returnContactNo,
        //   this.returnAddress
        // );
        this.$bvModal.show("returnBanknoteModal");
      }
    },

    alertNoBanknote() {
      if (
        this.nftDetails[0].status.toUpperCase() == "RETURNED" &&
        !(
          this.nftDetails[0].current_owner.toUpperCase() ==
          this.currentUser.toUpperCase()
        )
      ) {
        this.$swal
          .fire({
            title: this.$t("Disclaimer"),
            text: this.$t("NFT physical banknote is not with Numisart. Please deal with the banknote owner directly after the NFT purchase. Numisart will not be held responsible for this deal."),
            // icon: 'warning',
            confirmButtonColor: "#ffa409",
            confirmButtonText: this.$t("Proceed"),
          })
          .then((result) => {
            if (result.isConfirmed) {
            //   this.buyNFT();
            }
          });
      } else {
        // this.buyNFT();
      }
    },

    async submitShippingDetails() {
      let id = this.$route.params.id;
      const baseUrl = process.env.VUE_APP_API;
      const signer = await this.provider.getSigner();
      // const userAddress = await signer.getAddress();

      // const approved = await this.getApproved(signer, userAddress);
      // if (!approved) {
      //   return false;
      // }

      // marketplace contract
      const marketPlaceContract = new ethers.Contract(
        this.marketplaceAddress,
        marketplaceAbi,
        signer
      );

      const updateNFTStatus = await marketPlaceContract.userUpdatePMG(this.nftDetails[0].contract_address, this.nftDetails[0].token_id);
      this.$swal.fire({
        title: this.$t("Processing"),
        allowOutsideClick: false,
        icon: "info",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      // check listing transaction
      let txReceipt = await this.provider.waitForTransaction(updateNFTStatus.hash);
      if (!txReceipt) {
        this.$swal(this.t("Error"), this.t("Hash not found"), "error");
        return false;
      }

      this.$swal.fire({
        title: this.$t("Success"),
        icon: "success",
      });

      axios
        .post(
          `${baseUrl}/user/return/banknote`,
          {
            wallet_address:this.currentUser,
            nft_id:id,
            phone_number:this.returnPhoneNo,
            real_name:this.returnReceiver,
            shipping_address:this.returnAddress
          }, config
        )
        .then(() => {
          this.$swal
            .fire({
              icon: "success",
              text: this.$t("Submitted"),
            })
            .then(() => {
              axios.get(`${baseUrl}/pmg/id/${id}`).then((res) => {
                this.nftDetails = res.data;
                // console.log("this.nftDetails", this.nftDetails);
              });
            });
        });
    },

    async buyNFT() {
      try {
        // check balance
        const signer = await this.provider.getSigner();
        const marketPlaceContract = new ethers.Contract(
            this.marketplaceAddress,
            marketplaceAbi,
            signer
        );
        let buyNFT;

        if(this.nftDetails[0].currency_address == '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'){
          const available = await this.fetchPoolAvailable(this.poolAddress[this.nftDetails[0].currency_address]); 

          if(this.nftDetails[0].price*1 > available){
            // this.$refs['confirmWithWalletModal'].show();
            this.$nextTick(() => {      
              this.$bvModal.hide('confirmBankNoteModal')
            })
            this.$bvModal.show('confirmWithWalletModal')
            this.confirmAction = 'buy';
            return;
          }

          buyNFT = await marketPlaceContract.buyNFT(this.nftDetails[0].listing_id, {value: 0 });
        }else{
          const available = await this.fetchPoolAvailable(this.poolAddress[this.nftDetails[0].currency_address]); 

          if(this.nftDetails[0].price*1 > available){
            // this.$refs['confirmWithWalletModal'].show();
            this.$nextTick(() => {      
              this.$bvModal.hide('confirmBankNoteModal')
            })
            this.$bvModal.show('confirmWithWalletModal')
            this.confirmAction = 'buy';
            return;
          }

          const userAddress = await signer.getAddress();
          const tokenContract = new ethers.Contract(this.nftDetails[0].currency_address, tokenAbi, signer);

          const balance = await tokenContract.balanceOf(userAddress);
          const formatedBalance = ethers.utils.formatEther(balance);

          if(formatedBalance < this.nftDetails[0].price * 1){
              this.$swal(this.$t('Error'),this.$t('Infsufficient funds'),'error');
              return false;
          }

          const allowance = await this.getAllowance(signer, this.nftDetails[0].currency_address);
          if (!allowance) {
              return false;
          }

          buyNFT = await marketPlaceContract.buyNFT(this.nftDetails[0].listing_id) 
        }
        
        this.$swal.fire({
            title: this.$t("Buying NFT"),
            allowOutsideClick: false,
            icon: "info",
            didOpen: () => {
            this.$swal.showLoading();
            },
        });

        // check listing transaction
        let txReceipt = await this.provider.waitForTransaction(buyNFT.hash);
        if (!txReceipt) {
            this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
            return false;
        }
        this.$swal.fire({
            title: this.$t("Success"),
            icon: "success",
        });

        window.location.reload();
      } catch(error) {
        this.$swal(this.$t("Error"), error , "error");
      }
    },

    async listNFT() {
      try {
        const signer = await this.provider.getSigner();
        const userAddress = await signer.getAddress();

        const approved = await this.getApproved(signer, userAddress);
        if (!approved) {
          return false;
        }

        // listing nft to marketplace
        const marketPlaceContract = new ethers.Contract(
          this.marketplaceAddress,
          marketplaceAbi,
          signer
        );

        const list = await marketPlaceContract.listNFT(
          this.nftDetails[0].contract_address,
          this.listForm.selectedToken,
          this.nftDetails[0].token_id,
          ethers.utils.parseEther(this.listForm.price.toString())
        );
        this.$swal.fire({
          title:this.$t("NFT Listing"),
          allowOutsideClick: false,
          icon: "info",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        // check listing transaction
        let txReceipt = await this.provider.waitForTransaction(list.hash);
        if (!txReceipt) {
          this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
          return false;
        }
        this.$swal.fire({
          title: this.$t("NFT Listed"),
          icon: "success",
        });

        window.location.reload();
      } catch(error) {
        console.log(error);
        this.$swal("Error", error.data.message , "error");
      }
    },

    async delistNFT() {
      const signer = await this.provider.getSigner();

      // auction nft
      const marketPlaceContract = new ethers.Contract(
        this.marketplaceAddress,
        marketplaceAbi,
        signer
      );
      const auction = await marketPlaceContract.delistNFT(
        this.nftDetails[0].listing_id
      );
      this.$swal.fire({
        title: this.$t("Delisting"),
        allowOutsideClick: false,
        icon: "info",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      // check listing transaction
      let txReceipt = await this.provider.waitForTransaction(auction.hash);
      if (!txReceipt) {
        this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
        return false;
      }
      this.$swal.fire({
        title: this.t("Success"),
        icon: "success",
      });

      window.location.reload();
    },

    async auctionNFT() {
      try {
        const signer = await this.provider.getSigner();
        const userAddress = await signer.getAddress();

        const approved = await this.getApproved(signer, userAddress);
        if (!approved) {
          return false;
        }

        // auction nft
        const marketPlaceContract = new ethers.Contract(
          this.marketplaceAddress,
          marketplaceAbi,
          signer
        );
        // console.log(this.auctionForm.selectedToken);
        const auction = await marketPlaceContract.auctionNFT(
          this.nftDetails[0].contract_address,
          this.auctionForm.selectedToken,
          this.nftDetails[0].token_id,
          ethers.utils.parseEther(this.auctionForm.price.toString()),
          ethers.utils.parseEther(this.auctionForm.minBid.toString()),
          this.auctionForm.day
        );
        this.$swal.fire({
          title: this.$t("NFT uploading to auction"),
          allowOutsideClick: false,
          icon: "info",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        // check listing transaction
        let txReceipt = await this.provider.waitForTransaction(auction.hash);
        if (!txReceipt) {
          this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
          return false;
        }
        this.$swal.fire({
          title: this.$t("NFT auction in progress"),
          icon: "success",
        });

        window.location.reload();
      } catch(error) {
        this.$swal(this.$t("Error"), error , "error");
      }
    },

    async reauctionNFT() {
      const signer = await this.provider.getSigner();
      const userAddress = await signer.getAddress();

      const approved = await this.getApproved(signer, userAddress);
      if (!approved) {
        return false;
      }

      // auction nft
      const marketPlaceContract = new ethers.Contract(
        this.marketplaceAddress,
        marketplaceAbi,
        signer
      );

      const auction = await marketPlaceContract.auctionFail(
        this.nftDetails[0].auction_id,
        1,
        ethers.utils.parseEther(this.auctionForm.price.toString()),
        ethers.utils.parseEther(this.auctionForm.minBid.toString()),
        this.auctionForm.day,
        this.nftDetails[0].currency_address
      );

      this.$swal.fire({
        title: this.$t("Processing"),
        allowOutsideClick: false,
        icon: "info",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      // check listing transaction
      let txReceipt = await this.provider.waitForTransaction(auction.hash);
      if (!txReceipt) {
        this.$swal(this.$t("Error"),this.$t("Hash not found"), "error");
        return false;
      }
      
      this.$swal.fire({
        title: this.$t("Success"),
        icon: "success",
      });

      window.location.reload();
    },

    async bidNFT() {
      try {
        const signer = await this.provider.getSigner();

        // bid nft
        const marketPlaceContract = new ethers.Contract(
          this.marketplaceAddress,
          marketplaceAbi,
          signer
        );

        let bidNFT;

        if(this.nftDetails[0].currency_address == '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'){
          const available = await this.fetchPoolAvailable(this.poolAddress[this.nftDetails[0].currency_address]); 
          // console.log({'bidPrice':this.bidForm.price, 'available':available});

          if(this.bidForm.price > available){
            // this.$refs['confirmWithWalletModal'].show();
            this.$bvModal.show('confirmWithWalletModal')
            this.confirmAction = 'bid';
            return;
          }

          bidNFT = await marketPlaceContract.bidNFT(
            this.nftDetails[0].auction_id,
            ethers.utils.parseEther(this.bidForm.price.toString()),
            { value: 0 }
          );
        } else {
          const available = await this.fetchPoolAvailable(this.poolAddress[this.nftDetails[0].currency_address]); 

          if(this.bidForm.price > available){
            // this.$refs['confirmWithWalletModal'].show();
            this.$bvModal.show('confirmWithWalletModal')
            this.confirmAction = 'bid';
            return;
          }

          const userAddress = await signer.getAddress();
          const tokenContract = new ethers.Contract(this.nftDetails[0].currency_address, tokenAbi, signer);

          const balance = await tokenContract.balanceOf(userAddress);
          const formatedBalance = ethers.utils.formatEther(balance);
          if(formatedBalance < this.bidForm.price){
              this.$swal(this.$t('Error'),this.$t('Infsufficient funds'),'error');
              return false;
          }

          const allowance = await this.getAllowance(signer, this.nftDetails[0].currency_address);
          if (!allowance) {
              return false;
          }
          bidNFT = await marketPlaceContract.bidNFT(
            this.nftDetails[0].auction_id,
            ethers.utils.parseEther(this.bidForm.price.toString())
          );
        }

        this.$swal.fire({
          title:this.$t("Bidding"),
          allowOutsideClick: false,
          icon: "info",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        // check listing transaction
        let txReceipt = await this.provider.waitForTransaction(bidNFT.hash);
        if (!txReceipt) {
          this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
          return false;
        }
        this.$swal.fire({
          title: this.$t("Bided"),
          icon: "success",
        });

        window.location.reload();
      //   this.fetchHighestBidder();
      } catch(error) {
        console.error(error)
        this.$swal("Error", error.data.message , "error");
      }
    },

    async ownerClaimBackNFT(){
      // console.log(this.nftDetails[0].auction_id);
      const signer = await this.provider.getSigner();
      const userAddress = await signer.getAddress();

      const approved = await this.getApproved(signer, userAddress);
      if (!approved) {
        return false;
      }

      // auction nft
      const marketPlaceContract = new ethers.Contract(
        this.marketplaceAddress,
        marketplaceAbi,
        signer
      );
      const auction = await marketPlaceContract.auctionFail(
        this.nftDetails[0].auction_id,
        '0',
        ethers.utils.parseEther('1'),
        ethers.utils.parseEther('1'),
        '1',
        this.nftDetails[0].currency_address,
      );
      this.$swal.fire({
        title: this.$t("Claim"),
        allowOutsideClick: false,
        icon: "info",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      // check listing transaction
      let txReceipt = await this.provider.waitForTransaction(auction.hash);
      if (!txReceipt) {
        this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
        return false;
      }
      this.$swal.fire({
        title: this.$t("Success"),
        icon: "success",
      });

      window.location.reload();
    },

    async winnerClaimNFT(){
      const signer = await this.provider.getSigner();

      // bid nft
      const marketPlaceContract = new ethers.Contract(
        this.marketplaceAddress,
        marketplaceAbi,
        signer
      );

      const bidNFT = await marketPlaceContract.claimAuctionNFT(
        this.nftDetails[0].auction_id,
      );
      
      this.$swal.fire({
        title: this.$t("Claim"),
        allowOutsideClick: false,
        icon: "info",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      // check listing transaction
      let txReceipt = await this.provider.waitForTransaction(bidNFT.hash);
      if (!txReceipt) {
        this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
        return false;
      }
      this.$swal.fire({
        title: this.$t("Success"),
        icon: "success",
      });

      window.location.reload();
    },

    async offerNFT(){
      try{
        const signer = await this.provider.getSigner();

        const marketPlaceContract = new ethers.Contract(
            this.marketplaceAddress,
            marketplaceAbi,
            signer
        );

        let offerNFT;
          
        try{
          // use BNB
          if(this.offerForm.selectedToken == '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'){
            const available = await this.fetchPoolAvailable(this.poolAddress[this.offerForm.selectedToken]); 
            if(this.offerForm.price > available){
              this.$bvModal.hide('offerModal')
              this.$nextTick(() => {      
                this.$bvModal.hide('confirmBankNoteModal');
              })
              this.$bvModal.show('confirmWithWalletModal')
              this.confirmAction = 'offer';
              return;
            }

            offerNFT = await marketPlaceContract.offerNFT(
                this.offerForm.selectedToken,
                this.nftDetails[0].contract_address,
                this.nftDetails[0].token_id,
                ethers.utils.parseEther(this.offerForm.price.toString()),
                this.nftDetails[0].status.toUpperCase() == "HOLDING",
                { value: 0 }
            );
          } else{
            // use Token
            const available = await this.fetchPoolAvailable(this.poolAddress[this.offerForm.selectedToken]); 

            if(this.offerForm.price > available){
              this.$bvModal.hide('offerModal')
              this.$nextTick(() => {      
                this.$bvModal.hide('confirmBankNoteModal');
              })
              this.$bvModal.show('confirmWithWalletModal')
              this.confirmAction = 'offer';
              return;
            }

            const signer = await this.provider.getSigner();
            const userAddress = await signer.getAddress();
            const tokenContract = new ethers.Contract(this.offerForm.selectedToken, tokenAbi, signer);

            const balance = await tokenContract.balanceOf(userAddress);
            const formatedBalance = ethers.utils.formatEther(balance);
            if(formatedBalance < this.offerForm.price){
                this.$swal(this.$t('Error'),this.$t('Insufficient funds'),'error');
                return false;
            }

            const allowance = await this.getAllowance(signer, this.offerForm.selectedToken);
            if (!allowance) {
                return false;
            }

            this.$swal.fire({
                title:this.$t("Connecting"),
                allowOutsideClick: false,
                icon: "info",
                didOpen: () => {
                this.$swal.showLoading();
                },
            });

            offerNFT = await marketPlaceContract.offerNFT(
                this.offerForm.selectedToken,
                this.nftDetails[0].contract_address,
                this.nftDetails[0].token_id,
                ethers.utils.parseEther(this.offerForm.price.toString()),
                this.nftDetails[0].status.toUpperCase() == "HOLDING"
            );
          }
          
          this.$swal.fire({
              title: this.$t("Offering"),
              allowOutsideClick: false,
              icon: "info",
              didOpen: () => {
              this.$swal.showLoading();
              },
          });

          // check listing transaction-
          let txReceipt = await this.provider.waitForTransaction(offerNFT.hash);
          if (!txReceipt) {
              this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
              return false;
          }

          this.$swal.fire({
              title: this.$t("Offered"),
              icon: "success",
          });

          window.location.reload();

        }catch(error){
          console.error(error);

          this.$swal(this.$t("Error"), error.data.message , "error");
          // this.$swal("Error", "Please cancel the previous offer for this nft", "error");
        }
      } catch(error) {
        this.$swal("Error", error , "error");
      }
    },

    async cancelOffer(offerId){
        const signer = await this.provider.getSigner();

        const marketPlaceContract = new ethers.Contract(
            this.marketplaceAddress,
            marketplaceAbi,
            signer
        );

        try{
          const offerNFT = await marketPlaceContract.cancelOfferAndClaim(
             offerId
          );

          this.$swal.fire({
              title: this.$t("Cancelling"),
              allowOutsideClick: false,
              icon: "info",
              didOpen: () => {
              this.$swal.showLoading();
              },
          });

          // check listing transaction
          let txReceipt = await this.provider.waitForTransaction(offerNFT.hash);
          if (!txReceipt) {
              this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
              return false;
          }
          this.$swal.fire({
              title: this.$t("Offer cancelled"),
              icon: "success",
          });

          window.location.reload();
          
        }catch(error){
          this.$swal("Error", error.data.message , "error");
        }
    },

    async acceptOffer(offerId,offerStatus){
      console.log(offerId, this.marketplaceAddress);
      // console.log('offerId: ',offerId);
        if(offerStatus == "green" && this.nftDetails[0].status.toUpperCase() != 'HOLDING'){
          this.$swal.fire({
              title: this.$t("Failed"),
              text:this.$t("Kindly return the physical banknote to NumisArt in order to accept the offer.This offer was made when the physical banknote is with NumisArt."),
              icon: "error"
          });

          return false;
        }
      
        const signer = await this.provider.getSigner();
        const userAddress = await signer.getAddress();

        const marketPlaceContract = new ethers.Contract(
            this.marketplaceAddress,
            marketplaceAbi,
            signer
        );

        const approved = await this.getApproved(signer, userAddress);
        if (!approved) {
          return false;
        }

        try{
          const offerNFT = await marketPlaceContract.acceptOffer(
             offerId
          );

          this.$swal.fire({
              title:this.$t("Accepting offer"),
              allowOutsideClick: false,
              icon: "info",
              didOpen: () => {
              this.$swal.showLoading();
              },
          });

          // check listing transaction
          let txReceipt = await this.provider.waitForTransaction(offerNFT.hash);
          if (!txReceipt) {
              this.$swal(this.$t("Error"),this.$t("Hash not found"), "error");
              return false;
          }
          this.$swal.fire({
              title: this.$t("Offer accepted"),
              icon: "success",
          });

          window.location.reload();
          
        }catch(error){
          // console.log(error);
          this.$swal(this.$t("Error"), error.data.message , "error");
          // this.$swal("Error", "Please try again later", "error");
        }
    },

    async confirmActionWithWallet(){
      const signer = await this.provider.getSigner();
      const userAddress = await signer.getAddress();
      const marketPlaceContract = new ethers.Contract(
        this.marketplaceAddress,
        marketplaceAbi,
        signer
      );

      let action;
      let pendingMsg;
      let successMsg;

      try {
        switch(this.confirmAction){
          case 'bid':

            if(this.nftDetails[0].symbol =='BNB') {
              action = await marketPlaceContract.bidNFT(
                this.nftDetails[0].auction_id,
                ethers.utils.parseEther(this.bidForm.price.toString()),
                { value: ethers.utils.parseEther(this.bidForm.price.toString()) }
              ); 
            } else {
              const userAddress = await signer.getAddress();
              const tokenContract = new ethers.Contract(this.nftDetails[0].currency_address, tokenAbi, signer);

              const balance = await tokenContract.balanceOf(userAddress);
              const formatedBalance = ethers.utils.formatEther(balance);
              if(formatedBalance < this.bidForm.price){
                  this.$swal(this.$t('Error'),this.$t('Infsufficient funds'),'error');
                  return false;
              }


              const allowance = await this.getAllowance(signer, this.nftDetails[0].currency_address);
              if (!allowance) {
                  return false;
              }

              action = await marketPlaceContract.bidNFT(
                this.nftDetails[0].auction_id,
                ethers.utils.parseEther(this.bidForm.price.toString())
              ); 
            }
          
            pendingMsg = this.$t("Bidding");
            successMsg = this.$t("Bided");                         
          break;

          case 'offer':
            if(this.offerForm.selectedToken == '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c') {
              action = await marketPlaceContract.offerNFT(
                this.offerForm.selectedToken,
                this.nftDetails[0].contract_address,
                this.nftDetails[0].token_id,
                ethers.utils.parseEther(this.offerForm.price.toString()),
                this.nftDetails[0].status.toUpperCase() == "HOLDING",
                { value: ethers.utils.parseEther(this.offerForm.price.toString()) }
              );
            } else {
              const tokenContract = new ethers.Contract(this.offerForm.selectedToken, tokenAbi, signer);

              const balance = await tokenContract.balanceOf(userAddress);
              const formatedBalance = ethers.utils.formatEther(balance);
              if(formatedBalance < this.offerForm.price * 1){
                  this.$swal(this.$t('Error'),this.$t('Insufficient funds'),'error');
                  return false;
              }

              const allowance = await this.getAllowance(signer, this.offerForm.selectedToken,);
              if (!allowance) {
                  return false;
              }

              console.log(this.nftDetails[0].status.toUpperCase() == "HOLDING");
              
              action = await marketPlaceContract.offerNFT(
                this.offerForm.selectedToken,
                this.nftDetails[0].contract_address,
                this.nftDetails[0].token_id,
                ethers.utils.parseEther(this.offerForm.price.toString()),
                this.nftDetails[0].status.toUpperCase() == "HOLDING"
              );
            }
            
            pendingMsg = this.$t("Offering");
            successMsg = this.$t("Offered");
          break;

          case 'buy':
            if(this.nftDetails[0].symbol =='BNB') {
              action = await marketPlaceContract.buyNFT(
                this.nftDetails[0].listing_id, 
                {value: ethers.utils.parseEther(this.nftDetails[0].price) }
              );
            } else {
              
              const userAddress = await signer.getAddress();
              const tokenContract = new ethers.Contract(this.nftDetails[0].currency_address, tokenAbi, signer);

              const balance = await tokenContract.balanceOf(userAddress);
              const formatedBalance = ethers.utils.formatEther(balance);
              
              if(formatedBalance < this.nftDetails[0].price * 1){
                  this.$swal(this.$t('Error'),this.$t('Infsufficient funds'),'error');
                  return false;
              }

              const allowance = await this.getAllowance(signer, this.nftDetails[0].currency_address);
              if (!allowance) {
                  return false;
              }
              
              action = await marketPlaceContract.buyNFT(
                this.nftDetails[0].listing_id, 
              );
            }
            
            pendingMsg = this.$t("Buying NFT");
            successMsg = this.$t("Success");
          break;
        }

        this.$swal.fire({
          title: pendingMsg,
          allowOutsideClick: false,
          icon: "info",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        // check listing transaction
        let txReceipt = await this.provider.waitForTransaction(action.hash);
        if (!txReceipt) {
          this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
          return false;
        }
        
        this.$swal.fire({
          title: successMsg,
          icon: "success",
        });

        window.location.reload();
      } catch (error) {
        console.error('js log error',error);
        this.$swal("Error", error.data.message, "error");
      }
    },

    async getApproved(signer, userAddress) {
      // get approved
      const nftContract = new ethers.Contract(
        this.nftDetails[0].contract_address,
        this.nftTokenAbi[this.nftDetails[0].contract_address],
        signer
      );
      // console.log(this.marketplaceAddress);
      const approved = await nftContract.isApprovedForAll(
        userAddress,
        this.marketplaceAddress
      );
      if (!approved) {
        // approve nft
        const approveNft = await nftContract.setApprovalForAll(
          this.marketplaceAddress,
          true
        );
        this.$swal.fire({
          title: this.$t("NFT Approving"),
          icon: "info",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        // check approve transaction on block chain
        let txReceipt = await this.provider.waitForTransaction(approveNft.hash);
        if (!txReceipt) {
          this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
          return false;
        }
        this.$swal.fire({
          title: this.$t("NFT Approved"),
          icon: this.$t("success"),
        });
      }
      return true;
    },

    async getAllowance(signer, tokenAddress) {
      const userAddress = await signer.getAddress();

      const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
      const allowance = await tokenContract.allowance(
        userAddress,
        this.marketplaceAddress
      );
      const formatedAllowance = ethers.utils.formatEther(allowance);
      // console.log(formatedAllowance);
      if (formatedAllowance == 0) {
        // update user allowance to max
        const approveAllowance = await tokenContract.approve(
          this.marketplaceAddress,
          ethers.BigNumber.from(
            "115792089237316195423570985008687907853269984665640564039457584007913129639935"
          )
        );
        this.$swal.fire({
          title: this.$t("Approving Allowance"),
          icon: "info",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        const txReceipt = await this.provider.waitForTransaction(
          approveAllowance.hash
        );
        if (!txReceipt) {
          this.$swal(this.$t("Error"), this.$t("Hash not found"), "error");
          return false;
        }
        this.$swal.fire({
          title: this.$t("Allowance Approved"),
          icon: "success",
        });
      }

      return true;
    },


    async fetchPriceHistory() {
      const baseUrl = process.env.VUE_APP_API;
      try {
        let priceHistory = await axios.get(
          `${baseUrl}/sales/priceHistory/${this.nftDetails[0].nft_id}`
        );
        this.priceHistory = priceHistory.data;
        this.series[0].data = this.priceHistory.map((item)=>numeral(item.in_usd).format('0,0.00'));
        this.chartOptions.xaxis.categories = this.priceHistory.map((item)=>moment(item.created_at).format('MM/DD'))
      } catch (error) {
        console.log(error);
      }
    },

    async fetchHighestBidder() {
      const baseUrl = process.env.VUE_APP_API;
      try {
        let highestBidder = await axios.get(
          `${baseUrl}/bidding/highestBidder/${this.nftDetails[0].auction_id}`
        );
        this.highestBidder = highestBidder.data;
        this.bidForm.price = this.roundDownNumber(this.highestBidder.amount * 1 + this.nftDetails[0].min_price * 1);
        
      } catch (error) {
        this.bidForm.price = this.roundDownNumber(this.nftDetails[0].price * 1 + this.nftDetails[0].min_price * 1);
      }
    },

    async fetchOfferList() {
      const baseUrl = process.env.VUE_APP_API;
      try {
        let offers = await axios.get(
          `${baseUrl}/offer/all_offer/${this.nftDetails[0].nft_id}`, {
            params: {
              pageSize:this.pageSize,
              page:this.currentPage,
            }
          }
        );
        // console.log(offers);
        this.offers = offers.data.results;
        this.totalOffers = offers.data.metadata.total
        
        if(this.offers.length > 0){
          let previousOffer = this.offers.filter((item)=>item.buyer_address.toUpperCase() == this.userFullAddress.toUpperCase());
          this.isOfferBefore = previousOffer.length > 0 ? true : false;
        }
      } catch (error) {
        // console.log(error);
      }
    },

    async fetchTokens() {
      const baseUrl = process.env.VUE_APP_API;
      try {
        let tokens = await axios.get(
          `${baseUrl}/currency`
        );
        this.tokens = tokens.data;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchUserBNBAvailable(){
        let bnbPoolContract = new ethers.Contract(this.bnbPoolAddress, STRAT_ABI, this.provider);
        let bnbPoolBal = await bnbPoolContract.userInfos(this.userFullAddress)
        let formatBal = (parseFloat(bnbPoolBal.want.toString()) / 1e18) - (parseFloat(bnbPoolBal.wantFreeze.toString()) / 1e18);

        return formatBal;
    },

    async fetchUserUSDTAvailable() {
        let usdtPoolContract = new ethers.Contract(this.usdtPoolAddress, STRAT_ABI, this.provider);
        let usdtPoolBal = await usdtPoolContract.userInfos(this.userFullAddress)
        let formatBal = (parseFloat(usdtPoolBal.want.toString()) / 1e18) - (parseFloat(usdtPoolBal.wantFreeze.toString()) / 1e18);

        return formatBal;
    },

    async fetchPoolAvailable(pool_address){
        let poolContract = new ethers.Contract(pool_address, STRAT_ABI, this.provider);
        let poolBal = await poolContract.userInfos(this.userFullAddress)
        let formatBal = (parseFloat(poolBal.want.toString()) / 1e18) - (parseFloat(poolBal.wantFreeze.toString()) / 1e18);

        return formatBal;
    },

    async fetchActivity() {
      const baseUrl = process.env.VUE_APP_API;
      try {
        let activities = await axios.get(
          `${baseUrl}/sales/nftActivity/${this.nftDetails[0].nft_id}`, {
            params: {
              pageSize:10,
              page:1,
              remarks: this.selectedRemark.length > 0 ? JSON.parse(JSON.stringify(this.selectedRemark)) : ''
            }
          }
        );
        this.activities = activities.data.results;
        this.totalActivities = activities.data.metadata.total;
        console.log(activities);
      } catch (error) {
        // console.log(error);
      }
    },

    async fetchLatestAuction(){
      const baseUrl = process.env.VUE_APP_API;
      try {
        let latestAuctions = await axios.get(
          `${baseUrl}/bidding/bidHistory/${this.nftDetails[0].auction_id}`, {
            params: {
              pageSize:this.pageSize,
              page:this.currentPage,
            }
          }
        );
        this.latestAuctions = latestAuctions.data.results;
        this.totalAuction = latestAuctions.data.metadata.total
      } catch (error) {
        // console.log(error);
      }
    },

    async fetchLastRoundAction(){
      const baseUrl = process.env.VUE_APP_API;
      try {
        let latestAuctions = await axios.get(
          `${baseUrl}/auction/history/highestBidder`,{params:{
          pageSize:this.pageSize,
          page:this.currentPage,
          nft_id:this.nftDetails[0].nft_id
        }});
        this.latestAuctions = latestAuctions.data.results;
        this.totalAuction = latestAuctions.data.metadata.total
      } catch (error) {
        // console.log(error);
      }
    },

    async loadMoreAuctions(){
      this.currentPage ++;
      const baseUrl = process.env.VUE_APP_API;
  
      try{
        let latestAuctions = await axios.get(
          `${baseUrl}/bidding/bidHistory/${this.nftDetails[0].auction_id}`, {
            params: {
              pageSize:this.pageSize,
              page: this.currentPage
            }
          }
        );
        
        for (let i=0; i < latestAuctions.data.results.length; i++) {
          this.latestAuctions.push(latestAuctions.data.results[i]);
        }
      }catch(error){
          console.error(error.message);
      }
    },

    async loadMoreOffers(){
      this.currentPage ++;
      const baseUrl = process.env.VUE_APP_API;

      try{
        let offers = await axios.get(
          `${baseUrl}/offer/all_offer/${this.nftDetails[0].nft_id}`, {
            params: {
              pageSize:this.pageSize,
              page:this.currentPage,
            }
          }
        );
        
        for (let i=0; i < offers.data.results.length; i++) {
          this.offers.push(offers.data.results[i]);
        }
      }catch(error){
        console.error(error.message);
      }
    },

    async loadMoreActivities() {
      this.currentPage ++;
      const baseUrl = process.env.VUE_APP_API;
  
      try{
        let activities = await axios.get(
          `${baseUrl}/sales/nftActivity/${this.nftDetails[0].nft_id}`, {
            params: {
              pageSize:this.pageSize,
              page:this.currentPage,
              remarks: this.selectedRemark.length > 0 ? JSON.parse(JSON.stringify(this.selectedRemark)) : ''
            }
          }
        );
        
        for (let i=0; i < activities.data.results.length; i++) {
          this.activities.push(activities.data.results[i]);
        }
      }catch(error){
          console.error(error.message);
      }
    },

    formatNumber(num,symbol='USDT') {      
      let fourDecimalCoin = ['BNB','ETH'];
      let decimal = fourDecimalCoin.includes(symbol) ? 4 : 2;
      
      let formatedDecimal = Math.floor(num * Math.pow(10, 4)) / Math.pow(10, 4);
      return formatedDecimal > 100000 ? numeral(formatedDecimal).format('0,0a').toUpperCase() :numeral(formatedDecimal).format(decimal == 2 ? '0,0.00' : '0,0.0000');	
    },

    roundDownNumber(num){
      let formatedDecimal = Math.floor(num * Math.pow(10, 4)) / Math.pow(10, 4);
      return formatedDecimal;
    },

    formatDate(date){
        return moment(date).fromNow();
    },

    increaseBidPirce(){
      this.bidForm.price = (this.bidForm.price*1 + this.nftDetails[0].min_price*1).toFixed(4);
    },

    decreaseBidPrice(){
      let limit = this.highestBidder.buyer_address != '0x0000000000000' ? this.highestBidder.amount*1 :this.nftDetails[0].price*1;

      if((this.bidForm.price - this.nftDetails[0].min_price*1).toFixed(4) <= limit){
        return;
      }

      this.bidForm.price = (this.bidForm.price - this.nftDetails[0].min_price*1).toFixed(4);
    },

    connectWallet(){
      this.$emit('connectWallet');
    },

    async fetchRemarks() {
      const baseUrl = process.env.VUE_APP_API;
      const remarks = await axios.get(`${baseUrl}/sales/enum`)
      
      this.remarks = remarks.data.remarks
    },

    removeSelectedRemark(text) {
      let index = this.selectedRemark.indexOf(text);
      this.selectedRemark.splice(index, 1);
      this.fetchActivity();
    },

    clearFilterRemark() {
      this.selectedRemark = [];
      this.fetchActivity();
    },
    shortenPriceObj(price) {
        let suffix = '';
        if (Number(price) >= 1.0e+9) {
            price = (Number(price) / 1.0e+9).toFixed(2);
            suffix = " B";
        } else if (Number(price) >= 1.0e+6) {
            price = (Number(price) / 1.0e+6).toFixed(2);
            suffix = " M";
        } else if (Number(price) >= 1.0e+3) {
            price = (Number(price) / 1.0e+3).toFixed(2);
            suffix = " K";
        } else {
            price = price.toFixed(0);
        }
        return { price, suffix }
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');
.copy-btn {
  border-radius: 6px;
  margin-left: 10px;
  border: 1px solid var(--gold);
  background: transparent;
}
.copy-icon {
  color: var(--gold);
}
#flip {
  appearance: none;
  display: block;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
  position: relative;
}

.flip-card .flip-indication {
  position: absolute;
  top: 0;
  z-index: 99;
  right: 0;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

/* .flip-card:hover .flip-card-inner, */
#flip:checked + .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lightmode .flip-card-front {
  background-color: white;
  border-radius: 12px;
}

.lightmode .flip-card-back {
  background-color: white;
  border-radius: 12px;
  transform: rotateY(180deg);
}

.flip-card-front {
  background-color: rgb(35, 38, 39);
  border-radius: 12px;
}

.flip-card-back {
  background-color: rgb(35, 38, 39);
  border-radius: 12px;
  transform: rotateY(180deg);
}

.item-buy-part{
  position: relative;
  top: 0;
  padding: 20px;
  min-height: 325px;
}

.lightmode .item-buy-part {
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
}

#nft-details-part {
  height: fit-content;
}

#price-history-part, #offer-details, #latest-auction {
  height: 325px;
}

.item-buy-part .item-content{
  max-height: 400px;
  overflow: auto;
}

.nft-item-title {
  display: flex;
  /* flex-direction: column; */
}

.lightmode .nft-item-title {
  color: black;
  /* flex-direction: column; */
}



.nft-item-title-info {
  width: 85%;
}

.nft-item-title .share-btn {
  font-size: 25px;
}

.status-logo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 120px;
  /* float: right; */
  text-align: center !important;
  cursor: pointer;
  padding: 0 !important;
}
.status-logo .desc {
  font-size: 12px;
}
.status-logo .status-remark{
  font-size: 10px;
  color: gray;
}
.return-note {
  width: 100%;
  text-align: right;
}
.return-note button {
  background: transparent;
  padding: 10px;
  color: var(--default);
  border-radius: 8px;
  margin-bottom: 10px;
}
.return-note button svg {
  color: var(--default);
}
.return-note button span {
  padding-left: 6px;
}
.buying-btns.function-wrappers {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-end !important;
  justify-content: space-between !important;
}
#returnBanknoteModal .input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
#returnBanknoteModal .close {
  background: transparent;
  border: none;
  color: white;
}
.nft-details .modal-content {
  background-color: rgb(44, 47, 49) !important;
  color: white;
}
#returnBanknoteModal input,
#returnBanknoteModal textarea {
  background: rgb(66, 66, 66);
  /* border: 1px solid white !important; */
  border-radius: 4px;
  color: white;
}

#fullpage {
  display: none;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: black;
}
#fullpage svg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}
.fullpage-btn {
  text-align: right;
}
.fullpage-btn span,
.fullpage-btn svg {
  cursor: pointer;
}
.fullpage-btn svg {
  margin-right: 10px;
}
.image-functions {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.flip-indication {
  display: flex;
  gap: 10px;
  align-items: center;
}
.flip-indication {
  font-size: 12px;
}
.flip-indication img {
  width: 20px;
  height: 20px !important;
}

/* swal css */
.swal2-container.swal2-center > .swal2-popup {
  border: 2px solid var(--bs-body-color) !important;
  background: rgb(24, 26, 27) !important;
  color: white;
}
.swal2-icon.swal2-warning {
  border-color: #d33 !important;
  color: #d33 !important;
}
.swal2-styled.swal2-confirm {
  background-color: transparent !important;
  border: 1px solid rgb(255, 164, 9) !important;
}

#shareModal .close {
  background: transparent;
  border: none;
  color: white;
}

.address-input {
  font-size: 1rem;
  color: #fff;
  background-color: transparent;
  border: none;
  border: 1px solid transparent;
}

.nft-details .item-details-countdown .item-countdown-list {
  gap: 10px;
}

#bidModal .bid-input{
  border: 1px solid #F9A300;
  border-radius: 20px;
  display: flex;
  padding: 10px;
  align-items: center;
}

#bidModal .bid-input .price{
  font-size: 25px;
}

#bidModal .bid-input .price .bid-symbol{
  color: var(--theme-color);
}

.btn-layout{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.buying-btns .default-btn{
  height: 100%;
  min-width: 140px;
  text-align: center;
}

.selected-content {
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.selected-content-box {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1px solid white;
  align-items: center;
  border-radius: 10px;
  text-transform: capitalize;
  color: white;
  margin: 5px 0px;
  width: 145px;
  justify-content: space-between;
}

.selected-content-box:not(:first-child), .selected-content-box:not(:last-child) {
  margin-right: 10px;
}

.selected-text {
  width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-selected {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: white;
  font-weight: 600;
  padding: 0px;
}

.btn-clearAll {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #ffa135;
  margin: 10px 0px;
}

.btn-clearAll:hover {
  color: #da7809;
}

.offer-td-div {
  position: relative;
  height: 100%;
  top: 10px;
  display: flex;
  align-items: center;
}

#latest-auction, #offer-details {
  /* max-height: 350px; */
  overflow-y: scroll;
}

#activity-table::-webkit-scrollbar,
#activity-table-selected::-webkit-scrollbar, 
#latest-auction::-webkit-scrollbar,
#offer-details::-webkit-scrollbar {
  width: 7px;               /* width of the entire scrollbar */
}

#activity-table::-webkit-scrollbar-track,
#activity-table-selected::-webkit-scrollbar-track,
#latest-auction::-webkit-scrollbar-track,
#offer-details::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

#activity-table::-webkit-scrollbar-thumb,
#activity-table-selected::-webkit-scrollbar-thumb,
#latest-auction::-webkit-scrollbar-thumb,
#offer-details::-webkit-scrollbar-thumb {
  background-color: grey;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid grey;  /* creates padding around scroll thumb */
}

.latest-usdPrice {
  font-size: 12px;
}

.filter-activity {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #151517;
  padding: 10px 20px;
  justify-content: space-between;
  border-radius: 10px;
  color: white;
}

#filter-activity-content {
  background-color: #151517;
  border-radius: 10px;
  color: white;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.form-check-label {
  text-transform: capitalize;
}

#activity-div {
  max-height: 650px !important;
  height: 650px !important;
  color: white;
}

#activity-table {
  max-height: 450px !important;
  height: 450px !important;
}

#activity-table-selected {
  height: 410px;
}

.item-desc-inner, .items-activity-part {
  padding: 10px;
}

.items-right-nft {
  padding: 10px !important;
}

.btn-offer-table {
  position: absolute;
  right: 0;
}

@media only screen and (min-width: 1280px) {
  .item-details-wrapper {
    width: 70%;
    min-width: 1280px;
  }
}

@media screen and (min-width: 1000px) {
  .item-desc-inner, .items-right-nft {
    padding: 0px;
  }

  .item-details-section {
    display: flex;
    justify-content: center;
  }

  .item-details-wrapper {
    width: 70%;
  }

  .flip-card {
    height: 500px;
  }
  .flip-card .flip-indication {
    transform: translateY(20px);
  }
  /* .buying-btns.function-wrappers {
        display: flex;
        flex-direction: row;
        align-items: flex-end !important;
        justify-content: space-between !important;
    } */
  .btn-layout{
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .nft-details table td,tr{
    min-width: 100px;
  }
}

.qrcode {
  display: inline-block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
  border-radius: 0.25rem;
}

.qrcode canvas {
  border-radius: 0.25rem;
}

.qrcode__image {
  background-color: #2c2f31;
  border: 0.25rem solid #2c2f31;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 15%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
}

.nft-details .item-other-info{
  display: flex;
  align-items: baseline !important;
}

.item-other-info .address {
  color: #ffa409;
  text-decoration: none;
}

.buying-btns {
  display: flex;
}

.offer table tr{
  vertical-align: baseline;
}

.nft-details table td,.nft-details table tr,.nft-details table th{
  text-align: left;
  min-width: 200px;
  color: white;
  /* border-color: #212529; */
  border-width: 0 0 2px 0;
}

.lightmode .nft-details table td,.lightmode .nft-details table tr,.lightmode .nft-details table th{
  color: var(--dark) !important;
  border-color: #212529;
}

.nft-details .activity-table td{
  min-width: 200px;
}

.modal-header .close{
  background-color: transparent;
  border: none;
  color: white;
}

.nft-details table .coin-amount img{
  position: relative;
  bottom: 2px;;
  margin-right: 5px;
}

.nft-details table .event span{
  position: relative;
  top: 2px;
}

.nft-details .text-theme{
  color: var(--theme-color);
}

.nft-details #activity-div .collapsing {
  height: 0;
  overflow: hidden;
  transition: none !important
}

.nft-details .owner-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
}

.nft-details .owner-content{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.nft-details .owner-content .index {
  font-size: 16px;
  border: 1px solid var(--theme-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.nft-details .btn-disabled{
  opacity: 0.2;
  cursor: not-allowed;
}

.nft-details .content-collapse{
  margin-top: 1rem;
}

.nft-details .content-collapse-header{
  width: 100%;
  background-color: #464a4d !important;
  margin: 0 !important;
  padding: 20px;
  text-align: left;
  font-size: 20px;
  border-radius: 10px ;
  display: flex;
  justify-content: space-between;
  color: var(--default);
}

.lightmode .nft-details .content-collapse-header{
  background-color: white !important;
  color: var(--dark) !important;
}

.nft-details .content-collapse-body{
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nft-details .content-collapse-header:not(.collapsed){
  border-radius: 10px 10px 0 0;  
}

.nft-details .content-collapse-header .b-icon.bi{
  transition: 0.5s;
}

.nft-details .content-collapse-header:not(.collapsed) .b-icon.bi{
  transform: rotate(180deg);
}

.lightmode .nft-details .offerModal{
  color: black;
}
.lightmode .count-down > li > span:nth-child(1){
  color: white;
}

.nft-details .offer-wrapper{
  position: relative;
}

.nft-details .offer-wrapper .offer-warning{
  position: absolute;
  z-index: 2;
  background-color: #464a4d;
  bottom: 0;
  text-align: center;
  padding: 8px;
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.lightmode .nft-details .offer-wrapper .offer-warning{
  background-color: #ffffff;
  color: black;
}

.nft-details .nft-item-title-count{
  display: flex;
}

.form-group{
  margin-bottom: 1rem;
}

.flip-serial-number{
  position: absolute;
  font-family: 'Source Sans Pro', sans-serif;
  top: 5%;
  left: 10px;
  font-weight: bold;
  letter-spacing: 5px;
}
</style>