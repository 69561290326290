<template>
  <div :class="lightMode && lightMode == 'dark' ? 'dealers_page' : 'dealers_page lightmode'">
    <div class="country-filter">
        <!-- <select name="country" id="country" v-model="countryFilter">
            <option value=""></option>
        </select> -->
        <v-select class="style-chooser" id="allCountries" v-model="countryFilter" label="category" :options="countries" @input="fetchDealers"/>
    </div>
    <div v-if="dealers.length < 1">
        {{ $t("Currently no dealers") }}
    </div>
    <a data-bs-toggle="collapse" :data-bs-target="'#collapseDetails'+index" href="#collapseDetails" role="button" aria-expanded="false" :aria-controls="'collapseDetails'+index"
        v-for="d, index in dealers" :key="d.email + index" class="dealers_card">
        <div class="dealer_img">
            <img :src="d.image ? d.image : lightMode && lightMode == 'dark' ? logoLight : logoDark" alt="">
        </div>
        <div class="dealer_content">
            <div class="title">
                {{ d.company_name }}
            </div>
            <div class="contact-details">
                <span>{{ d.office_number }}</span> | 
                <span>{{ d.email }}</span>
            </div>
        </div>
        <div class="collapse address" :id="'collapseDetails'+index">
            <div class="card card-body">
                {{ d.company_addr }}
            </div>
        </div>
    </a>
  </div>
</template>

<script>
import axios from "axios";

export default {
    data(){
        return {
            dealers: [],
            logoLight: require('../assets/images/numisart-logo.png'),
            logoDark: require('../assets/images/numisart-logo.png'),
            countryFilter: "Malaysia",
            countries: []
        }
    },
    props:['lightMode'],
    created() {
        this.fetchCountries()
        this.fetchDealers()
    },
    methods: {
        fetchDealers (filter = null) {
            const baseUrl = process.env.VUE_APP_API;

            if (filter != null) {
                try{
                    axios.get(`${baseUrl}/dealer?country=${filter}`).then(res => {
                        console.log('res: ', res.data);
                        this.dealers = res.data;
                    })
                
                } catch(error){
                    console.log('/dealer api error', error);
                }
            } else {
                try{
                    axios.get(`${baseUrl}/dealer`).then(res => {
                        console.log('res: ', res.data);
                        this.dealers = res.data.results;
                    })
                
                } catch(error){
                    console.log('/dealer api error', error);
                }
            }
        },
        fetchCountries () {
            const baseUrl = process.env.VUE_APP_API;
            try{
                axios.get(`${baseUrl}/dealer/country`).then(res => {
                    console.log('res: ', res.data);
                    for(let i=0; i<res.data.length; i++){
                        this.countries.push(res.data[i].name);
                    }
                })
            
            } catch(error){
                console.log('/dealer/country api error', error);
            }
        }
    }
}
</script>

<style>
.dealers_page {
    width: 90%;
    margin: auto;
}
.dealers_page .dealer_img img {
  max-height: 100px;
  object-fit: contain;
}
.dealers_page .dealer_img {
  grid-area: img;
  padding-bottom: 10px;
}
.dealers_page .dealer_content {
  grid-area: details;
}
.dealers_page .address {
  grid-area: address;
}
.lightmode .dealers_page .dealers_card {
    color: black;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
}
.lightmode .dealers_page .dealers_card:hover {
    color: black !important;
}
.dealers_page .dealers_card:hover {
    color: white !important;
}
.dealers_page .dealers_card {
    width: 100%;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px 0px rgba(255,255,255,0.1);
    color: white;
    text-decoration: none;
    padding: 20px;
    margin: 10px 0;
    border-radius: 12px;
    text-align: center;
}
.dealers_page .dealers_card .card-body {
    color: white;
    background: #6c757d;
}
.dealers_card.collapsed .dealer_img {
    padding-bottom: 0;
}
#allCountries {
    margin-top: 20px;
    width: 100%;
}
@media only screen and (min-width: 1200px) {
    .dealers_page {
        width: 80%;
        max-width: 1000px;
        margin: 100px auto 0 auto;
    }
    .lightmode .dealers_page .dealers_card:hover,
    .dealers_page .dealers_card:hover {
        color: var(--gold) !important;
    }
    .dealers_page .dealers_card {
        display: grid;
        align-items: center;
        grid-template-columns: 40% 60%;
        grid-template-areas: 
        "img details"
        "address address";
        text-decoration: none;
        padding: 20px;
        margin: 10px;
        border-radius: 12px;
    }
    #allCountries {
        margin-top: 0;
        width: 30%;
    }
}
</style>