<template>
  <div class="allOwner text-center">
    <!-- title start -->
    <div class="title mt-3">
      <div class="main">{{ $t("Numisart Collectors") }}</div>
      <p class="sub">{{ $t("Explore, collect and sell Numismatic NFT") }}</p>
    </div>
    <!-- title end -->

    <!-- filter start  -->
    <div class="d-flex justify-content-center px-3">
      <div class="filter-input">
        <b-icon icon="search"></b-icon>
        <input id="search" type="search" v-model="search" placeholder="Search" @input="filter">
      </div>
    </div>
    <!-- filter end -->

    <!-- content start -->
    <div class="allOwner-outer-container">
      <div v-if="owners" class="allOwner-container px-3">
        <router-link
          :class="`allOwner-wrapper ${index < 3 ? 'top-border' : ''}`"
          v-for="(owner, index) in owners"
          :to="
            userAddress &&
            userAddress.toUpperCase() == owner.current_owner.toUpperCase()
              ? '/profile'
              : '/profile/' + owner.current_owner
          "
          :key="index"
        >
          <div class="allOwner-profileBgImg">
            <img :src="owner.banner" alt="bg" />
          </div>
          <div class="allOwner-profileImg">
            <img :src="owner.profile_pic" alt="profile" />
          </div>
          <div class="allOwner-info">
            <span
              ><b>{{ owner.name }}</b></span
            >
            <b class="ownNum">{{ $t('NFT owned') }}: {{ owner.total_owned }}</b>
          </div>
          <div class="allOwner-bio">
            <!-- {{ owner.bio }} -->
            <span v-html="owner.bio"></span>
          </div>
        </router-link>
      </div>
    </div>
    
    <!-- content end -->

    <!-- view more start -->
    <div v-if="owners.length < totalOwners" class="more-btn-wrapper">
        <button @click="viewMore" class="more-btn">{{ $t('View More') }}</button>
    </div>
    <!-- view more end -->

    <!-- title end start -->
    <div class="title-end">
      <p>{{ $t("The World 1st & Largest Numismatic NFT Marketplace") }}</p>
    </div>
    <!-- title end end -->
  </div>
</template>

<style scoped>
.allOwner .main {
  font-size: 25px;
}
.allOwner .title {
  color: #f9a02f;
}
.allOwner .title h1 {
  font-size: auto;
}
.lightmode .allOwner .title p {
  color: var(--default);
}
.allOwner .title p {
  font-size: unset;
  color: rgba(255, 255, 255, 0.6);
}
.allOwner .title-end p {
  font-size: unset;
  color: #f9a02f;
  margin-top: 10%;
}

.allOwner-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
  grid-template-rows: 320px;
  grid-gap: 20px;
  margin: 2em 0;
  /* height: auto; */
  min-height: 70vh;
  max-height: 100%;
}

.lightmode .allOwner-wrapper {
  background: var(--light);
  color: var(--dark);
  overflow: hidden;
}

.allOwner-wrapper {
  box-shadow: rgba(41, 41, 41, 0.35) 0px 5px 15px;
  position: relative;
  height: 320px;
  cursor: pointer;
  transition: 1s;
  background: #2f2f2f;
  border-radius: 10px;
  z-index: 1;
}

.top-border {
    /* filter: blur(6px); */
    /* border: 1px solid rgba(#000, .2); */
    box-shadow: 0px 0px 3px 3px #F39F29;
    animation: changeColor 5s linear infinite;
}

/* @keyframes changeColor{
    0%{
        box-shadow: 0px 0px 3px 3px #f5c684;
    }

    25%{
        box-shadow: 0px 0px 3px 3px #F39F29;
    }
    
    50%{
        box-shadow: 0px 0px 3px 3px #f0ac4d;
    }
    100%{
        box-shadow: 0px 0px 3px 3px #f5c684;
    }
} */

@keyframes changeColor{
    0%{
        box-shadow: 0px 0px 3px 3px #f3f3f3;
    }

    25%{
        box-shadow: 0px 0px 3px 3px #fd9400;
    }
    
    50%{
        box-shadow: 0px 0px 3px 3px #e9b976;
    }
    100%{
        box-shadow: 0px 0px 3px 3px #f3f3f3;
    }
}

.allOwner-wrapper:hover{
    z-index: 2;
    transform: translateY(-10px);
    /* background: #2f2f2f; */
    /* background: initial; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.top-border:hover{
    box-shadow: 0px 0px 3px 3px rgb(235, 201, 156);
}

.allOwner-wrapper .allOwner-profileBgImg img {
  width: 100%;
  height: 145px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.allOwner-wrapper .allOwner-profileImg {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 135px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.allOwner-profileImg img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: black;
  object-fit: cover;
}

.allOwner-info {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allOwner-info .ownNum {
  color: #f9a02f;
}

.allOwner-bio {
  height: 75px;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 0 10px;
  text-overflow: ellipsis;
}

a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}

.allOwner .more-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.allOwner .more-btn {
  border: none;
  margin-top: 0;
  background: var(--gold);
  color: black !important;
  border-radius: 12px;
  transition: 1s;
  background-size: 200% 200%;
  width: 40%;
  margin: 50px auto;
  height: 40px;
  font-weight: 700; 
}

.filter-input {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #2f2f2f;
  padding: 10px;
  border-radius: 12px;
  margin: auto;
  font-size: 1.2rem;
}

.filter-input input {
  border: none;
  width: 100%;
  text-indent: 50px;
  background-color: #2f2f2f;
  color: white;
}

.lightmode .filter-input {
  border: 1px solid var(--default);
  background-color: var(--light);
}

.lightmode .filter-input input {
  background-color: var(--light);
  color: var(--dark);
}

.filter-input input:focus-visible {
  outline: none;
}

/* PC */
@media only screen and (min-width: 1200px) {
  .allOwner .main {
    font-size: 70px;
  }
  .allOwner-outer-container {
    width: 70%;
    margin: auto;
  }

  .allOwner-wrapper .top-border {
    width: 380px;
    height: 220px;
  }
  .allOwner-container {
    /* grid-template-columns: repeat(3, minmax(100px, 1fr)); */
    height: 100%;
    justify-content: center;
  }

  .allOwner .title h1 {
    font-size: 70px;
  }
  .allOwner .title p {
    font-size: 25px;
  }
  .allOwner .title-end p {
    font-size: 50px;
  }

  .filter-input {
    width: 70%;
  }
}
</style>

<script>
import axios from "axios";
export default {
  name: "AllOwner",
  data() {
    return {
      owners: [],
      currentPageOwners:[],
      pageSize:9,
      page:1,
      userAddress: localStorage.getItem("address"),
      totalOwners:0,
      search:''
    };
  },
  mounted() {
    document.body.style.backgroundImage = "none"
    this.fetchOwnerList();
  },
  methods: {
    async fetchOwnerList() {
        const baseUrl = process.env.VUE_APP_API;
        const ownerList = await axios.get(`${baseUrl}/user/top`,{params:{pageSize:this.pageSize,page:this.page}});
        this.owners = ownerList.data.results;
        this.currentPageOwners = ownerList.data.results;
        this.totalOwners = ownerList.data.metadata.total;
    },

    async viewMore() {
        let page = this.page + 1;
        const baseUrl = process.env.VUE_APP_API;
        try{
          const ownerList = await axios.get(`${baseUrl}/user/top`,{params:{pageSize:this.pageSize,page:page}});
          this.currentPageOwners = ownerList.data.results;

          if(ownerList.data.length == 0){
              return;
          }

          for(let i=0; i<ownerList.data.results.length; i++){
            console.log(ownerList.data.results[i]);
            this.owners.push(ownerList.data.results[i]);
          }


          this.page += 1;
          
        }catch(error){
          console.log(error);
        }
    },

    async filter() {
      const baseUrl = process.env.VUE_APP_API;
      const ownerList = await axios.get(`${baseUrl}/user/searchTop`,{
          params:{pageSize:this.pageSize,page:this.page,search_input:this.search},       
        }
      )
      this.owners = ownerList.data.results;
      this.currentPageOwners = ownerList.data.results;
    },
  },
};
</script>