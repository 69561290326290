import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Profile from '../views/Profile.vue'
import Profile from '../views/Profile.vue'
import ProfileOthers from '../views/ProfileOthers.vue'
import NftDetails from '../views/Nft-details.vue'
import MintDetails from '../views/Mint-details.vue'
import Certificate from '../views/Certificate.vue'
import AllOwner from '../views/AllOwner.vue'
import About from '../views/About.vue';
import Roadmap from '../views/Roadmap.vue';
import ConnectWallet from '../views/ConnectWallet.vue';
import Marketplace from '../views/Marketplace.vue';
import MarketplaceMystery from '../views/MarketplaceMystery.vue';
import Mystery from '../views/MysteryLuck.vue';
import ContactUs from '../views/ContactUs.vue';
import ArrangeNFT from '../views/ArrangeNFT.vue';
import Dealers from '../views/Dealers.vue';

// middleware
import ConnectWalletMiddleware from '../middleware/ConnectWallet';
// import ConnectedMiddleware from '../middleware/Connected';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/connect/wallet',
    name: 'ConnectWallet',
    component: ConnectWallet,
    meta:{
      // middleware: ConnectedMiddleware
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    props: true,
    meta: {
      middleware: ConnectWalletMiddleware
    }
  },
  {
    path: '/profile/:address',
    name: 'ProfileOthers',
    component: ProfileOthers,
    props: true
  },
  {
    path: '/nft/:id',
    name: 'NftDetails',
    component: NftDetails
  },
  {
    path: '/mint/:id',
    name: 'MintDetails',
    component: MintDetails
  },
  {
    path: '/certificate',
    name: 'Certificate',
    component: Certificate
  },
  {
    path: '/all/owner',
    name: 'AllOwner',
    component: AllOwner
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace
  },
  {
    path: '/marketplace/mystery',
    name: 'MarketplaceMystery',
    component: MarketplaceMystery
  },
  {
    path: '/roadmap',
    name: 'Roadmap',
    component: Roadmap
  },
  {
    path: '/mystery-mint',
    name: 'MysteryMint',
    component: Mystery
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/arrange-nft',
    name: 'ArrangeNFT',
    component: ArrangeNFT
  },
  {
    path: '/dealers',
    name: 'Dealers',
    component: Dealers
  },
  { path: '*', redirect: '/' } // catch all use case
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index,  1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}
  

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];
  
      const context = {
        from,
        next,
        router,
        to,
      };
      const nextMiddleware = nextFactory(context, middleware, 1);
  
      return middleware[0]({ ...context, next: nextMiddleware });
    }
  
    return next();
});

export default router
