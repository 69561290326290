<template>
  <div class="roadmap">
    <div class="roadmap-banner">
      <div class="title">{{ $t('Roadmap') }}</div>
      <div class="sub-title">
        {{ $t("The world's first & largest numismatic NFT marketplace") }}
      </div>
    </div>

    <div class="container d-flex flex-column">
      <!-- Roadmap -->
      <div class="text-white mt-5 min-vh-100">
        <div class="mt-5">
          <ul>
            <li class="roadmap-li roadmap-step1">
              <div class="roadmap-step">
                <div class="step">STEP 01</div>
                <div class="roadmap-step-left">
                  <div class="roadmap-content">
                    <ul>
                      <li>
                        <span>
                          Malaysia's Largest Collectibles Auction House -
                          Trigometric
                        </span>
                      </li>
                      <li><span>Concept Generation</span></li>
                      <li>
                        <span>Brand and marketing strategy preparation</span>
                      </li>
                      <li><span>Team establishment</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <li class="roadmap-li roadmap-step2">
              <div class="roadmap-step">
                <div class="step">STEP 02</div>
                <div class="roadmap-step-right">
                  <div class="roadmap-content">
                    <ul>
                      <li>
                        <span>
                          Establishment of major social media pages (Social
                          Media page)
                        </span>
                      </li>
                      <li>
                        <span>
                          Community Building (Social Media Channel Launch)
                        </span>
                      </li>
                      <li><span>Marketing</span></li>
                      <li><span>Website launch</span></li>
                      <li><span>Smart contract creation</span></li>
                      <li><span>NFT marketplace Beta version</span></li>
                      <li><span>Initial Funding Community (IFC)</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <li class="roadmap-li roadmap-step3">
              <div class="roadmap-step">
                <div class="step">STEP 03</div>
                <div class="roadmap-step-left">
                  <div class="roadmap-content">
                    <ul>
                      <li><span>White paper</span></li>
                      <li>
                        <span>
                          Hilton KL hosts the annual Malaysia Collectibles
                          Exhibition-Live present numismatic mint NFT
                        </span>
                      </li>
                      <li><span>NFT market place launch</span></li>
                      <li>
                        <span>
                          Launch Numismatic combined with NFT blockchain first
                          press conference
                        </span>
                      </li>
                      <li>
                        <span>
                          The first Numismatic NFTs are online (Banknotes)
                        </span>
                      </li>
                      <li>
                        <span>
                          Target 3000 collectibles to successfully cast NFT
                        </span>
                      </li>
                      <li><span>Numiscoin Yield Farming</span></li>
                      <li><span>NFT Staking</span></li>
                      <li><span>Liquidity farm</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <li class="roadmap-li roadmap-step4">
              <div class="roadmap-step">
                <div class="step">STEP 04</div>
                <div class="roadmap-step-right">
                  <div class="roadmap-content">
                    <ul>
                      <li><span>CyberPan NFT launch (Opensea)</span></li>
                      <li><span>Presales 4.0 (CyberPan Box)</span></li>
                      <li>
                        <span>
                          Linked coin appraiser as a certification for all
                          Numismatic NFT Mint
                        </span>
                      </li>
                      <li><span>Centralize exchange listed</span></li>
                      <li><span>Coin marketcap</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <li class="roadmap-li roadmap-step5">
              <div class="roadmap-step">
                <div class="step">STEP 05</div>
                <div class="roadmap-step-left">
                  <div class="roadmap-content">
                    <ul>
                      <li><span>DAPP launch</span></li>
                      <li><span>Beta Game Launching</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- WHAT IS TOKENOMIC -->
      <div class="content-title">
        <hr />
        <div class="title">{{ $t('WHAT IS TOKENOMIC') }}</div>
        <hr />
      </div>

      <div class="tokenomic">
        <div class="mt-0">
          {{ $t('Tokenomic refers to the study or collection of currency in circulation. The so-called currency in circulation includes coins, tokens, banknotes and related items; at the same time, the term Numismatists generally refers to scholars or collectors who study coins. Academically, numismatics also includes extensive research on currencies and other payment media used to offset debt and commodity transactions.') }}
        </div>

        <div>
          {{ $t('In the absence of a structured currency system, the ancients lived in a society that bartered things and used items with intrinsic value that can be found locally. Today, bartering behavior still exists in a small group of groups lacking a currency system. The currency of early mankind was strange, but it was impossible to use other commodities in bartering transactions, even if the currency was circulating (the situation is like cigarettes in prison).') }}
        </div>

        <div>
          {{ $t('Historically, people used horses as the main unit of currency and used sheepskins for renewal change. Perhaps sheepskin is suitable for numismatics, but horses are not. For centuries, many objects have been used as currency, such as shells, precious metals and gems. Monetary science is the subject of studying currency and its history. It is very important in archeology. As some kings and historical events do not have corresponding historical data, archaeologists can learn about the appearance of corresponding kings (such as the Roman Empire, Persia) and infer through the currency excavated The general social and economic conditions at that time determine the age of the antiquities.') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  data() {
    return {

    } 
  },
  mounted () {
    document.body.style.backgroundImage = "none"
  }
})
</script>

<style scoped>
.roadmap {
  min-height: 100vh;
}

.roadmap hr {
  align-self: center;
  background: white;
  width: 100%;
}

.content-title {
  display: flex;
  margin: 3rem 0rem;
}

.content-title .title {
  color: white;
  font-size: 3rem;
  font-weight: 800;
  min-width: fit-content;
  text-align: center;
  margin: 0px 15px;
}

.roadmap-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
  background-image: url('../assets/images/roadmap.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.roadmap-banner .title {
  color: white;
  font-size: 4rem;
  font-weight: 800;
}

.roadmap-banner .sub-title {
  color: var(--dim-white);
  font-size: 1.4rem;
  text-align: center;
}

.step {
  background: #1c1922;
  width: 149px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 88%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 1.6rem;
  border: 1px solid #3d3773;
}

.roadmap ul,
.roadmap-step-left ul,
.roadmap-step-right ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.roadmap-content li {
  background: url('../assets/images/list-bullet.svg') no-repeat left top;
  padding: 3px 0px 3px 10px;
  list-style: none;
  margin: 0;
}

.roadmap-content span {
  margin-left: 15px;
  display: inherit;
}

.roadmap-li {
  width: 100%;
  float: left;
  list-style-type: none;
}

.roadmap-step {
  position: relative;
  float: left;
  border-right: 1px solid#3d3773;
  padding: 30px;
  text-align: right;
  width: 49%;
  height: 175px;
}

.roadmap-step-left {
  position: absolute;
  top: 65%;
  right: 130px;
  transform: translateY(-50%);
  text-align: left;
  color: white;
  white-space: nowrap;
}

.roadmap-step-left:after {
  content: '';
  width: 55px;
  display: inline-block;
  height: 1px;
  background: #3d3773;
  position: absolute;
  right: -54px;
  top: 12%;
}

.roadmap-step-right {
  position: absolute;
  top: 91%;
  left: 100%;
  transform: translateY(-50%);
  padding: 0px 0px 0px 128px;
  text-align: left;
  color: white;
  white-space: nowrap;
}

.roadmap-step-right:after {
  content: '';
  width: 55px;
  display: inline-block;
  height: 1px;
  background: #3d3773;
  position: absolute;
  left: 73px;
  top: 8%;
}

.roadmap-content {
  width: 370px;
  padding: 30px 35px;
  border-radius: 8px;
  word-break: break-word;
  white-space: initial;
  background: #1c1922;
  box-shadow: -5px -5px 15px 0 #3d3773 inset, 5px 5px 15px 0 #3d3773 inset;
  border: 1px solid #3d3773;
}

.roadmap-step3 .roadmap-step {
  height: 345px;
}

.roadmap-step3 .roadmap-step-left:after {
  top: 6%;
}

.roadmap-step4 .roadmap-step {
  height: 200px;
}

.roadmap-step4 .roadmap-step-right {
  top: 58%;
}

.roadmap-step4 .roadmap-step-right:after {
  top: 12%;
}

.roadmap-step5 .roadmap-step {
  border: none;
}

.roadmap-step5 .roadmap-step-left {
  top: 35%;
}

.roadmap-step5 .roadmap-step-left:after {
  top: 20%;
}

.step:before {
  position: absolute;
  bottom: 45px;
  left: 49%;
  height: 7px;
  width: 7px;
  background: #3d3773;
  content: '';
  border-radius: 5px;
}

.step:after {
  position: absolute;
  bottom: -4px;
  left: 49%;
  height: 7px;
  width: 7px;
  background: #3d3773;
  content: '';
  border-radius: 5px;
}

.roadmap-step1 .step:before,
.roadmap-step5 .step:after {
  display: none;
}

.tokenomic {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 7rem;
}

.tokenomic div {
  margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0% 8%;
  }

  .roadmap-banner {
    padding: 0px 20px;
    min-height: 30vh;
  }

  .roadmap-banner .title {
    color: white;
    font-size: 2.5rem;
    font-weight: 800;
  }

  .roadmap-banner .sub-title {
    font-size: 1rem;
  }

  .content-title {
    margin: 0rem 0rem 2rem 0rem;
  }

  .content-title .title {
    font-size: 1.5rem;
    margin: 0px 10px;
  }

  .roadmap-step {
    padding: 0px;
    width: 31px;
    left: 2%;
  }

  .step {
    width: 105px;
    left: -80%;
    font-size: 1.3rem;
  }

  .roadmap-step-left {
    top: 44%;
    left: 85%;
    padding: 0px 0px 0px 80px;
  }

  .roadmap-step-right {
    top: 46%;
    left: 85%;
    padding: 0px 0px 0px 80px;
  }

  .roadmap-step-left:after {
    width: 25px;
    left: 55px;
    top: 11%;
  }

  .roadmap-step-right:after {
    width: 25px;
    left: 55px;
    top: 7%;
  }

  .roadmap-content {
    width: 220px;
    padding: 25px 20px;
    font-size: 0.9rem;
  }

  .roadmap-step1 .roadmap-step {
    height: 260px;
  }

  .roadmap-step2 .roadmap-step {
    height: 410px;
  }

  .roadmap-step3 .roadmap-step {
    height: 615px;
  }

  .roadmap-step3 .roadmap-step-left {
    top: 47%;
  }

  .roadmap-step3 .roadmap-step-left:after {
    top: 5%;
  }

  .roadmap-step4 .roadmap-step {
    height: 325px;
  }

  .roadmap-step4 .roadmap-step-right {
    top: 45%;
  }

  .roadmap-step4 .roadmap-step-right:after {
    top: 10%;
  }

  .roadmap-step5 .roadmap-step-left {
    top: 30%;
  }

  .roadmap-step5 .roadmap-step-left:after {
    top: 25%;
  }

  .tokenomic {
    font-size: 1rem;
    margin-bottom: 5rem;
  }
}

@media only screen and (min-width: 400px) and (max-width: 768px) {
  .roadmap-step {
    padding: 0px;
    width: 31px;
    left: 7%;
  }
}
</style>