<template>
  <div id="profile" class="profile">
      <div class="banner">
          <img :src="bannerImg" alt="numismatic_profile_banner">
      </div>
      <div class="profile-pic">
            <div class="profile-pic-wrapper">              
                <img class="profile-img" :src="profileImg"  alt="numismatic_profile_pic">
            </div>
            <div class="profile-desc">
                <div class="profile_name">
                    <div class="name">
                        {{ name }}
                    </div>
                </div>
                <div class="profile-address">
                    <div class="address-wrapper">
                        <div class="address-border">
                            <img src="../assets/images/bnb-diamond.png" alt="diamond">
                            <span>{{ userAddress }}</span>
                        </div>
                        <!-- <img src="../assets/images/copy.png" alt="copy-btn"> -->
                    </div>
                </div>
                <div class="bio">
                    {{ bio }}
                </div>
                <!-- <div>Joined January 2022</div> -->

            </div>
      </div>
      <div class="profile_content">            
          <div class="title">{{ $t("NFT Collection") }}</div>
          <div class="section-collection">
            <div>
                <div class="filter-wrapper">
                    <label for="allCategory" class="filter-label">{{ $t("Filter by") }}: </label>
                    <v-select class="style-chooser" id="allCategory" v-model="collectionFilter" label="category" :options="categoryTypes" @input="handleFilter"/>
                </div>
                <div v-if="allCollection && allCollection.length <= 0" >{{$t("No records found")}}</div>
                <div v-if="allCollection && allCollection.length > 0" class="collection-container">
                    <div class="nft-card" v-for="(m, i) in allCollection" :key="m.thumbnail+i" >
                        <div class="nft-card-wrapper">
                            <div @click="handleSelected(m.nft_id)" class="nft-wrapper position-relative">
                                <div class="collection-label position-relative">
                                    <div class="thumbnail">
                                        <img :src="m.thumbnail_picture" alt="thumbnail">
                                    </div>
                                    <!-- <video class="mint-video" autoplay loop muted playsinline>
                                        <source :src="m.thumbnail" type="video/mp4">
                                    </video> -->
                                    
                                    <button class="switch-btn" @click.prevent.stop="changeSrc($event,i)">
                                        <i class="bi bi-play-fill"></i>
                                        <!-- <i>{{m.nft_id}}</i> -->
                                    </button>
                                </div>
                                
                                <div class="desc">
                                    <div class="details-wrapper">
                                        <div class="nft-details">
                                            <div class="category">{{ m.category }}</div>
                                            <div># {{ m.pmg_cert }}</div>
                                        </div>
                                        <div v-if="m.nft_status != 'passive'" class="price-details">
                                            <div>{{$t("Price")}}</div>
                                            <div class="price-wrapper">
                                                <span class="min-bid" v-if="m.nft_status == 'auction'">MIN </span>
                                                <img :src="m.currency_image" alt="symbol">
                                                <span>{{ m.symbol.toUpperCase() ==  'BNB' ? addCommas(formatNumber(m.price, 4)) : addCommas(formatNumber(m.price)) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="functions-wrapper" style="justify-content: flex-end">
                                        <!-- <div class="functions-details">
                                            <b-icon-three-dots @click.prevent.stop="openMenu(m.nft_id)"></b-icon-three-dots>
                                            <ul v-if="m.nft_id" :id="'optionMenu'+m.nft_id" class="actions-menu">
                                                <li @click.prevent.stop="copyLink">Copy Link</li>
                                            </ul>
                                        </div> -->
                                        <div class="fave">
                                            <b-icon-suit-heart-fill :id="'fave'+m.nft_id" :class="m.like_status == '0' ? '' : 'faved'" @click.prevent.stop="faveNft(m.nft_id)"></b-icon-suit-heart-fill>
                                            <div :id="'faveCount'+m.nft_id" class="fave-count">{{ m.total_likes }}</div>
                                            <!-- <div>100,000,000</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="nftLoading" class="collection-container">
                    <div v-for="i in lastNftQty" :key="i" class="movie--isloading">
                        <div class="loading-image"></div>
                        <div class="loading-content">
                            <div class="loading-text-container">
                            <div class="loading-main-text"></div>
                            <div class="loading-sub-text"></div>
                            </div>
                            <div class="loading-btn"></div>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="allCollection && allCollection.length > 6" class="more-btn-wrapper">
                    <button @click="addMore" class="more-btn">View More</button>
                </div> -->
            </div>
          </div>
      </div>
  </div>
</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
import axios from "axios";
const config = {
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem("access_token"),
  },
}

export default {
    // components: { VueperSlides, VueperSlide },
    // props: [
    //     "userAddress"
    // ],
    data () {
        return {
            nftLoading: true,
            lastNftQty: 6,
            userAddress: null,
            name: 'Username',
            bio: '',
            collection: [
                {
                    nft_id:'1',
                    category: 'El Teide Volcano, Spain',
                    // You can also provide a URL for the image.
                    front: require('../assets/images/note1-front.jpg'),
                    back: require('../assets/images/note1-back.jpg'),
                    thumbnail: require('../assets/images/note1.gif'),
                    pmg_cert: '7003378-001',
                    note: 'BNBUNL50a',
                    serial: '43c66E8021843032G',
                    country: 'Malaysia',
                    series: '1st Series & 4th Series',
                    grade: 'VF 25 NET',
                    signatures: 'Currency Commissioners',
                    like_status: '0'
                },
                // Other slides.
                {
                    nft_id:'2',
                    category: 'El Teide Volcano, Spain',
                    // You can also provide a URL for the image.
                    front: require('../assets/images/note2-front.jpg'),
                    back: require('../assets/images/note2-back.jpg'),
                    thumbnail: require('../assets/images/note2.gif'),
                    pmg_cert: '7003378-001',
                    note: 'BNBUNL50a',
                    serial: '43c66E8021843032G',
                    country: 'Malaysia',
                    series: '1st Series & 4th Series',
                    grade: 'VF 25 NET',
                    signatures: 'Currency Commissioners',
                    like_status: '0'
                },
                {
                    nft_id:'3',
                    category: 'El Teide Volcano, Spain',
                    // You can also provide a URL for the image.
                    front: require('../assets/images/note3-front.jpg'),
                    back: require('../assets/images/note3-back.jpg'),
                    thumbnail: require('../assets/images/note3.gif'),
                    pmg_cert: '7003378-001',
                    note: 'BNBUNL50a',
                    serial: '43c66E8021843032G',
                    country: 'Malaysia',
                    series: '1st Series & 4th Series',
                    grade: 'VF 25 NET',
                    signatures: 'Currency Commissioners',
                    like_status: '0'
                },
                {
                    nft_id:'4',
                    category: 'El Teide Volcano, Spain',
                    // You can also provide a URL for the image.
                    front: require('../assets/images/note1-front.jpg'),
                    back: require('../assets/images/note1-back.jpg'),
                    thumbnail: require('../assets/images/note2.gif'),
                    pmg_cert: '7003378-001',
                    note: 'BNBUNL50a',
                    serial: '43c66E8021843032G',
                    country: 'Malaysia',
                    series: '1st Series & 4th Series',
                    grade: 'VF 25 NET',
                    signatures: 'Currency Commissioners',
                    like_status: '0'
                },
                {
                    nft_id:'5',
                    category: 'El Teide Volcano, Spain',
                    // You can also provide a URL for the image.
                    front: require('../assets/images/note2-front.jpg'),
                    back: require('../assets/images/note2-back.jpg'),
                    thumbnail: require('../assets/images/note3.gif'),
                    pmg_cert: '7003378-001',
                    note: 'BNBUNL50a',
                    serial: '43c66E8021843032G',
                    country: 'Malaysia',
                    series: '1st Series & 4th Series',
                    grade: 'VF 25 NET',
                    signatures: 'Currency Commissioners',
                    like_status: '0'
                }
            ],
            isOpenMenu: false,
            walletAddress: null,
            allCollection: [],
            // collection: [6,7,8,9,10],
            innerStyles: {},
            step: '',
            initialStep: '',
            transitioning: false,
            testFave: false,
            currentPage: 1,
            pageSize: 6,
            bannerImg: 'https://numisartgallery.sgp1.digitaloceanspaces.com/default_banner.jpg',
            profileImg: 'https://numisartgallery.sgp1.digitaloceanspaces.com/default_profile.jpg',
            userAddressFull: this.$route.params.address,
            editable: false,
            defaultThumbnail: require('../assets/images/no-images.png'),
            categoryTypes: [],
            collectionFilter: '',
            currentUser: localStorage.getItem('address'),
            totalNft: null,
            isWaiting:false,
        }
    },
    async mounted() {
        document.body.style.backgroundImage = "none"
        let prevScroll = localStorage.getItem('scrollY') ?? 0
        let historyPage = localStorage.getItem('page') ?? 1

        if (prevScroll > document.body.scrollHeight) {
            console.log("longloooong");
            window.scrollTo(0, prevScroll);
            this.lastNftQty = this.pageSize * historyPage
        }

        let address = this.$route.params.address
        this.userAddress = `${address.substr(0,4)}.....${address.substr(-4,4)}`
        const baseUrl = process.env.VUE_APP_API;
        try{
            axios.get(`${baseUrl}/user/${address}`).then(res => {
                console.log('res: ', res.data.banner);
                this.bannerImg = res.data.banner == null ? 'https://numisartgallery.sgp1.digitaloceanspaces.com/default_banner.jpg' : res.data.banner
                this.profileImg = res.data.profile_pic == null ? 'https://numisartgallery.sgp1.digitaloceanspaces.com/default_profile.jpg' : res.data.profile_pic
                this.name = res.data.name ?? 'username'
                this.bio = res.data.bio ?? 'no bio yet'
            })

        // http://api.numisart.io/api/pmg/address/others?owner_address=0x6f4C86cB46196FCCa4fE0DF7200BF67b930a2e99&other_owner_address=0x45277ec4f5718620ce65665E888724E377778327&pageSize=10&page=1
        let collection = []
        if (!(this.currentUser)) {
            collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.userAddressFull}&other_owner_address=${address}&pageSize=${this.lastNftQty > 6 ? this.lastNftQty : this.pageSize}&page=${this.currentPage}`)
        } else {
            collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.currentUser}&other_owner_address=${address}&pageSize=${this.lastNftQty > 6 ? this.lastNftQty : this.pageSize}&page=${this.currentPage}`)
        }
        if (collection.data.results) {
            this.totalNft = collection.data.metadata.total
            this.allCollection = collection.data.results;
        }

        }catch(error){
            console.log('/pmg/address api error',error);
        }

        try{
            // const categories =  await axios.get(`${baseUrl}/category`)
            const categories =  await axios.get(`${baseUrl}/user/personalCategory/${address}`)
            const all = {id: 0, category: "All"}
            if (categories.data) {
                this.categoryTypes.push(all)
                for (let i = 0; i < categories.data.length; i++){
                    this.categoryTypes.push(categories.data[i])
                }
            }
        } catch(error){
            console.log('/category api error',error);
        }

        this.getUsers()
        this.nftLoading = false

    },
    // watch: {
    //     collectionFilter: {
    //         async handler () {
    //             let collection = []
    //             const baseUrl = process.env.VUE_APP_API;
    //             if (this.collectionFilter == '') {
    //                 collection =  await axios.get(`${baseUrl}/pmg/address?owner_address=${this.userAddressFull}&is_hidden=0&pageSize=6&page=${this.currentPage}`)
    //                 if (collection) {
    //                     this.allCollection =  collection.data.results
    //                 }
    //                 return;
    //             } else {
    //                 collection =  await axios.get(`${baseUrl}/pmg/address?owner_address=${this.userAddressFull}&is_hidden=0&category=${this.collectionFilter}&pageSize=6&page=${this.currentPage}`)
    //                 console.log(collection);
    //                 if (collection) {
    //                     this.allCollection =  collection.data.results
    //                 }
    //             }
    //         },
    //         deep: true
    //     }
    // },
    methods: {
        handleSelected (nft_id) {
            if (this.allCollection.length > 6){
                this.currentPage = Math.ceil(this.allCollection.length / 6)
            }
            localStorage.setItem('page', this.currentPage)
            localStorage.setItem('scrollY', document.documentElement.scrollTop)
            this.$router.push({ name: 'NftDetails', params: { id: nft_id } })

        },
        changeSrc(element,index){
            let switchBtnSelectors = document.querySelectorAll('.collection-label .switch-btn')
            let thumbnailSelectors = document.querySelectorAll('.collection-label .thumbnail');

            // check current element is video or img
            let currentElementClass = thumbnailSelectors[index].className;
            if(currentElementClass == 'thumbnail is_play'){
                let img = this.allCollection[index].thumbnail_picture;

                thumbnailSelectors[index].classList.remove('is_play');
                thumbnailSelectors[index].innerHTML = `<img src="${img}" alt="thumbnail">`;
                switchBtnSelectors[index].innerHTML = `<i class="bi bi-play-fill"></i>`;

                element.preventDefault();
                return;
            }
            
            // set target to pause
            // set others to play
            switchBtnSelectors = [...switchBtnSelectors];

            switchBtnSelectors[index].innerHTML = `<i class="bi bi-pause-fill"></i>`

            switchBtnSelectors.splice(index,1);

            switchBtnSelectors.forEach((item)=>{
                item.innerHTML = `<i class="bi bi-play-fill"></i>`
            });

            // change target from img to video
            // change others from video to img
            thumbnailSelectors = [...thumbnailSelectors]

            let thumbnailVideo = this.allCollection[index].thumbnail;
            thumbnailSelectors[index].innerHTML = `<video autoplay loop muted playsinline>
                                                        <source src="${thumbnailVideo}" type="video/mp4">
                                                    </video>`;

            thumbnailSelectors[index].classList.add('is_play')
                                    
            thumbnailSelectors.forEach((item,i)=>{
                if(i == index){
                    return;
                }

                let img = this.allCollection[i].thumbnail_picture;

                item.innerHTML = `<img src="${img}" alt="thumbnail">`
                item.classList.remove('is_play')
            }); 

            element.preventDefault();
            
            return false;
        },

        getUsers() {
            let address = this.$route.params.address
            const baseUrl = process.env.VUE_APP_API;
            // let a = 0;
            window.onscroll = async () => {
                let collection = [];
                // let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.getElementById('profile').offsetHeight;
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.getElementById('profile').offsetHeight;
                if (bottomOfWindow && !this.isWaiting) {
                    if (this.allCollection.length < this.totalNft) {
                        this.isWaiting = true;

                        console.log("this.totalNft: ", this.totalNft);
                        if ((this.collectionFilter && this.collectionFilter != '') && (this.collectionFilter && this.collectionFilter.category != "All")) {
                            console.log(this.collectionFilter);
                            this.currentPage++
                            if (!(this.currentUser)) {
                                collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.userAddressFull}&other_owner_address=${address}&category=${this.collectionFilter.id}&pageSize=${this.lastNftQty > 6 ? this.lastNftQty : this.pageSize}&page=${this.currentPage}`)
                            } else {
                                collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.currentUser}&other_owner_address=${address}&category=${this.collectionFilter.id}&pageSize=${this.lastNftQty > 6 ? this.lastNftQty : this.pageSize}&page=${this.currentPage}`)
                            }
                            if (collection.data.results) {
                                for (let i=0; i < collection.data.results.length; i++) {
                                    console.log(collection.data.results[i]);
                                    this.allCollection.push(collection.data.results[i]);
                                }
                            }
                        } else {
                            this.currentPage++
                            if (!(this.currentUser)) {
                                collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.userAddressFull}&other_owner_address=${address}&pageSize=${this.lastNftQty > 6 ? this.lastNftQty : this.pageSize}&page=${this.currentPage}`)
                                console.log('js log collection', collection);
                            } else {
                                collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.currentUser}&other_owner_address=${address}&pageSize=${this.lastNftQty > 6 ? this.lastNftQty : this.pageSize}&page=${this.currentPage}`)
                            }
                            if (collection.data.results) {
                                this.isWaiting = false;
                                for (let i=0; i < collection.data.results.length; i++) {
                                    console.log(collection.data.results[i]);
                                    this.allCollection.push(collection.data.results[i]);
                                }
                            }
                            
                        }
                    }
                    
                }
            }
            // this.nftLoading = false
        },
        async handleFilter (value) {
            let collection = []
            this.currentPage = 1
            // this.collectionFilter = value.id.toString()
            let address = this.$route.params.address
            const baseUrl = process.env.VUE_APP_API;
            if (value.category == 'All') {
                collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.userAddressFull}&other_owner_address=${address}&pageSize=${this.lastNftQty > 6 ? this.lastNftQty : this.pageSize}&page=${this.currentPage}`)
                if (collection) {
                    this.totalNft = collection.data.metadata.total
                    this.allCollection =  collection.data.results
                }
                return;
            }
            collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.userAddressFull}&other_owner_address=${address}&category=${value.id}&pageSize=${this.lastNftQty > 6 ? this.lastNftQty : this.pageSize}&page=${this.currentPage}`)
            console.log(collection);
            if (collection) {
                this.totalNft = collection.data.metadata.total
                this.allCollection =  collection.data.results
            }
        },
        copyLink () {
            console.log("TODO: copy link");
        },
        openMenu (nft_id) {
            let menu = document.getElementById('optionMenu'+nft_id)
            let style = window.getComputedStyle(document.getElementById('optionMenu'+nft_id)).display;
            if (style == 'none') {
                menu.style.display = 'block'
            } else {
                menu.style.display = 'none'
            }
            console.log('style: ', style);
        },
        formatNumber(num, decimals=2){
            return Math.floor(num * Math.pow(10,decimals))/Math.pow(10,decimals)
        },
        faveNft (nft_id) {
            // let address = this.$route.params.address
            // let collection = []
            // let fave = true
            // var element = document.getElementById(`fave${nft_id}`)
            // if (status == "1" && element.classList.contains("faved")) {
            //     fave = false
            // }
            let userAddress = localStorage.getItem('address')
            // let address = this.$route.params.address
            const baseUrl = process.env.VUE_APP_API
            axios.post(`${baseUrl}/favourite/update`, {
                "user_address": userAddress,
                "nft_id": nft_id,
            }, config).then(async () => {
                let element = document.getElementById(`fave${nft_id}`)
                let count = document.getElementById(`faveCount${nft_id}`)
                if (element.classList.contains("faved")) {
                    element.classList.remove("faved")
                    count.innerHTML = parseInt(count.innerHTML) - 1
                } else {
                    element.classList.add("faved")
                    count.innerHTML = parseInt(count.innerHTML) + 1
                }
                // if ((this.collectionFilter && this.collectionFilter != '') && (this.collectionFilter && this.collectionFilter.name != "All")) {
                //     console.log(this.collectionFilter);
                //     if (!(this.currentUser)) {
                //         collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.userAddressFull}&other_owner_address=${address}&category=${this.collectionFilter.id}&pageSize=${this.pageSize}&page=${this.currentPage}`)
                //     } else {
                //         collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.currentUser}&other_owner_address=${address}&category=${this.collectionFilter.id}&pageSize=${this.pageSize}&page=${this.currentPage}`)
                //     }
                //     if (collection.data.results) {
                //         for (let i=0; i < collection.data.results.length; i++) {
                //             console.log(collection.data.results[i]);
                //             this.allCollection.push(collection.data.results[i]);
                //         }
                //     }
                // } else {
                //     if (!(this.currentUser)) {
                //         collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.userAddressFull}&other_owner_address=${address}&pageSize=${this.pageSize}&page=${this.currentPage}`)
                //     } else {
                //         collection =  await axios.get(`${baseUrl}/pmg/address/others?owner_address=${this.currentUser}&other_owner_address=${address}&pageSize=${this.pageSize}&page=${this.currentPage}`)
                //     }
                //     if (collection.data.results) {
                //         this.allCollection = collection.data.results;
                //     }
                    
                // }

            })
        },
        // async addMore (type, category) {
        async addMore () {
            this.currentPage++
            let address = localStorage.getItem('address')
            const baseUrl = process.env.VUE_APP_API;
            try{
                const collection =  await axios.get(`${baseUrl}/pmg/address?owner_address=${address}&is_hidden=0&pageSize=${this.pageSize}&page=${this.currentPage}`);
                for (let i=0; i < collection.length; i++) {
                    this.allCollection.push(collection.data.results[i]);
                }
                console.log(this.allCollection);
            }catch(error){
                console.log('/pmg/address api error',error);
            }
        },
        addCommas (nStr) {
            nStr += ''
            var x = nStr.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return x1 + x2
        },
    }
}
</script>

<style>
#allCategory.style-chooser {
  --vs-controls-color: #dee2e6;
  --vs-border-color: #dee2e6;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: var(--gold);
  --vs-dropdown-option-color: var(--gold);

  --vs-selected-bg: #dee2e6;
  --vs-selected-color: #dee2e6;
  --vs-search-input-placeholder-color: #dee2e6;

  --vs-search-input-color: #eeeeee;
  --vs-search-input-bg: #282c34;

  --vs-dropdown-option--active-bg: var(--gold);
  --vs-dropdown-option--active-color: #282c34;
  width: 300px;
}
.lightmode .profile_content .card-header {
    background: transparent;
    color: var(--dark);
}

.lightmode .balance-wrapper {
    color: var(--dark);
}

.lightmode .profile_content .title {
    color: var(--dark);
}

.lightmode .btn-secondary {
    color: var(--dark) !important;
}

.lightmode .pool-container {
    color: var(--dark);
    border: 1px solid rgba(192, 192, 192, 0.6);
}

.lightmode .pool-container .loading-overlay {
    background: var(--light);
    color: var(--dark);
}

.lightmode .desc .nft-details .category {
    color: var(--dark) !important;
}

.lightmode .desc .nft-details .cert {
    color: rgba(0,0,0,0.4) !important;
}

.lightmode .desc .functions-wrapper .fave svg.heart path{
    fill:var(--default);
}
.lightmode .desc .functions-wrapper .fave svg.heart:hover path{
    fill: #dc3535;
}

.lightmode .section-collection .custom-tabs .per-tab {
    color: var(--dark);
}

.lightmode .profile-desc .profile_name .edit-name {
    color: var(--dark);
}
.lightmode .profile_content .pool .deposit-func .max-wrapper input,
.lightmode .profile_content .pool .deposit-func .max-wrapper input:focus-visible,
.lightmode .profile_content .pool .deposit-func .func-form button,
.lightmode .profile_content .pool .deposit-func .max-wrapper button {
    background: var(--light) !important;
    border: 1px solid rgba(192, 192, 192, 0.6) !important;
    color: var(--dark) !important;
}

.lightmode .desc .details-wrapper {
    color: var(--default);
}

.lightmode .desc .functions-wrapper {
    color: var(--dark);
}

.lightmode .desc .details-wrapper, .lightmode .desc .functions-wrapper {
    background: linear-gradient(rgba(255, 255, 255, 0.95) 0%, rgb(255, 255, 255) 20%);
    border-left: 1px solid rgba(191, 191, 191, 0.6);
    border-right: 1px solid rgba(191, 191, 191, 0.6);
}

.lightmode .desc .functions-wrapper {
    border-bottom: 1px solid rgba(191, 191, 191, 0.6);
}

.lightmode .profile-desc .address-wrapper .address-border {
    color: var(--dark);
}

.lightmode #allCategory.style-chooser {
    --vs-controls-color: #dee2e6;
    --vs-border-color: #dee2e6;
    --vs-dropdown-bg: var(--light);
    --vs-dropdown-color: var(--gold);
    --vs-dropdown-option-color: var(--gold);
    --vs-selected-bg: #dee2e6;
    --vs-selected-color: var(--default);
    --vs-search-input-placeholder-color: #dee2e6;
    --vs-search-input-color: #000000;

    --vs-search-input-bg: #dee2e6;
    --vs-dropdown-option--active-bg: var(--gold);
    --vs-dropdown-option--active-color: rgba(0, 0, 0, 0.6);
    width: 300px;
}
.style-chooser .vs__clear {
  display: none !important;
}
.profile {
    min-height: 100vh;
}
.profile ul {
    padding: 0;
}
.profile .banner {
    height: 100%;
    position: relative;
    transition: .5s;
}
.profile .banner img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}
.mint-video {
    width: 350px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.edit-banner-btn {
    background: transparent;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 12px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    opacity: 0;
    transition: .5s;
    cursor: pointer;
}
/* .edit-banner-btn:hover { */
    /* background: white; */
    /* color: black; */
/* } */
.profile .banner:hover .edit-banner-btn {
    opacity: 1;
}
.profile-pic {
    transform: translateY(-100px);
    /* position: absolute; */
    /* left: 0;
    right: 0; */
    display: flex;
    justify-content: center;
    width: 100%;
}
.profile-pic-wrapper {
    position: absolute;
}
.profile-pic .profile-img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid white;
    z-index: 1;
    background: black;
}
.profile-desc .address-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-desc .address-wrapper img {
    height: 30px;
    padding: 2px;
    margin-left: 2px;
}
.profile-desc .address-wrapper img:last-child {
    margin-left: 4px;
}
.profile-desc .address-wrapper .address-border {
    border: 1px solid var(--gold);
    padding: 2px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}
.profile-desc .address-wrapper .address-border img {
    padding: 4px;
}
.edit-profile-pic-btn {
    opacity: 0;
    position: absolute;
    background: transparent;
    color: white;
    border: none;
    width: 80px;
    height: 80px;
    /* border-radius: 50%; */
    top: 20px;
    right: -20px;
    z-index: 1;
    transition: .5s;
    cursor: pointer;
}
.profile-pic-wrapper:hover .edit-profile-pic-btn {
    opacity: 1;
}
.profile_content {
    margin-top: 150px;
}
.profile_content .mint-empty {
    text-align: center;
    /* width: 100%; */
}
.profile_content .title {
    min-width: none;
    text-align: center;
    margin: 20px auto;
    color: white;
    font-size: 2rem;
    /* margin-left: 10%; */
    width: 70%;
    display: flex;
    align-content: center;
    flex-direction: column;
    /* justify-content: center; */
    flex-wrap: wrap;
    align-items: center;
}
.profile_content .title #currencies {
    font-size: 1rem;
    background: rgb(24,26,27);
    color: white;
    border-radius: 4px;
}
/* .profile .section-mint, */
.profile .section-collection {
    /* width: 950px; */
    width: 90%;
    /* margin-left: 10%; */
    /* display: flex; */
    margin-bottom: 10%;
    margin: auto;
    /* grid-auto-rows: 25% 25% 25% 25%; */
}
.profile .collection-container {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 5% 0;
}
/* .profile .section-collection {
    margin: auto;
    width: 300px;
} */
.profile .section-mint .mint-wrapper,
.profile .section-collection .collection-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: .5s;
}
.profile .section-collection .collection-wrapper {
    border-radius: 12px;
    overflow: hidden;
    text-decoration: none;
    color: white;
    border: 1px solid rgba(229, 232, 235, 0.4);
    border: 1px solid transparent;
    width: 300px;
}
.collection-wrapper img {
    width: 300px;
}
.collection-wrapper .description{
    line-height: 2;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px;
    background: var(--purple-bg-gradient);
}
.collection-wrapper .description h6{
    /* width: 300px; */
    overflow: hidden;
    white-space: pre-line;
    text-align: left;
    line-height: 1.8;
    font-size: 12px;
}
.collection-wrapper:hover {
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    transform: translateY(-6px);
    box-shadow: #e3af72 0px 0px 10px;
}
.mint-container .mint-btn {
    border: none;
    margin-top: 10px;
    background: var(--gold);
    color: black !important;
    border-radius: 12px;
    transition: .5s;
    background-size: 200% 200%;
    min-width: 100px;
    width: 40%;
    margin: 12px auto;
    height: 40px;
    font-weight: 700;
}
.mint-container .mint-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0px 2px 0px 1px #573f24;
}
.mint-container .mint-btn:active {
    transform: translateY(2px);
    box-shadow: none;
}

.mint-container {
    display: flex;
    align-items: center;
    margin: auto;
}

.mint-carousel {
    width: 350px;
    height: 580px;
    overflow-x: auto;
    overflow-y: hidden;
    text-align: center;
    margin: auto;
}
.mint-carousel .nft-card {
    margin: 70px 0 0 20px;
}
/* .mint-carousel .nft-card:last-child {
    margin: 70px 20px 0 20px;
} */
.inner {
    transition: transform 0.2s;
    white-space: nowrap;
}

.nft-card {
    margin: 70px auto 0 auto;
    width: 350px;
    height: 400px;
    display: inline-flex;
    /* optional */
    /* background-color: var(--bs-gray-900); */
    color: white;
    align-items: center;
    justify-content: center;
}
.nft-wrapper {
    transition: .5s;
    position: relative;
    border-radius: 10px;
}
.nft-wrapper .mint-label,
.nft-wrapper .collection-label{
    background: black;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.nft-wrapper:hover {
    cursor: pointer;
    transform: translateY(-6px);
    box-shadow: rgb(229 232 235 / 40%) 0px 0px 10px;
}
.section-mint .nft-wrapper:hover {
    -webkit-box-shadow: rgb(255, 255, 255) 0px -1px 4px, rgba(255, 255, 0, 0.5) 0px -2px 10px, rgba(255, 128, 0, 0.5) 0px -10px 20px, 5px 5px 15px 5px rgba(0,0,0,0); 
    box-shadow: rgb(255, 255, 255) 0px -1px 4px, rgba(255, 255, 0, 0.5) 0px -2px 10px, rgba(255, 128, 0, 0.5) 0px -10px 20px, 5px 5px 15px 5px rgba(0,0,0,0);
}
.nft-card img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 350px;
    /* border: 1px solid #e3af72; */
    cursor: pointer;
    height: 350px;
    object-fit: cover;
}

.nft-card-wrapper {
    display: flex;
    flex-direction: column;
}

.nft-card-wrapper a,
.nft-card-wrapper a:hover {
    text-decoration: none;
    color: white;
}
.mint-container .desc .details-wrapper {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* border-bottom: 1px solid rgb(128, 128, 128); */
}
.desc .details-wrapper,
.desc .functions-wrapper {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid rgb(128, 128, 128); */
    padding: 10px 15px;
    background-color: var(--bs-gray-900);
}
.desc .details-wrapper {
    border-bottom: 1px solid rgba(128, 128, 128, 0.4);
}
.lightmode .desc .details-wrapper {
    border-bottom: 1px solid transparent;
}
.desc .functions-wrapper {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    background-color: var(--bs-gray-900);
}
.desc .functions-wrapper .fave {
    background: none;
    border: none;
}
.desc .functions-wrapper .fave svg path{
    fill: var(--default);
}
/* .desc .functions-wrapper .fave svg:hover path{
    fill: #dc3535;
} */
.desc .functions-wrapper .fave svg.faved path{
    fill: #dc3535;
}
.desc .nft-details {
    font-size: 0.8rem;
    text-align: start;

}
.desc .nft-details .category{
    /* font-size: 0.8rem; */
    color: #bbb;
}

.mint-container button {
    margin-right: 5px;
    margin-top: -50px;
}

.mint-container .direction-btn {
    background: transparent;
    border: none;
}
.mint-container .direction-btn.mobile {
    display: block;
}
.mint-container .direction-btn.web {
    display: none;
}
.mint-container .direction-btn.web img {    
    width: 30px;
}
.mint-container .direction-btn.mobile img {    
    width: 150px;
}
.collection-wrapper .content-wrapper {
    display: flex;
    /* justify-content: space-between; */
    font-size: 12px;
}
.collection-wrapper .content-wrapper .sub-title {
    color: #FFA135;
    flex: 1;
    text-align: start;

}
.collection-wrapper .content-wrapper .value {
    flex: 1;
    text-align: start;
    white-space: pre-wrap;
}
h2 {
    color: white;
}
.profile-desc {
    transform: translateY(200px);
    text-align: center;
}
.profile-desc .profile_name {
    margin-top: 10px;
}
.profile-desc .profile_name .name {
    color: white;
    font-size: 16px;
    margin-right: 10px;
    border: none;
    background: transparent;
}
.lightmode .profile-desc .profile_name .name,
.lightmode .profile-desc .bio {
    color: black;
}
.profile-desc .profile_name .name:focus-visible {
    outline: none;
    border: none;
}
.profile-desc .profile_name .name:read-only {
    background: transparent;
}
.profile-desc .profile_name .icon {
    cursor: pointer;
}
.profile-desc .profile_name .edit-name {
    border: 1px solid var(--gold);
    border-radius: 10px;
    padding: 4px 8px;
    background: transparent;
    color: white;
}
.profile-address+div {
    color: gray;
    margin-top: 10px;
    max-width: 100vw;
    padding: 10px;
}
.profile-bio > div:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
}
.section-collection .more-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.section-collection .more-btn {
    border: none;
    margin-top: 0;
    background: var(--gold);
    color: black !important;
    border-radius: 12px;
    transition: 1s;
    background-size: 200% 200%;
    width: 40%;
    margin: 50px auto;
    height: 40px;
    font-weight: 700;
}
.nav-tabs {
    border-bottom-color: var(--gold) !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: transparent !important;
    border-color: var(--gold)!important;
    color: var(--gold) !important;
}
a.nav-link {
    color: var(--default) !important;
}
.nav-link:hover, .nav-link:focus {
    color: var(--gold) !important;
}
.functions-details {
    position: relative;
}
.functions-details .actions-menu {
    position: absolute;
    bottom: 10px;
    left: 0;
    border-radius: 12px;
    border: 1px solid;
    background: rgb(24, 26, 27);
    display: none;
}
.functions-details .actions-menu li {
    color: white;
    padding: 8px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    white-space: nowrap;
}
.functions-details .actions-menu li:last-child {
    border-bottom: none;
}
.profile .section-collection .filter-label {
    padding-right: 10px;
}
.profile .section-collection .filter-wrapper {
    text-align: center;
    display: flex;
    align-items: center;
}
.profile .section-collection .filter-wrapper select,
.profile .section-collection .filter-wrapper option{
    background: rgb(17, 18, 19);
    color: white;
}
@keyframes btnGradient {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}
.profile_content .currency-wrapper {
    border: 1px solid var(--gold);
    padding: 10px 16px;
    display: inline-flex;
    align-items: flex-start;
    border-radius: 12px;
    margin-left: 10px;
    transition: .5s;
}
.profile_content .mintable-currency {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}
.profile_content .mintable-currency img {
    width: 40px;
    padding-bottom: 6px;
}
.profile_content .currency-wrapper b {
    font-size: 16px;
}
.profile_content .currency-wrapper:hover {
    cursor: pointer;
    color: black;
    -webkit-box-shadow: #FFF 0 -1px 4px, #ff0 0 -2px 10px, #ff8000 0 -10px 20px, var(--gold) 0 -18px 40px, 5px 5px 15px 5px rgba(0,0,0,0); 
    box-shadow: #FFF 0 -1px 4px, #ff0 0 -2px 10px, #ff8000 0 -10px 20px, var(--gold) 0 -18px 40px, 5px 5px 15px 5px rgba(0,0,0,0);
    background: #ECEFCF;
}
.profile_content .title .currency-icon {
    width: 30px;
}
.hide-thumbnail-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(44, 47, 49);
    z-index: 20;
    display: flex;
    justify-content: space-between;
    height: 90px;
    align-items: center;
    padding: 0px 24px;
}
.hide-thumbnail-wrapper .hide-btns {
    display: flex;
    gap: 10px;
}
.hide-thumbnail-wrapper .hide-btns .hide,
.hide-thumbnail-wrapper .hide-btns .cancel {
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;
    color: rgb(255, 255, 255);
    gap: 10px;
    cursor: pointer;
}
.hide-thumbnail-wrapper .hide-btns .hide{
    background-color: var(--gold);
    border: 1px solid var(--gold);
}
.hide-thumbnail-wrapper .hide-btns .cancel {
    background-color: rgb(32, 34, 37);
    border: 1px solid rgb(112, 122, 131);
}
.hide-thumbnail-wrapper .hide-btns .hide svg,
.hide-thumbnail-wrapper .hide-btns .cancel svg {
    width: 25px;
    height: 25px;
}
.hidden-check {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: -20px;
    background: black;
    border-radius: 50%;
}

.nft-card .nft-card-wrapper .collection-label .thumbnail {
    height: 350px;
}

.nft-card .nft-card-wrapper .collection-label .switch-btn{
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
}

.nft-card .nft-card-wrapper .collection-label .switch-btn:hover{
    opacity: 0.2;
}

.nft-card .nft-card-wrapper .collection-label .thumbnail img,
.nft-card .nft-card-wrapper .collection-label .thumbnail video{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.lightmode .movie--isloading .loading-content .loading-main-text
.lightmode .movie--isloading .loading-content .loading-sub-text,
.lightmode .movie--isloading .loading-content .loading-btn {
    background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
    background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
    background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
}
.lightmode .nft-card .nft-card-wrapper .nft-wrapper .collection-label .thumbnail img,
.lightmode .nft-card .nft-card-wrapper .nft-wrapper .collection-label .thumbnail video{
    border-left: 1px solid rgba(191, 191, 191, 0.6);
    border-right: 1px solid rgba(191, 191, 191, 0.6);
    border-top: 1px solid rgba(191, 191, 191, 0.6);
}

@media screen and (min-width: 1280px) {
    .mint-carousel {
        width: calc(400px * 3 + 60px);
        padding: 0 10px;
    }
    .mint-video {
        width: 400px;
    }
    .mint-carousel .nft-card {
        margin: 70px 20px 0 0;
    }
    .nft-card {
        margin-top: 70px;
        margin-left: 0;
        width: 400px;
        height: 450px;
        /* margin-right: 20px; */
        display: inline-flex;
        /* optional */
        /* background-color: #39b1bd; */
        color: white;
        align-items: center;
        justify-content: center;
    }
    .nft-card img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 400px;
        cursor: pointer;
        height: 450px;
        object-fit: cover;
    }
    .mint-container .direction-btn.mobile {
        display: none;
    }
    .mint-container .direction-btn.web {
        display: block;
    }
    .mint-container {
        width: calc(400px * 3 + 60px);
        display: flex;
        align-items: center;
        /* width: 950px; */
        /* min-width: 397px; */
        position: relative;
        margin: auto;
    }
    .mint-container .direction-btn {
        position: absolute;
    }
    .mint-container .direction-btn.left {
        top: 60%;
        left: -5%;
    }
    .mint-container .direction-btn.right {
        top: 60%;
        right: -5%;
    }
    .mint-container .direction-btn img {
        width: 50px;
    }
    .profile .section-collection {
        min-width: 1260px;
        width: 70%;
        /* margin-left: 10%; */
        /* display: flex; */
        margin-bottom: 10%;
        margin: auto;
        /* grid-auto-rows: 25% 25% 25% 25%; */
    }
    .profile .section-collection .filter-wrapper {
        float: right;
    }
    .profile .collection-container {
        grid-template-columns: 33.33% 33.33% 33.33%;
        justify-content: space-between;
        row-gap: 65px;
    }
    .profile_content .title {
        min-width: 1260px;
        text-align: left;
        margin: 20px auto;
        color: white;
        font-size: 2rem;
        /* margin-left: 10%; */
        /* width: 950px; */
        justify-content: space-between;
        flex-direction: row;
    }
    .profile_content .mint-empty {
        width: 950px;
        margin: auto;
        text-align: left;
    }
    /* .profile_content .title .currency-icon {
        margin-left: 10px;
    } */
    .desc .nft-details .category{
        font-size: 1rem;
    }

    .nft-card .nft-card-wrapper .collection-label .thumbnail{
        height: 400px;
    }
}

</style>